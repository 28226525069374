import React, { Component } from 'react'
import PropTypes from 'prop-types'

export class SignupTab extends Component {
    static propTypes = {
        activeTab: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
    }

    onClick = () => {
        const { label, onClick } = this.props;
        onClick(label);
    }

    render() {
        const {
            onClick,
            props: {
              activeTab,
              label
            },
        } = this;

        let className = 'tab-list-item col';

        if (activeTab === label) {
            className += ' tab-list-active';
        }


        return (
            <li
                className={className}
                onClick={onClick}
            >
                {label}
            </li>
        )
    }
}

export default SignupTab
