import React, { Component } from 'react';
import '../App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Button, Card, Table, Badge } from "react-bootstrap";

import { Link } from 'react-router-dom';

class Grade extends Component{
    render(){
        return(
            <div>
                <h1 className="text-center">회원등급기준표</h1>
                <div className="card border-radius-0">
                    <div className="card-body">
                        저희 연애해 매치메이커는 사람을 차별하지 않습니다.
                        본인의 소중하고 진실된 정보만으로 등급을 부여하고 있으며
                        성사 후 강남지역의 검증된 장소에서만 상호동의하에
                        안전한 만남이 이뤄집니다.
                    </div>
                </div>
                <div className="card border-radius-0">
                    <div className="card-body">
                        <Table responsive="md">
                            <thead className="text-center">
                            <tr>
                                <th style={{verticalAlign:'middle'}}>가입종류</th>
                                <th style={{verticalAlign:'middle'}}>
                                    심사기준 <br />
                                    (모든 인증서류는 주민번호 뒤 6자리,<br/>거주 소재지 등 민감한 개인정보는<br/><span className="text-red">마스킹</span> 처리가 <span className="text-red">필수</span>입니다.)
                                </th>
                            </tr>
                            </thead>
                            <tbody className="text-center">
                            <tr>
                                <td rowSpan="2" style={{margin:'auto', textAlign:'center'}}>스탠다드 등급</td>
                                <td>PASS본인인증</td>
                            </tr>
                            <tr>
                                <td>본인이 등록한 서류검증 후 트로피 수여</td>
                            </tr>
                            <tr>
                                <td rowSpan="2">골드등급</td>
                                <td>PASS본인인증 + 재직(명함)증 또는 졸업(재학)증명서</td>
                            </tr>
                            <tr>
                                <td>본인이 등록한 서류검증 후 트로피 수여</td>
                            </tr>
                            <tr>
                                <td rowSpan="2">플래티늄등급</td>
                                <td>PASS본인인증 + 재직(명함)증 또는 졸업(재학)증명서 + 가족관계증명서 + 소득증명서 또는 세금납부서
                                </td>
                            </tr>
                            <tr>
                                <td>본인이 등록한 서류검증 후 트로피 수여</td>
                            </tr>
                            <tr>
                                <td rowSpan="2">다이아등급</td>
                                <td>PASS본인인증 + 재직(명함)증 또는 졸업(재학)증명서 + 가족관계증명서 + 소득증명서 또는 세금납부서 + 부동산증빙자료</td>
                            </tr>
                            <tr>
                                <td>본인이 등록한 서류검증 후 트로피 수여</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                            </tr>
                            </tbody>
                        </Table>

                        <div className="text-center mb-3">
                            <Link to="/pass">
                                <Button variant="btn btn-secondary" type="submit">결제 페이지로 돌아가기</Button>
                            </Link>
                        </div>  
                    </div>
                </div>
                
                
            </div>
        );
    }
}
export default Grade;