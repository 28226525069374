import React, { Component } from 'react';
import '../App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import User from '../api/user.api'
import { AuthContext } from '../components/AuthenticatedComponent'

class Password extends React.Component{

    static contextType = AuthContext

    constructor(props) {
        super(props)
        
        this.state = {
            newPassword: '',
            newPasswordConfirm : '',
            prevPassword: '',
            modeOut: false 
        }
    }

    onChange=(e)=> {
        this.setState({
            [e.target.name] : e.target.value,
            message: ''
        })

    }

    onSubmit=async(e)=>{
        e.preventDefault();

        if(this.state.modeOut) {
            this.userWithdraw()
        } else {
            const userRes = await User.update({
                action: 'changePw',
                prevPassword: this.state.prevPassword,
                password: this.state.newPassword,
                passwordConfirm: this.state.newPasswordConfirm
            })
    
            if (!userRes.success){
                alert(userRes.message)
            } else {
                alert('비밀번호 수정이 완료되었습니다.')
                this.props.history.push('/infomodify')
            }
        }

        
    }

    checkWithdraw = () => {
        alert('잠시만요!! 탈퇴보다 계정휴면으로 전환하시면 모든 매칭이 중단됩니다.')
        this.setState((state) => ({
            modeOut: !state.modeOut
        }))
    }

    userWithdraw = async (e) => {
        const result = window.confirm("정말로 회원탈퇴를 하시겠습니까? 회원정보 모두 삭제됩니다. 휴면계정으로 해주세요.")
        if(result){
            const userRes = await User.withdraw({
                action: 'setWithdraw',
                password: this.state.prevPassword,
                status: 'withdraw'
            });
            if(userRes.success){
                alert('탈퇴가 완료되었습니다.')
                if(localStorage.getItem('autoLogin') === 'false'){
                    sessionStorage.removeItem('token')
                } else {
                    localStorage.removeItem('token')
                }
                localStorage.removeItem('autoLogin')
                this.props.history.push('/login')
            } else {
                alert(userRes.message)
            }
        }
    }

    checkRest = async (e) => {
        e.preventDefault()
        const result = window.confirm("휴면계정으로 전환 하시겠습니까?")
        if(result){
            const userRes = await User.rest();

            if(userRes.success){
                alert('휴먼상태로 완료되었습니다.')
                if(localStorage.getItem('autoLogin') === 'false'){
                    sessionStorage.removeItem('token')
                } else {
                    localStorage.removeItem('token')
                }
                localStorage.removeItem('autoLogin')
                this.props.history.push('/login')
            }
        }
    }
    
    render(){
        const {
            onChange,
            onSubmit,
            userWithdraw,
            checkWithdraw,
            checkRest,
            state: {
                newPassword,
                newPasswordConfirm,
                prevPassword,
                modeOut
            }
        } = this

        let message = ''

        if(newPasswordConfirm !== newPassword) {
            message = '비밀번호가 일치하지 않습니다.'
        }

        return(
            <div>
                <div className="card border-radius-0 mb-3">
                    <div className="card-header">
                        <h6 className="mb-0">{modeOut ? '탈퇴하기' : '비밀번호 변경'}</h6>
                    </div>
                    <div className="card-body">
                        <form onSubmit={onSubmit}>
                            <div className="form-group mb-3">
                                <label>기존 비밀번호</label>
                                <input type="password" className="form-control" name="prevPassword" defaultValue={prevPassword} onChange={onChange} />
                            </div>
                            {
                                modeOut ?
                                null :
                                <>
                                <div className="form-group mb-3">
                                    <label>새 비밀번호</label>
                                    <input type="password" className="form-control" name="newPassword" defaultValue={newPassword} onChange={onChange} />
                                </div>
                                <div className="form-group mb-3">
                                    <label>새 비밀번호 확인</label>
                                    <input type="password" className="form-control" name="newPasswordConfirm" defaultValue={newPasswordConfirm} onChange={onChange} />
                                </div>
                                </>
                            }
                            
                            <div>{message}</div>
                            <div>
                                <button type="submit" className="btn btn-primary btn-block">{modeOut ? '탈퇴하기' : '수정완료'}</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="text-center">
                    <button className="btn btn-link text-secondary" onClick={checkWithdraw}>회원탈퇴</button>
                    <button className="btn btn-link text-secondary" onClick={checkRest}>휴면설정</button>
                </div>
            </div>
        );
    }
}
export default Password;