import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';
import Head from './components/Head';
import Footer from './components/Footer';

import "bootstrap/dist/css/bootstrap.min.css"
import "./css/style.css"
import "./css/font.css"

ReactDOM.render(
  <React.StrictMode>
    <App />
   </React.StrictMode>,
   document.getElementById('main')
 );

// ReactDOM.render(
//   <React.StrictMode>
//     <Head/>
//    </React.StrictMode>,
//    document.getElementById('nav')
//  );

//  ReactDOM.render(
//   <React.StrictMode>
//     <Footer/>
//    </React.StrictMode>,
//    document.getElementById('footer')
//  );



 serviceWorker.unregister();
