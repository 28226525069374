import moment from 'moment'

const getAge = birth => {
    // NOTE: jason_wang: 일본식 나이 계산 로직
    // return Number(moment().format("YYYY")) - Number(moment(birth).format("YYYY")) + 1
    // NOTE: jason_wang: 만 나이 계산 로직
    return moment().diff(birth, 'years')
}

const ageCal = birth => {
    const age = getAge(birth)
    const ageSub = age % 10
    const ageTitle = Math.floor(age / 10) * 10

    let ageLabel = ''

    if (ageSub >= 0 && ageSub < 3) {
        ageLabel = '초반'
    } else if (ageSub >= 3 && ageSub < 7) {
        ageLabel = '중반'
    } else {
        ageLabel = '후반'
    }

    return ageTitle + ageLabel
}

export const ageLimit = (birth, gender) => {

    if (!birth || !gender) {
        return {
            success: false,
            message: '본인인증을 진행해주세요.'
        }
    }

    const age = getAge(birth)
    let result = {}
    if (gender === 'male') {
        if (age > 22) {
            result.success = true
            result.message = ''
        } else {
            result.success = false
            result.message = '남성은 만23세부터 가입 가능합니다.'
        }
    } else {
        if (age > 19) {
            result.success = true
            result.message = ''
        } else {
            result.success = false
            result.message = '여성은 만20세부터 가입 가능합니다.'
        }
    }

    return result
}

export default ageCal