import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SignupTab from './SignupTab';

export class SignupTabs extends Component {
    static propTypes = {
        children: PropTypes.instanceOf(Array).isRequired,
        onClickMoveTab: PropTypes.func.isRequired,
        activeTab: PropTypes.string.isRequired
    }

    constructor(props) {
        super(props)
        this.state = {
            // activeTab: this.props.children[0].props.label,
            // activeTab: this.props.activeTab
        }
    }
    
    onClickTabItem = (tab) => {
        this.setState({ activeTab: tab });
    }

    render() {
        const {
            onClickTabItem,
            props: {
              children,
              onClickMoveTab,
              activeTab
            }
        } = this;
        return (
            <div className="tabs">
                <ol className="tab-list row mx-0 text-center">  
                    {children.map((child) => {
                        const { label } = child.props;

                        return (
                        <SignupTab
                            activeTab={activeTab}
                            key={label}
                            label={label}
                            // onClick={onClickTabItem}
                            onClick={onClickMoveTab}
                        />
                        );
                    })}
                </ol>
                <div className="tab-content">
                    {children.map((child) => {
                        if (child.props.label !== activeTab) return undefined;
                        return child.props.children;
                    })}
                </div>
            </div>
        )
    }
}

export default SignupTabs
