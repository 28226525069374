import React, { Component } from 'react'
import { Modal, Button } from "react-bootstrap";

const defaultStyleModal = {
    size: "xs",
}

const defaultStyleHead = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "1rem",
}

const defaultStyleTitle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
}

const defaultStyleBody = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "2rem",
    paddingBottom: "2rem",
}

const defaultStyleFooter = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "1rem",
}

const defaultStylePrimary = {
    width: "45%",
    flex: "0 0 45%",
    maxWidth: "45",
    paddingLeft: "2.5%",
    paddingRight: "2.5%",
}

const defaultStyleSecondary = {
    width: "45%",
    flex: "0 0 45%",
    maxWidth: "45",
    paddingLeft: "2.5%",
    paddingRight: "2.5%",
}

export class PhotoModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            visible: false,
        }
    }

    componentDidMount() {
        this._setVisible(true)
    }

    _setVisible = value => this.setState({ visible: value })
    _getVisible = () => this.state.visible
    
    _getValue = (object, keyword, defaultValue) => {
        if (!object)
            return defaultValue
        
        if (!object.hasOwnProperty(keyword))
            return defaultValue
        
        return object[keyword]
    }

    _onClick = async button => {
        if (!button)
            return;
        
        let hide = this._getValue(button, 'hide', true)

        if (button.onClick)
            hide = button.onClick()
        else if (button.onClickAsync)
            hide = await button.onClickAsync()

        if (hide)
            this._setVisible(false)
    }

    render() {
        const {
            state: {
                visible,
            },
            props: {
                photo,
                title,
                body,
                buttonPrimary,
                buttonSecondary,
                options,
            },
        } = this

        return (
            <Modal
                show={visible}
                onHide={() => this._setVisible(false)}
                aria-labelledby={this._getValue(options, "ariaLabelledby", "contained-modal-title-vcenter")}
                backdrop={this._getValue(options, "backdrop", "static")}
                centered
                style={this._getValue(options, "modalStyle", defaultStyleModal)}
            >
                {
                    title && this._getValue(options, "closeButton", false) ? (
                        <Modal.Header closeButton style={this._getValue(options, "headStyle", defaultStyleHead)}>
                            <Modal.Title style={this._getValue(options, "titleStyle", defaultStyleTitle)}>{title}</Modal.Title>
                        </Modal.Header>
                    ) : title ? (
                        <Modal.Header style={this._getValue(options, "headStyle", defaultStyleHead)}>
                            <Modal.Title style={this._getValue(options, "titleStyle", defaultStyleTitle)}>{title}</Modal.Title>
                        </Modal.Header>
                    ) : (
                        <></>
                    )
                }
                <Modal.Body style={this._getValue(options, "bodyStyle", defaultStyleBody)}>
                    {
                        photo && (
                            <div>
                                <label style={{
                                    backgroundImage: `url("${photo}")`,
                                    backgroundSize: this._getValue(options, "photoSize", 'cover'),
                                    backgroundPosition: this._getValue(options, "photoPosition", 'center'),
                                    width: this._getValue(options, "photoWidth", 200),
                                    height: this._getValue(options, "photoHeight", 200),
                                    display: this._getValue(options, "photoDisplay", 'flex'),
                                    justifyContent: this._getValue(options, "photoJustifyContent", 'center'),
                                    margin: this._getValue(options, "photoMargin", '0 0 2em 0'),
                                }}>
                                </label>
                            </div>)
                    }
                    {body}
                </Modal.Body>
                <Modal.Footer style={this._getValue(options, "footerStyle", defaultStyleFooter)}>
                    {
                        buttonPrimary && (
                            <Button
                                variant="primary"
                                onClick={() => this._onClick(buttonPrimary)}
                                style={this._getValue(options, "primaryStyle", defaultStylePrimary)}
                            >
                                {this._getValue(buttonPrimary, "label", "확인")}
                            </Button>
                        )
                    }
                    {
                        buttonSecondary && (
                            <Button
                                variant="secondary"
                                onClick={() => this._onClick(buttonSecondary)}
                                style={this._getValue(options, "secondaryStyle", defaultStyleSecondary)}
                            >
                                {this._getValue(buttonSecondary, "label", "취소")}
                            </Button>
                        )
                    }
                </Modal.Footer>
            </Modal>
        )
    }
}

export default PhotoModal