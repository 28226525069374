import Api from './api'
const url = "admin"
const Admin = {
  getTeamList: async () => {
    return await Api.get(`${url}/team?all=all`)
  },
  getTeamNames: async () => {
    return await Api.get(`${url}/tname?_sort=dsc`)
  }
}

export default Admin