import React, { Component } from 'react';
import '../App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { AuthContext } from '../components/AuthenticatedComponent'
import InterestApi from '../api/interest.api'

class Interest extends Component{
    static contextType = AuthContext

    constructor(props, context) {
        super(props)
        this.state = {
            face: !context.interest ? '' : context.interest.face.value,
            faceRank: !context.interest ? '' : context.interest.face.rank,
            income: !context.interest ? '' : context.interest.income.value,
            incomeRank: !context.interest ? '' : context.interest.income.rank,
            personality: !context.interest ? '' : context.interest.personality.value,
            personalityRank: !context.interest ? '' : context.interest.personality.rank,
            values: !context.interest ? '' : context.interest.values.value,
            valuesRank: !context.interest ? '' : context.interest.values.rank,
            familyBackground: !context.interest ? '' : context.interest.familyBackground.value,
            familyBackgroundRank: !context.interest ? '' : context.interest.familyBackground.rank,
            height: !context.interest ? '' : context.interest.height,
            job: !context.interest ? '' : context.interest.job,
            age: !context.interest ? '' : context.interest.age,
            interest: !context.interest ? '' : context.interest.interest,
            matchRange: !context.profile ? 'all' : context.profile.matchRange,
            myGrade: !context.profile ? 'standard' : context.profile.grade,
            isFirstCheck: !context.interest ? true : false
        }
    }
    
    onChange = async (e) => {

        const firstCheck = !this.context.interest ? true : false

        const target = e.target.name
        const value = e.target.value

        if(!firstCheck) {
            const interestRes = await InterestApi.update({
                [target] : value
            })
        }

        

        this.setState({
            [target]: value
        })
    }
    
    onChangeRank = async (e) => {
        const {
            faceRank,
            incomeRank,
            personalityRank,
            valuesRank,
            familyBackgroundRank
        } = this.state

        const rankArr = [{name: 'faceRank', value: faceRank},
            {name: 'incomeRank', value:incomeRank},
            {name: 'personalityRank', value:personalityRank},
            {name: 'valuesRank', value:valuesRank},
            {name: 'familyBackgroundRank', value:familyBackgroundRank}]
        const resCheck = rankArr.find( item => item.value === e.target.value*1)

        let paramsObj = {
            faceRank: faceRank+'',
            incomeRank: incomeRank+'',
            personalityRank: personalityRank+'',
            valuesRank: valuesRank+'',
            familyBackgroundRank: familyBackgroundRank+''
        }

        if(resCheck){
            const targetName = e.target.name
            const targetValue = e.target.value
            this.setState( state => {
                return {
                    [resCheck.name] : state[targetName],
                    [targetName] : targetValue*1
                }
                
            })

            paramsObj[resCheck.name] = this.state[e.target.name] + ''
            paramsObj[e.target.name] = e.target.value

        } else {
            this.setState({
                [e.target.name] : e.target.value*1
            })
            paramsObj[e.target.name] = e.target.value
        }

        const resInterest = await InterestApi.update(paramsObj)
        if(!resInterest.success){
            alert(resInterest.message)
        }
    }

    onChangeMatchRange = async (e) => {

        const resInterest = await InterestApi.update({
            matchRange: e.target.value
        })

    }

    onSubmit = async (e) => {
        e.preventDefault()

        const interestRes = await InterestApi.update({
            face: this.state.face,
            faceRank: this.state.faceRank,
            income: this.state.income,
            incomeRank: this.state.incomeRank,
            personality: this.state.personality,
            personalityRank: this.state.personalityRank,
            values: this.state.values,
            valuesRank: this.state.valuesRank,
            familyBackground: this.state.familyBackground,
            familyBackgroundRank: this.state.familyBackgroundRank,
            height: this.state.height,
            job: this.state.job,
            age: this.state.age,
            interest: this.state.interest,
            matchRange: this.state.matchRange
        })

        if(!interestRes.success){
            alert(interestRes.message)
        } else {
            alert('입력이 완료 되었습니다.')
            window.location.reload();
        }
    }

    render(){
        const {
            onChange,
            onSubmit,
            onChangeRank,
            onChangeMatchRange,
            state: {
                face,
                faceRank,
                income,
                incomeRank,
                personality,
                personalityRank,
                values,
                valuesRank,
                familyBackground,
                familyBackgroundRank,
                height,
                job,
                age,
                interest,
                matchRange,
                myGrade,
                isFirstCheck
            }
        } = this

        let options

        if(myGrade === 'standard') {
            options = <select name="matchRange" id="matchRange" defaultValue={matchRange} className="form-control" onChange={isFirstCheck ? onChange : onChangeMatchRange}>
                <option value="" disabled>-- 선택 --</option>
                <option value="all">모두다</option>
                <option value="standard">스탠다드만</option>
            </select>
        } else if( myGrade === 'gold') {
            options = <select name="matchRange" id="matchRange" defaultValue={matchRange} className="form-control" onChange={isFirstCheck ? onChange : onChangeMatchRange}>
                <option value="" disabled>-- 선택 --</option>
                <option value="all">모두다</option>
                <option value="standard">스탠다드 이상</option>
                <option value="gold">골드 이상</option>
            </select>
        } else if( myGrade === 'platinum' ){
            options = <select name="matchRange" id="matchRange" defaultValue={matchRange} className="form-control" onChange={isFirstCheck ? onChange : onChangeMatchRange}>
                <option value="" disabled>-- 선택 --</option>
                <option value="all">모두다</option>
                <option value="standard">스탠다드 이상</option>
                <option value="gold">골드 이상</option>
                <option value="platinum">플래티늄 이상</option>
            </select>
        } else {
            options = <select name="matchRange" id="matchRange" defaultValue={matchRange} className="form-control" onChange={isFirstCheck ? onChange : onChangeMatchRange}>
                <option value="" disabled>-- 선택 --</option>
                <option value="all">모두다</option>
                <option value="standard">스탠다드 이상</option>
                <option value="gold">골드 이상</option>
                <option value="platinum">플래티늄 이상</option>
                <option value="diamond">다이아만</option>
            </select>
        }

        return(
            <div className="row"><div className="col-12">
                <div className="card border-radius-0 mb-3">
                    <div className="card-header">
                        <h6 className="mb-0">관심설정</h6>
                    </div>
                    <div className="card-body">
                        {
                            !this.context.interest ?
                            <p className="text-red small">* 처음 관심설정 하실 땐 모든 입력란을 입력해주세요.</p>
                            : '' 
                        }
                        <form onSubmit={onSubmit}>
                            <div className="form-group mb-3">
                                <label>외모</label>
                                <div className="input-group mb-3">
                                    <select name="face" id="face" className="form-control" defaultValue={face} onChange={onChange} >
                                        <option value="" disabled>-- 선택 --</option>
                                        <option value="이쁨/잘생김">이쁨/잘생김</option>
                                        <option value="귀여운/준수함">귀여운/준수함</option>
                                        <option value="평범이상">평범이상</option>
                                    </select>
                                    <select name="faceRank" id="faceRank" className="form-control" value={faceRank} onChange={isFirstCheck ? onChange : onChangeRank}>
                                        <option value="" disabled >-- 선택 --</option>
                                        <option value="1">1순위</option>
                                        <option value="2">2순위</option>
                                        <option value="3">3순위</option>
                                        <option value="4">4순위</option>
                                        <option value="5">5순위</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group mb-3">
                                <label>경제력</label>
                                <div className="input-group mb-3">
                                    <select name="income" id="income" className="form-control" defaultValue={income} onChange={onChange}>
                                        <option value="" disabled>-- 선택 --</option>
                                        <option value="상관없음">상관없음</option>
                                        <option value="비슷하면됨">비슷하면됨</option>
                                        <option value="중요함">중요함</option>
                                    </select>
                                    <select name="incomeRank" id="incomeRank" className="form-control" value={incomeRank} onChange={isFirstCheck ? onChange : onChangeRank}>
                                        <option value="" disabled>-- 선택 --</option>
                                        <option value="1">1순위</option>
                                        <option value="2">2순위</option>
                                        <option value="3">3순위</option>
                                        <option value="4">4순위</option>
                                        <option value="5">5순위</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group mb-3">
                                <label>성격</label>
                                <div className="input-group mb-3">
                                    <select name="personality" id="personality" className="form-control" defaultValue={personality} onChange={onChange}>
                                        <option value="" disabled>-- 선택 --</option>
                                        <option>내향적인</option>
                                        <option>외향적인</option>
                                    </select>
                                    <select name="personalityRank" id="personalityRank" className="form-control" value={personalityRank} onChange={isFirstCheck ? onChange : onChangeRank}>
                                        <option value="" disabled>-- 선택 --</option>
                                        <option value="1">1순위</option>
                                        <option value="2">2순위</option>
                                        <option value="3">3순위</option>
                                        <option value="4">4순위</option>
                                        <option value="5">5순위</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group mb-3">
                                <label>가치관</label>
                                <div className="input-group mb-3">
                                    <select name="values" id="values" className="form-control" defaultValue={values} onChange={onChange}>
                                        <option value="" disabled>-- 선택 --</option>
                                        <option value="현실적">현실적</option>
                                        <option value="사회도덕적">사회도덕적</option>
                                        <option value="미래지향적">미래지향적</option>
                                    </select>
                                    <select name="valuesRank" id="valuesRank" className="form-control" value={valuesRank} onChange={isFirstCheck ? onChange : onChangeRank}>
                                        <option value="" disabled>-- 선택 --</option>
                                        <option value="1">1순위</option>
                                        <option value="2">2순위</option>
                                        <option value="3">3순위</option>
                                        <option value="4">4순위</option>
                                        <option value="5">5순위</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group mb-3">
                                <label>가정환경</label>
                                <div className="input-group mb-3">
                                    <select name="familyBackground" id="familyBackground" className="form-control" defaultValue={familyBackground} onChange={onChange}>
                                        <option value="">-- 선택 --</option>
                                        <option value="중요함">중요함</option>
                                        <option value="둘만좋으면됨">둘만 좋으면 됨</option>
                                    </select>
                                    <select name="familyBackgroundRank" id="familyBackgroundRank" className="form-control" value={familyBackgroundRank} onChange={isFirstCheck ? onChange : onChangeRank}>
                                        <option value="" disabled>-- 선택 --</option>
                                        <option value="1">1순위</option>
                                        <option value="2">2순위</option>
                                        <option value="3">3순위</option>
                                        <option value="4">4순위</option>
                                        <option value="5">5순위</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group mb-3">
                                <label>키</label>
                                <div className="input-group mb-3">
                                    <select name="height" id="height" className="form-control" defaultValue={height} onChange={onChange}>
                                        <option value="" disabled>-- 선택 --</option>
                                        <option value="상관없음">상관없음</option>
                                        <option value="나와10cm이내">나와10cm이내</option>
                                        <option value="나와20cm이내">나와20cm이내</option>
                                    </select>
                                </div>
                            </div>
                            
                            <div className="form-group mb-3">
                                <label>직업군</label>
                                <div className="input-group mb-3">
                                    <select name="job" id="job" className="form-control" defaultValue={job} onChange={onChange}>
                                        <option value="" disabled>-- 선택 --</option>
                                        <option value="상관없음">상관없음</option>
                                        <option value="도전적">도전적</option>
                                        <option value="안정적">안정적</option>
                                    </select>
                                </div>
                            </div>
                            
                            <div className="form-group mb-3">
                                <label>나이대</label>
                                <div className="input-group mb-3">
                                    <select name="age" id="age" className="form-control" defaultValue={age} onChange={onChange}>
                                        <option value="" disabled>-- 선택 --</option>
                                        <option value="상관없음">상관없음</option>
                                        <option value="동연상">동연상</option>
                                        <option value="동연하">동연하</option>
                                    </select>
                                </div>
                            </div>
                            
                            <div className="form-group mb-3">
                                <label>관심사</label>
                                <div className="input-group mb-3">
                                    <select name="interest" id="interest" className="form-control" defaultValue={interest} onChange={onChange}>
                                        <option value="">없음</option>
                                        <option value="예술운동">예술, 운동</option>
                                        <option value="일상여행">일상, 여행</option>
                                        <option value="교육,자기계발">교육, 자기계발</option>
                                        <option value="음악,친목모임">음악, 친목모임</option>
                                        <option value="기타">기타</option>
                                    </select>
                                </div>
                            </div>

                            <div className="form-group mb-3">
                                <label>상대등급기준</label>
                                <div className="input-group mb-3">
                                    {options}
                                </div>
                            </div>

                            {
                                !this.context.interest ?
                                <button type="submit" className="btn btn-primary btn-block mb-3"> 입력완료 </button>:
                                ''
                            }
                        </form>
                    </div>
                </div>
            </div>
            </div>
        );
    }
}
export default Interest;