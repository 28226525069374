import React, { Component } from 'react'
import Footer from '../components/Footer'

import menual1 from '../img/menual1.png'
import menual2 from '../img/menual2.png'
import menual3 from '../img/menual3.png'
import menual4 from '../img/menual4.png'
import menual5 from '../img/menual5.png'

export class Menual extends Component {
    render() {
        return (
            <div>
                <div className="container">
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/fXve6ks4SJQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/o5ayHaR83rw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/Yh2EHGsjYN4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/UNFAYXnXF_E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/AjMqHKGQ7Go" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/RqNjYkwt8O4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <img className="w-100" src={menual1} alt="메뉴얼1"/>
                    <img className="w-100" src={menual2} alt="메뉴얼2"/>
                    <img className="w-100" src={menual3} alt="메뉴얼3"/>
                    <img className="w-100" src={menual4} alt="메뉴얼4"/>
                    <img className="w-100" src={menual5} alt="메뉴얼5"/>
                </div>
                <Footer />
            </div>
        )
    }
}

export default Menual
