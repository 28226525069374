import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Footer from '../components/Footer'
import staff01 from '../img/staff-01.png'
import staff03 from '../img/staff-03.png'
import staff04 from '../img/staff-04.png'
import staff05 from '../img/staff-05.png'
import staff06 from '../img/staff-06.png'
import staff07 from '../img/staff-07.png'
import Admin from '../api/admin.api'
import Config from '../config/config'
import userImg from '../img/user-1.png'
import Papa from 'papaparse'
import moment from 'moment'

export class AboutUs extends Component {

    constructor(props) {
        super(props)

        this.state = {
            teams: [],
            associated: []
        }
    }

    componentDidMount = async () => {
        try {
            const teamListRes = await Admin.getTeamList()
            if (teamListRes.success) {
                let admins = teamListRes.admins
                admins.sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt)))
                this.setState({ teams: admins })
            }

            // console.log(moment().format('YYYYMMDDHHmmss'));
            fetch('./associated.csv?v=20220421171406') // TODO: asset bundle
                .then(response => response.text())
                .then(responseText => {
                    var ret = Papa.parse(responseText)
                    this.setState({
                        associated: ret.data
                    })
                })
        } catch (error) {
            console.log(error)
        }
    }

    profileStyle = (image) => {
        return ({
            backgroundImage: image ? `url("${Config.photoUrl}${image}")` : `url(${userImg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            borderRadius: '50%'
        })
    }

    profileStyleByUrl = (url) => {
        return ({
            backgroundImage: `url("${url}")`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            borderRadius: '50%'
        })
    }

    render() {
        return (
            <div>
                <div className="bg-company">
                    <div className="container pt-3">
                        <div className="mb-5">
                            <div className="pagetitle mb-4">
                                <h3 className="mb-2 text-outline-shadow10">회사소개</h3>
                            </div>
                            <div className="row mb-3 text-outline-shadow8">
                                <div className="col">
                                    <div className="text-center square keyword-item rounded-circle">
                                        <div className="keyword">
                                            안전성
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="text-center square keyword-item rounded-circle">
                                        <div className="keyword">
                                            보안성
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="text-center square keyword-item rounded-circle">
                                        <div className="keyword">
                                            신속성
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="text-center square keyword-item rounded-circle">
                                        <div className="keyword">
                                            정확성
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col text-center font-size-clamp-min100rem-cen250vw-max200rem text-outline-shadow8">
                                    O2O(online to offline)기업 연애매칭회사 연애해는 강남 최고의 인적기반 인프라코어를 바탕으로 매치메이커 시장의 새로운 패러다임을 선도합니다.
                                </div>
                            </div>
                        </div>
                        <div className="mb-5">
                            <div className="pagetitle mb-4">
                                <h3 className="mb-2 text-outline-shadow10">입점사</h3>
                            </div>
                            <div className="row about-container">
                                {
                                    this.state.teams.map(item => {
                                        if (item.website) {
                                            return (
                                                <a href={item.website} className="about-item about-linked" target="_blank" rel="noopener noreferrer">
                                                    <div key={item._id} className="text-center">
                                                        <div className="p-2">
                                                            <div className="d-flex-column square" style={this.profileStyle(item._photo)}></div>
                                                        </div>
                                                        <div className="mt-1 font-size-clamp-min082rem-cen200vw-max175rem text-outline-shadow6" style={{ whiteSpace: 'nowrap' }}>{item.name}</div>
                                                    </div>
                                                </a>
                                            )
                                        } else {
                                            return (
                                                <div key={item._id} className="about-item text-center">
                                                    <div className="p-2">
                                                        <div className="d-flex-column square" style={this.profileStyle(item._photo)}></div>
                                                    </div>
                                                    <div className="mt-1 font-size-clamp-min082rem-cen200vw-max175rem text-outline-shadow6" style={{ whiteSpace: 'nowrap' }}>{item.name}</div>
                                                </div>
                                            )
                                        }
                                    })
                                }
                            </div>
                        </div>
                        <div className="pb-5">
                            <div className="pagetitle mb-4">
                                <h3 className="mb-2 text-outline-shadow10">공식제휴</h3>
                            </div>
                            <div className="row about-container">
                                {
                                    this.state.associated.map(item => {
                                        return (
                                            <a href={item[2]} className="about-item about-linked" target="_blank" rel="noopener noreferrer">
                                                <div key={item._id} className="text-center">
                                                    <div className="p-2">
                                                        <div className="d-flex-column square" style={this.profileStyleByUrl(item[3])}></div>
                                                    </div>
                                                    <div className="col mt-1 font-size-clamp-min082rem-cen200vw-max175rem text-outline-shadow6" style={{ whiteSpace: 'nowrap' }}>
                                                        {item[0]}
                                                        <div className="text-outline-shadow4" style={{ fontSize: '0.7em', whiteSpace: 'nowrap' }}>{item[1]}</div>
                                                    </div>
                                                </div>
                                            </a>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default AboutUs
