import Api from "./api"
const url = "user"
const User = {
    create : async (params = {}) => {
        return await Api.post(`${url}`, params)
    },
    login : async (params = {}) => {
        return await Api.post(`${url}/login`, params)
    }, 
    show : async (id) => {
        return await Api.get(`${url}/${id}`)
    },
    update : async (params = {}) => {
        return await Api.patch(`${url}`, params)
    },
    get: async (params = {}) => {
        return await Api.get(`${url}`, params)
    },
    withdraw: async (params = {}) => {
        return await Api.patch(`${url}`, params)
    },
    rest: async () => {
        return await Api.patch(`${url}`, {'status': 'rest'})
    },
    approval: async () => {
        return await Api.patch(`${url}`, {'status': 'approval'})
    },
    getcode: async(params = {}) => {
        return await Api.post(`${url}/email`, params)
    },
    findid: async(params = {}) => {
        return await Api.post(`${url}/email/number`, params)
    },
    findpw: async(params = {}) => {
        return await Api.post(`${url}/password`, params)
    },
    patchphoto: async(params={}) => {
        return await Api.patch(`${url}/photo`, params)
    }
}

export default User