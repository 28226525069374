import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Area from '../api/area.api'

export class ProfileForm extends Component {
    static propTypes = {

    }

    constructor(props) {
        super(props)
    
        this.state = {
            areas: []
        }
    }
    

    componentDidMount = async () => {
        const areaRes = await Area.get()

        if ( areaRes.success ) {
            this.setState({
                areas: areaRes.areas
            })
        } else {
            alert ('지역정보를 불러올 수 없습니다. 다시 시도해주세요.')
        }
    }

    render() {

        const {
            state: {
                areas
            },
            props: {
                onChange,
                onBlur,
                profile : {
                    name,
                    birth,
                    gender,
                    email,
                    phone,
                    _area,
                    religion,
                    married,
                    smoking,
                    drinking,
                    tattoo,
                }
            }
        } = this

        return (
            <div className="card border-radius-0 mb-3">
                <div className="card-header">
                    <h6 className="mb-0">프로필 수정</h6>
                </div>
                <div className="card-body">
                    <div className="form-group mb-3">
                        <label>이름</label>
                        <input type="text" className="form-control" name="name" defaultValue={name} readOnly />
                    </div>
                    <div className="form-group mb-3">
                        <label>성별</label>
                        <input type="text" className="form-control" name="gender" defaultValue={gender === 'female' ? "여" : "남"} readOnly />
                    </div>
                    <div className="form-group mb-3">
                        <label>생년월일</label>
                        <input type="text" className="form-control" name="birth" defaultValue={birth} readOnly />
                    </div>
                    <div className="form-group mb-3">
                        <label>휴대전화</label>
                        <input type="number" className="form-control" name="phone" onChange={onChange} onBlur={onBlur} defaultValue={phone} onChange={onChange} readOnly/>
                    </div>
                    <div className="form-group mb-3">
                        <label>이메일 ID</label>
                        <input type="text" className="form-control" name="email" onChange={onChange} onBlur={onBlur} defaultValue={email} onChange={onChange} disabled/>
                        <p className="small text-secondary">* 비밀번호 분실시 등록된 이메일 ID로 전송됩니다.</p>
                    </div>
                    <div className="form-group mb-3">
                        <label>지역</label>
                        <select className="form-control" name="_area" onChange={onChange}  onBlur={onBlur} value={_area} >
                            {
                                areas.map( item => <option key={item._id} value={item._id} >{item.city} {item.name}</option> )
                            }
                        </select>
                    </div>
                    <div className="form-group mb-3">
                        <label>종교</label>
                        <select className="form-control" name="religion" onChange={onChange} onBlur={onBlur} defaultValue={religion} >
                            <option value="무교">무교</option>
                            <option value="기독교">기독교</option>
                            <option value="천주교">천주교</option>
                            <option value="불교">불교</option>
                            <option value="기타">기타</option>
                        </select>
                    </div>
                    <div className="form-group mb-3">
                        <label>미혼여부</label>
                        <select className="form-control" name="married" onChange={onChange} onBlur={onBlur} defaultValue={married} >
                            <option value="미혼">미혼</option>
                            <option value="돌싱">돌싱</option>
                        </select>
                    </div>
                    <div className="form-group mb-3">
                        <label>흡연</label>
                        <select className="form-control" name="smoking" onChange={onChange} onBlur={onBlur} defaultValue={smoking} >
                            <option value="흡연">흡연</option>
                            <option value="비흡연">비흡연</option>
                        </select>
                    </div>
                    <div className="form-group mb-3">
                        <label>음주여부</label>
                        <select className="form-control" name="drinking" onChange={onChange} onBlur={onBlur} defaultValue={drinking} >
                            <option value="즐겨함">즐겨함</option>
                            <option value="가끔">가끔</option>
                            <option value="안함">안함</option>
                        </select>
                    </div>
                    <div className="form-group mb-3">
                        <label>타투</label>
                        <select className="form-control" name="tattoo" onChange={onChange} onBlur={onBlur} defaultValue={tattoo} >
                            <option value="크고화려함">크고화려함</option>
                            <option value="손바닥정도">손바닥정도</option>
                            <option value="포인트살짝">포인트살짝</option>
                            <option value="안함">안함</option>
                        </select>
                    </div>
                    <div className="form-group mb-3">
                        <label>비밀번호</label>
                        <div>
                            <a href="/passwordchange" className="btn btn-secondary">비밀번호 수정</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProfileForm
