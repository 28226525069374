import React, { Component } from 'react'
import PropTypes from 'prop-types'

export class PaymentTerms extends Component {
    static propTypes = {

    }

    render() {
        return (
            <div>
                <h6>취소/환불 규정</h6>
                <ul className="list-unstyled small">
                    <li>
                        1) 신용카드 또는 다른 결제수단을 이용하여 성사이용권 결제 후 7일 이내 환불요청시 전액 취소가 가능합니다. 이후 이용권의 환불은 구매 후 6개월간 유효합니다
                        <div>
                        &nbsp;&nbsp;&nbsp;&nbsp;예 : [상품별 이용권 남은개수 * 상품별 구매단가 * (잔여일수/총계약기간) = 환불금]
                        </div>
                    </li>
                    <br />
                    <li>
                        2) 신용카드 또는 다른 결제수단을 이용하여 이어줘 충전 후 사용하지 않고 7일 이내 환불요청시 전액 취소가 가능합니다. 이어줘는 소모성 상품이며 할인 적용된 이어줘 상품을 1개 이상 사용시 환불은 불가합니다
                    </li>
                    <br />
                    <li>
                        3) 신용카드 또는 다른 결제수단을 이용하여 프리미엄이용권을 사용하지 않고 7일 이내 환불요청시 전액 취소가 가능합니다. 이후 이용권의 환불은 구매 후 6개월간 유효합니다. 
                    <br />
                        &nbsp;&nbsp;단, 프리미엄이용권은 할인 적용된 상품이므로 1개 이상 사용시 사용된 이용권은 일반 성사이용권으로 간주되며 프리미엄이용권 구매금액보다 차액이 남아있을 경우에만 환불이 가능합니다
                        <br/>
                        <div>
                        &nbsp;&nbsp;&nbsp;&nbsp;예 : [(프리미엄이용권 구매단가 – 사용된 이용권은 일반 성사이용권으로 단가적용) * (잔여일수/총계약기간) = 환불금]
                        </div>
                    </li>
                    <br />
                    <li>
                        4) 일방적인 약속 파기 등 귀책 사유가 발생시 환불이 불가하며 구매한 이용권의 혜택은 소멸됩니다.
                    </li>
                    <br />
                    <li>
                        5) "웹"과 이용자 간에 발생한 전자상거래 분쟁과 관련하여 이용자의 피해구제신청이 있는 경우에는 공정거래위원회 또는 시,도지사가 의뢰하는 분쟁조정기관의 조정에 따룰 수 있습니다.
                        <div>
                        &nbsp;&nbsp;&nbsp;&nbsp;@ 취소/환불에 대한 자세한 문의는 담당매니저 또는 고객센터(1644-1885)로 문의하시길 바랍니다
                        </div>
                    </li>
                    <br />
                </ul>
            </div>
        )
    }
}

export default PaymentTerms
