import React, { Component } from 'react'
import PropTypes from 'prop-types'

export class PaymentNotice extends Component {
    static propTypes = {

    }

    render() {
        return (
            <div>
                <h6>회원등급 및 이용권 차감</h6>
                <ul className="list-unstyled small">
                    <li>
                        1) 성사이용권은 성사 확정시에만 구매해주시길 바랍니다.
                    </li>
                    <br />
                    <li>
                        2) <a href="/grade">회원등급기준 보기</a>
                    </li>
                    <br />
                </ul>
            </div>
        )
    }
}

export default PaymentNotice
