import React, { Component } from 'react'
import Footer from '../components/Footer'

import aboutService from '../img/aboutService.png'
import manager1 from '../img/manager1.jpg'

export class OurService extends Component {

    render() {
        return (
            <div>
                <div>
                    <div className="container pt-3">
                        <div className="mb-5">
                            <div className="pagetitle mb-3">
                                <h3 className="mb-2 px-2">1:1 케어서비스란?</h3>
                            </div>
                            <img className="w-100" src={aboutService} alt="1:1 케어서비스"/>
                        </div>
                        {/* <div className="row mb-5">
                            <div className="col-sm-6">
                                <img className="w-100" src={manager1} alt="managermiho"/>
                            </div>
                            <div className="col-sm-6">
                                <div className="px-2 py-4 text-center d-flex flex-column" style={{height: '100%'}}>
                                    <div style={{ margin:"auto 0"}}>
                                    <h3>
                                        "동종업계 오랜 경력의 다수<br />매니저들이 연애해 회원님을<br />1:1케어로 확실히 모시겠습니다."
                                    </h3>
                                    <h5>
                                        결혼정보업 15년 경력 <br /> 연애해 총괄매니저
                                    </h5>
                                    <p>
                                        -유민옥-
                                    </p>
                                    </div>
                                </div>
                                
                            </div>
                        </div> */}
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default OurService
