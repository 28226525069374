import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Config from '../config/config'

export class ProfileForm4 extends Component {
    static propTypes = {

    }

    _renderPreview(name, filenames, index, disabled, onChange, deletePhoto) {
        const length = filenames ? filenames.length : 0
        if (length < index)
            return (<></>)

        const jsxInput = disabled ? (<input name={name}
            type="file"
            accept="image/jpeg, image/png"
            className="form-control"
            onChange={e => onChange(index, e)}
            onClick={e => e.target.value = null}
            hidden
            disabled
        />) : (<input name={name}
            type="file"
            accept="image/jpeg, image/png"
            className="form-control"
            onChange={e => onChange(index, e)}
            onClick={e => e.target.value = null}
            hidden
        />)

        if (length == index) {
            return (<div key={index}>
                <label className="preview d-flex justify-content-center">
                    <div className="align-self-center text-danger">
                        <i className="fas fa-camera"></i>
                    </div>
                    {jsxInput}
                </label>
            </div>)
        }

        return (<div key={index}>
            <label className="preview d-flex justify-content-center mr-2"
                style={{
                    backgroundImage: `url("${Config.photoUrl}${filenames[index]}")`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}>
                {jsxInput}
            </label>
            {
                deletePhoto &&
                <div className="text-center">
                    <span className="badge badge-secondary" onClick={() => deletePhoto(index, name)}>삭제</span>
                </div>
            }
        </div>)
    }

    render() {
        const {
            props: {
                onChange,
                deletePhoto,
                photos: {
                    _main,
                    _privateR,
                    _hobbyLife
                }
            }
        } = this

        return (
            <div className="card border-radius-0 mb-3">
                <div className="card-header">
                    <h6 className="mb-0">프로필 사진</h6>
                </div>
                <div className="card-body">
                    <div className="form-group">
                        <label className="small">프로필 사진</label>
                        {this._renderPreview("_main", _main, 0, true, onChange, null)}
                        <p className="small text-secondary">* 프로필이미지변경은 관리자 또는 매니저에게 문의해주세요.</p>
                    </div>
                    <div className="form-group">
                        <label className="small">개인 사진</label>
                        <div className="d-flex flex-wrap">
                            {this._renderPreview("_privateR", _privateR, 0, false, onChange, 1 < _privateR.length ? deletePhoto : null)}
                            {this._renderPreview("_privateR", _privateR, 1, false, onChange, deletePhoto)}
                            {this._renderPreview("_privateR", _privateR, 2, false, onChange, deletePhoto)}
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="small">취미 사진</label>
                        <div className="d-flex flex-wrap">
                            {this._renderPreview("_hobbyLife", _hobbyLife, 0, false, onChange, 1 < _hobbyLife.length ? deletePhoto : null)}
                            {this._renderPreview("_hobbyLife", _hobbyLife, 1, false, onChange, deletePhoto)}
                            {this._renderPreview("_hobbyLife", _hobbyLife, 2, false, onChange, deletePhoto)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProfileForm4
