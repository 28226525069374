import React, { Component } from 'react'
import moment from 'moment'
import { AuthContext } from '../components/AuthenticatedComponent'
import optionChecker from '../helper/optionChecker.helper'
import Age from '../helper/age.helper'
import Match from '../api/match.api'
import Goods from '../helper/goods.helper'
import Config from '../config/config'
export class MatchState extends Component {

    static contextType = AuthContext;

    constructor(props) {
        super(props)

        this.state = {
            showItem: null,
            connectItem: null,
            standardTicket: null,
            goldTicket: null,
            platinumTicket: null,
            diamondTicket: null,

            loaded: false,
            status: "response",
            items: null,
        }
    }

    componentDidMount = async () => {
        const {
            context: {
                history
            }
        } = this

        this.setState({
            showItem: Goods.create(history.showItem_free, history.showItem_paid, history.showItem_price),
            connectItem: Goods.create(history.connectItem_free, history.connectItem_paid, history.connectItem_price),
            standardTicket: Goods.create(history.standardTicket_free, history.standardTicket_paid, history.standardTicket_price),
            goldTicket: Goods.create(history.goldTicket_free, history.goldTicket_paid, history.goldTicket_price),
            platinumTicket: Goods.create(history.platinumTicket_free, history.platinumTicket_paid, history.platinumTicket_price),
            diamondTicket: Goods.create(history.diamondTicket_free, history.diamondTicket_paid, history.diamondTicket_price),
        })

        if (!await this._getDatas(this.state.status))
            return false
    }

    _sortByRequestAt = (a, b) => {
        return moment(b.requestAt).diff(moment(a.requestAt)) // NOTE: jason_wang: 이어주는중, 약속대기는 과거의 내역이다. 가장 값이 높은게 현재랑 가까운 정보이다
    }

    _sortByUpdatedAt = (a, b) => {
        return moment(b.updatedAt).diff(moment(a.updatedAt)) // NOTE: jason_wang: 이어주는중, 약속대기는 과거의 내역이다. 가장 값이 높은게 현재랑 가까운 정보이다
    }

    _sortByMeetAt = (a, b) => {
        return moment(a.meetAt).diff(moment(b.meetAt)) // NOTE: jason_wang: 매칭성공은 미래의 예정된 약속시간 내역이다. 가장 값이 작은게 현재랑 가까운 정보이다
    }

    async _getDatas(status) {
        let params = {
            status,
            type: 'request',
            all: 'all',
            screen: 'matchstate',
        }

        const now = moment()
        if ('response' === status) {
            if (Config.expireResponse) {
                params.s_r = moment(now).subtract(Config.expireResponse, 'days').format("YYYY-MM-DD 00:00:00")
                params.e_r = now.format("YYYY-MM-DD 23:59:59")
            }
        } else if ('wait' == status) {
            if (Config.expireWait) {
                params.s_h = moment(now).subtract(Config.expireWait, 'days').format("YYYY-MM-DD 00:00:00")
                params.e_h = now.format("YYYY-MM-DD 23:59:59")
            }
        } else if ('success' === status) {
            params.s_m = now.format("YYYY-MM-DD HH:mm:ss")
        }

        const response = await Match.get(params)
        if (response.success) {
            const matches = [...response.matches]
            matches.sort('response' === status ? this._sortByRequestAt :
                         'wait' === status ? this._sortByUpdatedAt :
                         this._sortByMeetAt)
            const items = matches.map(match => {
                const response = match._response
                const user = response._user
                return {
                    photo: this._getProfileUrl(user._photos ? user._photos._main : null),
                    rate: match.rate,
                    userBasic: [
                        { name: '등급', value: optionChecker.grade(user.grade) },
                        { name: '지역', value: user._area.city },
                        { name: '나이', value: Age(user.birth) },
                    ],
                    date: 'response' === status ? match.requestAt : 
                          'wait' === status ? match.updatedAt :
                          match.meetAt
                }
            })
            this.setState({
                loaded: true,
                items: items,
            })
            return true
        } else {
            if (!Config.__LIVE__)
                alert(response.message)
            alert('매칭정보 조회에 실패하였습니다')
            return false
        }
    }

    _getProfileUrl(url = null) {
        return url ? `${Config.photoUrl}${url}` : `${Config.staticImageUrl}/user.png`
    }

    _getMatchStatus(item) {
        const status = this.state.status
        switch (status) {
            case 'response':
                return (<span className="badge badge-danger mr-2 offset-y2px">매칭확률 : {item.rate}%</span>)
            case 'wait':
                return (<span className="badge badge-info mr-2 offset-y2px">약속대기</span>)
            case 'success':
                return (<span className="badge badge-primary mr-2 offset-y2px">매칭성공</span>)
        }
    }

    _toDateString(date) {
        const dateMoment = moment(date)
        const month = dateMoment.month() + 1
        const day = dateMoment.date()
        const dayOfWeekNumber = dateMoment.day()
        const dayOfWeek = 0 == dayOfWeekNumber ? '일' :
                          1 == dayOfWeekNumber ? '월' :
                          2 == dayOfWeekNumber ? '화' :
                          3 == dayOfWeekNumber ? '수' :
                          4 == dayOfWeekNumber ? '목' :
                          5 == dayOfWeekNumber ? '금' : '토'
        const hour = dateMoment.hour()
        const meridiem = 12 > hour ? '오전' : '오후'
        const hour12 = 0 == hour ? 12 :
                       12 > hour ? hour :
                       12 == hour ? 12 : 
                       hour - 12
        const minute = dateMoment.minutes()
        return `${month}월 ${day}일 (${dayOfWeek}) ${meridiem} ${hour12}:${(minute.toString().padStart(2, '0'))}`
    }

    _getDate(item) {
        const dateString = this._toDateString(item.date)
        const status = this.state.status
        switch (status) {
            case 'response':
                return (<label className="padd-mini text-darklight text-center" style={{ width: '95%' }}>{dateString}</label>)
            case 'wait':
                return (<label className="alert alert-mini alert-info text-center" style={{ width: '95%' }}>{dateString}</label>)
            case 'success':
                return (<label className="alert alert-mini alert-primary text-center" style={{ width: '95%' }}>{dateString}</label>)
        }
        return (<></>)
    }

    _getEmptySpace() {
        const status = this.state.status
        switch (status) {
            case 'response':
                return "진행중인 이어줘 요청 내역이 없습니다"
            case 'wait':
                return "현재 약속대기중인 내역이 없습니다"
            case 'success':
                return "예정된 소개팅이 없습니다"
        }
        return ""
    }

    _onClickTabItem = tab => {
        this.setState({
            loaded: false,
            status: tab,
            items: null,
        })
        this._getDatas(tab)
    }

    render() {
        const {
            state: {
                showItem,
                connectItem,
                standardTicket,
                goldTicket,
                platinumTicket,
                diamondTicket,

                loaded,
                status,
                items,
            },

        } = this

        const tabClass = 'tab-list-item-sharpen col'
        const tabClassActive = 'tab-list-item-sharpen col tab-list-active-sharpen'

        const activeTabIsResponse = 'response' === status
        const activeTabIsWait = 'wait' === status
        const activeTabIsSuccess = 'success' === status

        const tabClassResponse = activeTabIsResponse ? tabClassActive : tabClass
        const tabClassWait = activeTabIsWait ? tabClassActive : tabClass
        const tabClassSuccess = activeTabIsSuccess ? tabClassActive : tabClass

        let contentHeader1 = (<></>)
        let contentHeader2 = (<></>)
        switch (status) {
            case 'response':
                contentHeader1 = (
                    <>
                        <div className="small text-red matchstate-lineheight">* 이어줘 실패시 리스트에서 자동으로 사라집니다</div>
                    </>
                )
                if (Config.expireResponse)
                    contentHeader2 = (<div className="small text-secondary matchstate-lineheight font-weight-light">{`정보는 ${Config.expireResponse}일동안 유지됩니다`}</div>)
                break
            case 'wait':
                contentHeader1 = (
                    <>
                        <div className="small text-red matchstate-lineheight">* 매칭된 두 분 중 낮은 등급으로 성사이용권이 차감됩니다</div>
                        <div className="small text-red matchstate-lineheight">* 소개팅 약속이 확정되었을 시에만 성사이용권을 구매바랍니다</div>
                    </>
                )
                if (Config.expireWait)
                    contentHeader2 = (<div className="small text-secondary matchstate-lineheight font-weight-light">{`정보는 ${Config.expireWait}일동안 유지됩니다`}</div>)
                break
            case 'success':
                contentHeader1 = (
                    <>
                        <div className="small text-red matchstate-lineheight">* 매칭성공은 예정된 소개팅을 보여줍니다</div>
                        <div className="small text-red matchstate-lineheight">* 소개팅 전날 상대방의 연락처가 SNS로 발송됩니다</div>
                    </>
                )
        }

        return (
            <div>
                <div className="row">
                    <div className="col-3">
                        <div className="px-2 separator-right">
                            <p className="small mb-0 font-weight-bold text-secondary">이어줘충전</p>
                            <div className="d-flex w-100">
                                <div className="w-100">
                                    <h6 className="text-secondary mb-0"><span className="text-danger" style={{ fontSize: '27px' }}>{Goods.count(connectItem)}</span> 회</h6>
                                    <div className="small">이어줘</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-9">
                        <div className="px-2">
                            <p className="small mb-0 font-weight-bold text-secondary">내 성사 이용권</p>
                            <div className="d-flex w-100">
                                <div className="w-100">
                                    <h6 className="text-secondary mb-0"><span className="text-danger" style={{ fontSize: '27px' }}>{Goods.count(standardTicket)}</span> 회</h6>
                                    <div className="small ">스탠다드</div>
                                </div>
                                <div className="w-100">
                                    <h6 className="text-secondary mb-0"><span className="text-danger" style={{ fontSize: '27px' }}>{Goods.count(goldTicket)}</span> 회</h6>
                                    <div className="small ">골드</div>
                                </div>
                                <div className="w-100">
                                    <h6 className="text-secondary mb-0"><span className="text-danger" style={{ fontSize: '27px' }}>{Goods.count(platinumTicket)}</span> 회</h6>
                                    <div className="small ">플래티넘</div>
                                </div>
                                <div className="w-100">
                                    <h6 className="text-secondary mb-0"><span className="text-danger" style={{ fontSize: '27px' }}>{Goods.count(diamondTicket)}</span> 회</h6>
                                    <div className="small ">다이아</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ height: "20px", width: "100%", clear: "both" }}></div>
                <div className="tabs">
                    <ol className="tab-list row mx-0 text-center">
                        <li className={tabClassResponse} onClick={e => this._onClickTabItem('response')}>이어주는중</li>
                        <li className={tabClassWait} onClick={e => this._onClickTabItem('wait')}>약속대기</li>
                        <li className={tabClassSuccess} onClick={e => this._onClickTabItem('success')}>매칭성공</li>
                    </ol>
                    <div className="tab-content">
                        <div className="col-12">
                            <div className="px-2">
                                {contentHeader1}
                                {contentHeader2}
                            </div>
                            <div>
                                <div className="row mt-2">
                                    {
                                        items && 0 < items.length ?
                                        items.map((item, index) => {
                                            return (
                                                <div className="col-sm-6 mb-3 px-2" key={index}>
                                                    <div className="card border-radius-0">
                                                        <div className="card-img-top border-radius-0 image-container">
                                                            <img className="image-content" src={item.photo} style={{ backgroundColor: '#e8e8e8' }} alt='프로필' />
                                                        </div>
                                                        <div className="card-body mx-auto text-center">
                                                            {this._getMatchStatus(item)}
                                                            {
                                                                item.userBasic.map((item, index) => {
                                                                    return (
                                                                        <span className="mr-2 text-danger" key={index}>#{item.value}</span>
                                                                    )
                                                                })
                                                            }
                                                            {this._getDate(item)}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }) : loaded ?
                                        (<div className="col-sm-12 mx-auto px-2">
                                            <div className="card my-4 py-5 text-center text-dark font-weight-normal border-secondary">
                                                <p>{this._getEmptySpace()}</p>
                                            </div>
                                        </div>) : (<></>)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default MatchState
