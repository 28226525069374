import Api from './api'
import Upload from '../helper/upload.helper'

const Photo = {
    upload: async files => {
        const result = await Upload.execute(files, params => Api.post('photo', params))
        if (result.error)
            return Api.error(result.error, result.status)
        
        return Api.success({ uploads: result.uploads })
    },
}

export default Photo