function createBufferFromFileAsync(file) {
    return new Promise((resolve, reject) => {
        let reader = new FileReader()
        reader.onload = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsArrayBuffer(file)
    })
}

const registry = {
    'image/jpeg': { mime: 'image/jpeg', pattern: [0xFF, 0xD8, 0xFF] },
    'image/png': { mime: 'image/png', pattern: [0x89, 0x50, 0x4E, 0x47] }
}

const Mime = {
    regist: (mime, pattern, mask) => {
        registry[mime] = { mime, pattern }
    },

    unregist: mime => {
        if (registry.hasOwnProperty(mime)) {
            delete registry[mime]
            return true
        }

        return false
    },

    getRegistry: () => registry,

    getRegisted: mime => {
        return registry[mime]
    },

    calcMaxPatternLength: () => {
        let max = 0
        for (let mime in registry) {
            const candidate = registry[mime].pattern.length
            if (max < candidate)
                max = candidate
        }
        return max
    },

    getMime: async (file, unknown = undefined) => {
        const blob = file.slice(0, Mime.calcMaxPatternLength())
        try {
            const buffer = await createBufferFromFileAsync(blob)
            const bytes = new Uint8Array(buffer)
            for (let mime in registry) {
                if (Mime.check(mime, bytes))
                    return mime
            }
        } catch (e) {
            return e.message
        }

        return unknown
    },

    check: (mime, bytes) => {
        const registed = registry[mime]
        if (!registed)
            return false

        const patternLength = registed.pattern.length
        const byteLength = bytes ? bytes.length : 0
        if (patternLength > byteLength)
            return false

        for (let n = 0; n < patternLength; ++n) {
            const p = registed.pattern[n]
            if (undefined !== p && p !== bytes[n])
                return false
        }

        return true
    }
}

export default Mime