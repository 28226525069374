import React, { Component } from 'react';
// import './App.css';
// import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Button, Nav, Card, Col, Row, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";

class FindId extends Component{
    render(){
        return(
            <div>
                <h1 class="text-center">비밀번호 찾기</h1><hr/>
                <Form>
                    <Form.Group as={Row} controlId="formHorizontalEmail">
                        <Form.Label column sm={2}>이름</Form.Label>
                        <Col sm={10}>
                        <Form.Control type="email" placeholder="홍길동" />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formHorizontalEmail">
                        <Form.Label column sm={2}>연락처</Form.Label>
                        <Col>
                        <InputGroup className="mb-3">
                        <Form.Control type="email" placeholder="010-1234-1234" />
                        <Button variant="outline-secondary">인증번호 받기</Button>
                        </InputGroup>
                        </Col>
                        <Col>
                        <InputGroup className="mb-3">
                        <Form.Control type="email" placeholder="문자승인번호" />
                        <Button variant="outline-secondary">인증</Button>
                        <InputGroup.Text id="basic-addon2"><i class="fas fa-eye"></i></InputGroup.Text>
                        </InputGroup>
                        </Col>
                    </Form.Group>
                    </Form>
                    <div className="text-center">
                        <Link to="/passwordchange">
                            <Button variant="primary">확인</Button>
                        </Link>
                    </div>

                    
            </div>
        );
    }
}
export default FindId;