import React, { Component } from 'react';
// import './App.css';
// import "bootstrap/dist/css/bootstrap.min.css";
import User from '../api/user.api';
import Config from '../config/config';

class FindPass extends Component{

    constructor(props) {
        super(props)
    
        this.state = {
            isSend: false,
            email: ''
        }
    }

    onChange = (e) => {
        this.setState({
            email: e.target.value
        })
    }

    onSubmit = async (e) => {
        e.preventDefault()

        const resFindpw = await User.findpw({
            email: this.state.email
        })

        if(resFindpw.success){

            this.setState({
                isSend: true
            })

        } else {
            if (Config.__LIVE__) {
                alert('정상처리 하지 못했습니다.')
            } else {
                alert("message: " + resFindpw.message + ", status: " + resFindpw.status)
            }
        }

        
    }
    
    render(){

        const {
            onSubmit,
            onChange,
            state: {
                isSend,
                email
            }
        } = this

        let afterMail = ''
        if(isSend){
            afterMail = <div>등록된 메일로 임시비밀번호를 전송했습니다.</div>
        }

        return(
            <div className="login-box py-3 mb-5" style={{marginTop:'50px'}}>
                <h5>비밀번호 찾기</h5>
                <form onSubmit={onSubmit}>
                    <div className="form-group floating-form-group">
                        <input type="email" className="form-control floating-input" name="email" onChange={onChange} defaultValue={email} />
                        <label className="floating-label">이메일</label>
                    </div>
                    <div>
                        {afterMail}
                    </div>
                    <div className="text-center">
                        <button className="btn btn-primary btn-block mb-5" type="submit">확인</button>
                        <a href="/login" className="text-secondary">로그인하러 가기</a>
                    </div>
                </form>
            </div>
        );
    }
}
export default FindPass;