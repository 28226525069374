import React, { Component } from 'react';
import '../App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import Api from '../api/api'
import Ban from '../api/ban.api'
import { AuthContext } from '../components/AuthenticatedComponent'
import queryString from 'query-string'
import {useLocation} from 'react-router-dom'

class Block extends Component {

    static contextType = AuthContext

    constructor(props) {
        super(props)
    
        this.caller_ban = Api.createCaller()
        this.caller_report = Api.createCaller()

        this.state = {
            activeTab: '차단관리',
            name: '',
            phone: '',
            banList: [],
            reportText: ''
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    claerUI = (name) => {
        let uiElement = document.getElementById(name)
        if (uiElement)
            uiElement.value = ""
    }

    onSubmitBan = async (e) => {
        e.preventDefault()
        let banList = this.state.banList
        const {
            name,
            phone
        } = this.state
        const banRes = await this.caller_ban(() => Ban.add(name, phone))
        if (banRes.success) {
            alert('등록되었습니다.')
            banList.push({ name, phone })
            this.setState({
                name: '',
                phone: '',
                banList: this.sortBanList(banList)
            })
            this.claerUI("name")
            this.claerUI("phone")
        } else {
            let message = banRes.message
            if (banRes.message.indexOf('이름') > -1) {
                message = '성을 제외한 이름 2글자이어야 합니다. (끝에서 2번째까지의 글자)'
            } else if (banRes.message.indexOf('연락처') > -1) {
                message = '연락처 뒷번호 4자리이어야 합니다.'
            }
            alert(message)
        }
    }

    deleteBan = async (e, index) => {
        let banList = this.state.banList
        let deleteMe = banList[index]
        const banRes = await this.caller_ban(() => Ban.delete(deleteMe.name, deleteMe.phone))
        if (banRes.success) {
            banList.splice(index, 1)
            this.setState({ banList: banList })
        } else {
            alert(banRes.message)
        }
    }

    onSubmitReport = async (e) => {
        e.preventDefault()
        const {
            name,
            phone,
            email,
        } = this.context.profile
        const reportText = this.state.reportText

        if (window.confirm('작성하신 내용으로 본사신고를 진행하시겠습니까?')) {
            const banRes = await this.caller_report(() => Ban.report(name, phone, email, reportText))
            if (banRes.success) {
                alert('본사신고가 접수되었습니다.');
                this.setState({ reportText: '' })
                this.claerUI("reportText")
            } else {
                alert(banRes.message)
            }
        }
    }

    componentDidMount = async () => {
        const banRes = await this.caller_ban(() => Ban.get())
        if (banRes.success) {
            this.setState({
                name: '',
                phone: '',
                banList: this.sortBanList(banRes.banList)
            })
        } else {
            alert('데이터 조회에 실패했습니다.')
        }
    }

    sortBanList = banList => {
        banList.sort((a, b) => {
            return a.name < b.name ? -1 : a.name > b.name ? +1 :
                   a.phone < b.phone ? -1 : a.phone > b.phone ? +1 :
                   0
        })

        return banList
    }

    onClickTabItem = tab => {
        this.setState({ activeTab: tab });
    }

    render(){
        const {
            onClickTabItem,
            onChange,
            onSubmitReport,
            onSubmitBan,
            deleteBan,
            state: {
                reportText,
                name,
                phone,
                banList,
                activeTab
            }
        } = this

        const tabClass = 'tab-list-item-sharpen col'
        const tabClassActive = 'tab-list-item-sharpen col tab-list-active-sharpen'

        const activeTabIsBan = activeTab === "차단관리"
        const activeTabIsReport = activeTab === "신고하기"

        const tabClassBan = activeTabIsBan ? tabClassActive : tabClass
        const tabClassReport = activeTabIsReport ? tabClassActive : tabClass

        const tabContentBan = activeTabIsBan ? (<div className="card border-radius-0 mb-3">
            <div className="card-body">
                <form onSubmit={onSubmitBan}>
                    <div className="d-flex">
                        <div className="form-group mb-3 mr-2 w-100">
                            <label>이름</label>
                            <input type="text" name="name" id="name" onChange={onChange} defaultValue={name} className="form-control" />
                        </div>
                        <div className="form-group mb-3 mr-2 w-100">
                            <label>연락처</label>
                            <input type="text" name="phone" id="phone" onChange={onChange} defaultValue={phone} className="form-control" />
                        </div>
                        <div className="mt-t w-100">
                            <label style={{ visibility: "hidden" }}>차단</label>
                            <button type="submit" className="btn btn-primary btn-block">차단</button>
                        </div>
                    </div>
                </form>
                <div className='mt-5'>
                    {
                        banList.length === 0 ? <div>등록된 차단이 없습니다.</div> :
                            <table className="table">
                                <thead>
                                    <tr>
                                        <td>이름</td>
                                        <td>연락처</td>
                                        <td>Act.</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        banList.map((item, index) => {
                                            return <tr key={index}>
                                                <td>{item.name}</td>
                                                <td>{item.phone}</td>
                                                <td><button onClick={(e) => deleteBan(e, index)} className="btn btn-sm btn-secondary">삭제</button></td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                    }
                </div>
            </div>
        </div>) : ''

        const tabContentReport = activeTabIsReport ? (<div className="card border-radius-0 mb-3">
            <div className="card-body">
                <div>
                    <label>불법피해 본사신고</label>
                    <textarea name="reportText" id="reportText" rows="6" onChange={onChange} defaultValue={reportText} className="form-control" />
                </div>
                <form onSubmit={onSubmitReport}>
                    <div className="mt-t w-100">
                        <label style={{ visibility: "hidden" }}>신고하기</label>
                        <button type="submit" className="btn btn-primary btn-block">신고하기</button>
                    </div>
                </form>
            </div>
        </div>) : ''

        return (
            <div className="tabs">
                <ol className="tab-list row mx-0 text-center">
                    <li className={tabClassBan} onClick={e => onClickTabItem('차단관리')}>차단관리</li>
                    <li className={tabClassReport} onClick={e => onClickTabItem('신고하기')}>신고하기</li>
                </ol>
                <div className="tab-content">
                    <div className="col-12">
                        {tabContentBan}
                        {tabContentReport}
                    </div>
                </div>
            </div>
        );
    }
}
export default Block;