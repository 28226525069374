import React, { Component } from 'react'
import Logo from '../img/logo.png'
import menuIcon from '../img/icons/menu-outline.svg'
import yeonhae1Logo from '../img/user-1.png'
import moment from 'moment'
import { AuthContext } from './AuthenticatedComponent'
import Config from '../config/config'


class Head extends Component {

    static contextType = AuthContext

    constructor(props) {
        super(props)

        this.state = {

        }
    }

    onLogout() {
        if (localStorage.getItem('autoLogin') === 'false') {
            sessionStorage.removeItem('token')
        } else {
            localStorage.removeItem('token')
        }
        localStorage.removeItem('autoLogin')
    }

    _getTextWidth(text, font) {
        const canvas = this._getTextWidth.canvas || (this._getTextWidth.canvas = document.createElement("canvas"))
        const context = canvas.getContext("2d")
        context.font = font
        const metrics = context.measureText(text)
        return metrics.width
    }

    _getCssStyle(element, prop) {
        return window.getComputedStyle(element, null).getPropertyValue(prop)
    }

    _getCanvasFontSize(element = null) {
        if (!element)
            element = document.body
        const fontWeight = this._getCssStyle(element, 'font-weight') || 'normal'
        const fontSize = this._getCssStyle(element, 'font-size') || '16px'
        const fontFamily = this._getCssStyle(element, 'font-family') || 'Helvetica Neue'

        return `${fontWeight} ${fontSize} ${fontFamily}`
    }

    _renderAdmin(teamName, managerPhone, profileImgStyle) {
        const hasLF = teamName.includes('\n')
        let adminNameContent = teamName
        if (hasLF) {
            const adminNameArray = adminNameContent.replace('\n', '\n<br/>\n').split('\n')
            adminNameContent = (<>
                {
                    adminNameArray.map(txt => {
                        return '<br/>' === txt ? (<br />) : (<>{txt}</>)
                    })
                }
            </>)
        }
        const content = hasLF || 80 < this._getTextWidth(teamName, this._getCanvasFontSize()) ? (<>
            <div className="align-self-center ml-2 text-dark head-font-style small" style={{ maxWidth: 80 }}>{adminNameContent}</div>
            {
                managerPhone &&
                <div className="align-self-center ml-3 text-dark head-font-style small">
                    <a href={`sms:${managerPhone}`}>담당<br />호출</a>
                </div>
            }
        </>) : (<>
            <div className="align-self-center ml-2 text-dark head-font-style">{teamName}</div>
            {
                managerPhone &&
                <div className="align-self-center ml-2 text-dark head-font-style">
                    <span>/</span> <a href={`sms:${managerPhone}`}>담당호출</a>
                </div>
            }
        </>)
        
        return (<div className="col-auto ml-auto px-2 d-flex">
            <div className="m-0 shadow-sm" style={profileImgStyle}></div>
            {content}
        </div>)
    }

    render() {
        const mainPhoto = this.context.profile._photos._main
        let teamPhoto = null
        let teamName = ""
        let managerPhone = ""

        const manager = this.context.profile._manager
        if (manager) {
            managerPhone = manager.phone
            const admin = manager._admin
            if (admin) {
                teamName = manager.hasOwnProperty('name2nd') && manager.name2nd ? manager.name2nd :
                    admin.hasOwnProperty('name2nd') && admin.name2nd ? admin.name2nd :
                        admin.name
                teamPhoto = manager._photo ? manager._photo : admin._photo
            } else {
                teamName = manager.hasOwnProperty('name2nd') && manager.name2nd ? manager.name2nd : ""
                teamPhoto = manager._photo
            }
        }


        const userName = this.context.profile.name
        const profileImgStyle = {
            backgroundImage: teamPhoto ? `url("${Config.photoUrl}${teamPhoto}")` : `url(${yeonhae1Logo})`,
            backgroundSize: 'cover',
            height: '44px',
            width: '44px',
            borderRadius: '50%'
        }

        return (
            <div className="header">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex align-items-center">
                                <div className="dropdown">
                                    <button className="btn nav-menu btn-link-default px-2" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <img src={menuIcon} alt="" className="icon-size-24" />
                                    </button>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <a className="dropdown-item" href="/matchstate">실시간매칭현황</a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item" href="/pass">서비스결제</a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item" href="/infomodify">내프로필관리</a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item" href="/interest">이상형설정</a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item" href="/block">차단/신고</a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item" href="/" onClick={this.onLogout}>로그아웃</a>
                                    </div>
                                </div>

                                <div className="col text-left">
                                    <a className="navbar-brand" href="/">
                                        <img src={Logo} alt="logo" style={{ height: '30px', width: 'auto' }} />
                                    </a>
                                </div>
                                {this._renderAdmin(teamName, managerPhone, profileImgStyle)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Head;