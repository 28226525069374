import React, { Component } from 'react'
import PropTypes from 'prop-types'

export class ProfileForm3 extends Component {
    static propTypes = {

    }

    render() {
        const {
            props: {
                onChange,
                onBlur,
                profile: {
                    values,
                    personality,
                    advantage,
                    idealType,
                    want,
                    talkTo
                }
            }
        } = this
        return (
            <div className="card border-radius-0 mb-3">
                <div className="card-header">
                    <h6 className="mb-0">담당매니저 참고자료</h6>
                </div>
                <div className="card-body">
                    {/* <div className="form-group mb-3">
                        <label>저의 성격과 잘하는 것은요</label>
                        <textarea className="form-control" name="values" defaultValue={values} onChange={onChange} onBlur={onBlur} cols="5" rows="2" />
                    </div> */}
                    {/* <div className="form-group mb-3">
                        <label>성격</label>
                        <textarea disabled className="form-control" name="personality" defaultValue={personality.value} onChange={onChange} onBlur={onBlur} cols="5" rows="2" />
                    </div> */}
                    <div className="form-group mb-3">
                        <label>매력포인트</label>
                        <textarea disabled className="form-control" name="advantage" defaultValue={advantage} onChange={onChange} onBlur={onBlur} cols="5" rows="2" />
                    </div>
                    <div className="form-group mb-3">
                        <label>이상형</label>
                        <textarea disabled className="form-control" name="idealType" defaultValue={idealType} onChange={onChange} onBlur={onBlur} cols="5" rows="2" />
                    </div>
                    {/* <div className="form-group mb-3">
                        <label>연애시 가장 하고 싶은 일은요</label>
                        <textarea className="form-control" name="want" defaultValue={want} onChange={onChange} onBlur={onBlur} cols="5" rows="2" />
                    </div> */}
                    <div className="form-group mb-3">
                        <label>데이트 스타일</label>
                        <textarea disabled className="form-control" name="want" defaultValue={want} onChange={onChange} onBlur={onBlur} cols="5" rows="2" />
                    </div>
                    <div className="form-group mb-3">
                        <label>매니저님 이것만은 안돼요.</label>
                        <textarea className="form-control" name="talkTo" defaultValue={talkTo} onChange={onChange} onBlur={onBlur} cols="5" rows="2" />
                    </div>
                </div>
            </div>
        )
    }
}

export default ProfileForm3
