import React, { Component } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import FullScreenLayouts from './layouts/fullScreenLayouts'
import DefaultLayout from './layouts/defaultLayout'


import AuthenticatedComponent from './components/AuthenticatedComponent';



class App extends React.Component{
    render(){
        return(
            <Router>
                <Switch>
                    <Route exact path="/">
                        <Redirect to="/main" />
                    </Route>
                    <Route exact path='/login' component={FullScreenLayouts}/>
                    <Route exact path='/findid' component={FullScreenLayouts}/>
                    <Route exact path='/findpw' component={FullScreenLayouts}/>
                    <Route exact path='/findpw2' component={FullScreenLayouts}/>
                    <Route exact path='/signup' component={FullScreenLayouts}/>
                    <Route exact path='/service' component={FullScreenLayouts}/>
                    <Route exact path='/about_us' component={FullScreenLayouts}/>
                    <Route exact path='/Menual' component={FullScreenLayouts}/>
                    <AuthenticatedComponent>
                        <Route exact path='/main' component={DefaultLayout} />
                        <Route exact path='/matchstate' component={DefaultLayout} />
                        <Route exact path='/pass' component={DefaultLayout} />
                        <Route exact path='/infomodify' component={DefaultLayout}/>
                        <Route exact path='/interest' component={DefaultLayout}/>
                        <Route exact path='/block' component={DefaultLayout}/>
                        <Route exact path='/passwordchange' component={DefaultLayout}/>
                        <Route exact path='/grade' component={DefaultLayout}/>
                    </AuthenticatedComponent>
                </Switch>
            </Router>
        );
    }
}
export default App;