import Api from "./api"
const url = "match"
const Match = {
    get: async (params = {}) => {
        return await Api.get(`${url}`, params)
    },
    todayShow : async (id) => {
        return await Api.get(`${url}/today`)
    },
    create : async (params = {}) => {
        return await Api.post(`${url}`, params)
    },
    setResponse: async (id, params = {}) => {
        return await Api.patch(`${url}/${id}`, params)
    }
}

export default Match