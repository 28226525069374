let Config = {}

Config.__LIVE__ = process.env.REACT_APP_WEB_ENV === "production"
Config.__STAGE__ = process.env.REACT_APP_WEB_ENV === "stage"
Config.__DEV__ = process.env.REACT_APP_WEB_ENV === "development"

Config.SANDBOX = process.env.REACT_APP_SANDBOX === "true"

Config.apiUrl = Config.__LIVE__ ? process.env.REACT_APP_API_URL : Config.__STAGE__ ? process.env.REACT_APP_API_URL_STAGE : process.env.REACT_APP_API_URL_DEV
Config.apiRequestBaseUrl = `${Config.apiUrl}${process.env.REACT_APP_API_ADD_URL}`
Config.staticImageUrl = `${Config.apiUrl}/public/images`

Config.clientUrl = Config.__LIVE__ ? process.env.REACT_APP_CLIENT_URL : Config.__STAGE__ ? process.env.REACT_APP_CLIENT_URL_STAGE : process.env.REACT_APP_CLIENT_URL_DEV

Config.adminUrl = Config.__LIVE__ ? process.env.REACT_APP_ADMIN_URL : Config.__STAGE__ ? process.env.REACT_APP_ADMIN_URL_STAGE : process.env.REACT_APP_ADMIN_URL_DEV

Config.moduleUrl = Config.__LIVE__ ? process.env.REACT_APP_MODULE_URL : Config.__STAGE__ ? process.env.REACT_APP_MODULE_URL_STAGE : process.env.REACT_APP_MODULE_URL_DEV

Config.photoUrl = Config.__LIVE__ ? process.env.REACT_APP_PHOTO_URL : Config.__STAGE__ ? process.env.REACT_APP_PHOTO_URL_STAGE : process.env.REACT_APP_PHOTO_URL_DEV

Config.defaultLimit = 10

Config.showTotalRegisted = process.env.REACT_APP_SHOW_TOTAL_REGISTED === "true"
Config.showTodayRegisted = process.env.REACT_APP_SHOW_TODAY_REGISTED === "true"
Config.showTodayMatch = process.env.REACT_APP_SHOW_TODAY_MATCH === "true"

Config.expireResponse = process.env.REACT_APP_EXPIRE_RESPONSE ? Number(process.env.REACT_APP_EXPIRE_RESPONSE) : 0
Config.expireWait = process.env.REACT_APP_EXPIRE_WAIT ? Number(process.env.REACT_APP_EXPIRE_WAIT) : 0
export default Config