import React, { Component } from 'react'
import { Route } from 'react-router-dom'
import Login from '../pages/Login'
import FindId from '../pages/FindId'
import Signup from '../pages/Signup';
import FindPass from '../pages/FindPass';
import FindPass2 from '../pages/FindPass2';
import OurService from '../pages/OurService';
import AboutUs from '../pages/AboutUs';
import Menual from '../pages/Menual';

export class fullScreenLayouts extends Component {

    

    render() {

        return (
            <div>
                <Route exact path="/login" component={Login} />
                <Route exact path="/signup" component={Signup} />
                <Route exact path='/findid' component={FindId}/>
                <Route exact path='/findpw' component={FindPass}/>
                <Route exact path='/findpw2' component={FindPass2}/>
                <Route exact path='/service' component={OurService}/>
                <Route exact path='/about_us' component={AboutUs}/>
                <Route exact path='/Menual' component={Menual}/>
            </div>
        )
    }
}

export default fullScreenLayouts
