import React, { Component } from 'react'
import { AuthContext } from '../components/AuthenticatedComponent'
import Config from '../config/config'
import Logo from '../img/logo.png'
import moment from 'moment'
import Age from '../helper/age.helper'
import OptionChecker from '../helper/optionChecker.helper'
import Area from '../api/area.api'
import Match from '../api/match.api'
import optionChecker from '../helper/optionChecker.helper'

export class UserMainCard extends Component {

    static contextType = AuthContext

    constructor(props) {
        super(props)

        this.state = {
            photos: [],
            enableShow: false
        }
    }

    requestClick = async () => {
        const {
            context: {
                profile: {
                    status
                }
            }
        } = this

        if (status === 'wait') {
            alert('가입심사중입니다. 심사완료후 다시 시도하세요.')
            return false
        } else {
            const matchRes = await Match.create({});

            if (matchRes.success) {
                this.setState({
                    enableShow: false
                })
            } else {
                alert(matchRes.message)
            }

        }
    }

    componentDidMount = async () => {
        const {
            profile,
            history,
            interrest,
            trophy
        } = this.context

        const {
            showable
        } = this.props

        let photos = []
        photos.push(profile._photos._main)
        photos.push(...profile._photos._privateR)
        photos.push(...profile._photos._hobbyLife)

        let userBasic = []
        // userBasic.push({ name: '이름', value : profile.name })
        userBasic.push({ name: '등급', value: OptionChecker.grade(profile.grade) })
        userBasic.push({ name: '지역', value: profile._area.city })
        userBasic.push({ name: '나이', value: Age(profile.birth) })

        let userProfile = []
        if (profile.body.visible) {
            userProfile.push({ name: '체형', value: profile.body.value })
        }
        if (profile.education.visible) {
            userProfile.push({ name: '학력', value: profile.education.value })
        }
        if (profile.car.visible) {
            userProfile.push({ name: '차', value: optionChecker.car(profile.car.value) })
        }
        if (profile.estate.visible) {
            userProfile.push({ name: '자산', value: optionChecker.estate(profile.estate.value) })
        }
        if (profile.height.visible) {
            userProfile.push({ name: '키', value: optionChecker.height(profile.height.value) })
        }
        if (profile.income.visible) {
            userProfile.push({ name: '수입', value: optionChecker.income(profile.income.value) })
        }
        if (profile.insurance.visible) {
            userProfile.push({ name: '보험', value: profile.insurance.value })
        }
        if (profile.job.visible) {
            userProfile.push({ name: '직업', value: profile.job.value })
        }
        if (profile.personality.visible) {
            const personality = profile.personality.value
            if (personality && 0 < personality.length) {
                const ssv = []
                personality.forEach(item => ssv.push(item.replace(' ', '')))
                userProfile.push({ name: '성격', value: ssv.join(' ') })
            }
        }

        let userTrophy = []
        if (trophy) {
            if (trophy.pet.post) {
                userTrophy.push({ name: '반려', icon: 'icon-1', value: trophy.pet.value })
            }
            if (trophy.donation.post) {
                userTrophy.push({ name: '봉사기부', icon: 'icon-2', value: trophy.donation.value })
            }
            if (trophy.estate.post) {
                userTrophy.push({ name: '부동산', icon: 'icon-3', value: trophy.estate.value })
            }
            if (trophy.income.post) {
                userTrophy.push({ name: '작년소득', icon: 'icon-4', value: trophy.income.value })
            }
            if (trophy.award.post) {
                userTrophy.push({ name: '수상장학', icon: 'icon-5', value: trophy.award.value })
            }
            if (trophy.tax.post) {
                userTrophy.push({ name: '세금납부', icon: 'icon-7', value: trophy.tax.value })
            }
            if (trophy.work.post) {
                userTrophy.push({ name: '재직재학', icon: 'icon-8', value: trophy.work.value })
            }
            if (trophy.sales.post) {
                userTrophy.push({ name: '작년도매출', icon: 'icon-9', value: trophy.sales.value })
            }
            if (trophy.graduation.post) {
                userTrophy.push({ name: '졸업학위', icon: 'icon-10', value: trophy.graduation.value })
            }
            if (trophy.license.post) {
                userTrophy.push({ name: '사업자/면허', icon: 'icon-6', value: trophy.license.value })
            }
            if (trophy.car.post) {
                userTrophy.push({ name: '차량', icon: 'icon-11', value: trophy.car.value })
            }
            if (trophy.family.post) {
                userTrophy.push({ name: '미혼돌싱', icon: 'icon-uniE900', value: trophy.family.value })
            }
        }

        this.setState({
            photos: photos,
            basic: userBasic,
            profileArr: userProfile,
            trophy: userTrophy,
            enableShow: showable
        })
    }

    render() {
        const {
            requestClick,
            props: {
                showable
            },
            state: {
                photos,
                basic,
                profileArr,
                trophy,
                enableShow
            },
            context: {
                profile
            }
        } = this

        if (!basic && !profileArr) {
            return false
        }

        return (
            <div className="row mt-2">
                <div className="col-md-4 mb-3">
                    <div className="card border-radius-0">
                        <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                            <ol className="carousel-indicators">
                                {
                                    photos.map((item, index) => {
                                        let classObj = ""
                                        if (index === 0) {
                                            classObj += 'active'
                                        }
                                        return (
                                            <li data-target="#carouselExampleIndicators" data-slide-to={index} className={classObj} key={index}></li>
                                        )
                                    })
                                }
                                {/* <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                                    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                                    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li> */}
                            </ol>
                            <div className="carousel-inner">
                                {
                                    photos.map((item, index) => {
                                        let classStyle = "carousel-item profile-carousel-item"
                                        if (index === 0) {
                                            classStyle += " active"
                                        }
                                        let style = {
                                            backgroundImage: `url('${Config.photoUrl}${item}')`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center'
                                        }

                                        return (
                                            <div className={classStyle} style={style} key={index}></div>
                                        )
                                    })
                                }
                            </div>
                            <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="sr-only">Previous</span>
                            </a>
                            <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="sr-only">Next</span>
                            </a>
                        </div>
                        <div className="card-body">
                            <div className="">
                                {
                                    basic.map((item, index) => {
                                        return (
                                            <span className="mr-2 text-danger" key={index}>#{item.value}</span>
                                        )
                                    })
                                }
                            </div>
                            <div>
                                <div>
                                    <small className="text-secondary font-weight-bold">공개정보</small>
                                </div>
                                <div>
                                    {
                                        profileArr.length === 0 ? <div className="text-sm text-secondary">공개정보 없음</div> :
                                            profileArr.map((item, index) => {
                                                return (
                                                    <span className="badge badge-info mr-2" key={index}>{item.name}: {!item.value ? '비공개' : item.value}</span>
                                                )
                                            })
                                    }
                                </div>
                            </div>
                            <div>
                                <div>
                                    <small className="text-secondary font-weight-bold">트로피정보</small>
                                </div>
                                <div style={{ margin: '0 -7px' }}>
                                    {
                                        !trophy || trophy.length === 0 ? <div className="text-sm text-secondary mx-2">트로피정보 없음</div> :
                                            trophy.map((item, index) => {

                                                let color = ''
                                                if (item.value === '금') {
                                                    color = 'text-warning'
                                                } else if (item.value === '은') {
                                                    color = 'text-secondary'
                                                } else if (item.value === '동') {
                                                    color = 'text-bronze'
                                                } else {
                                                    color = 'text-danger'
                                                }

                                                if (!item.value || item.value === '없음' || item.value === '미등록') {
                                                    return false
                                                }

                                                if (item.value === '개') item.value = '반려견'
                                                if (item.value === '고양이') item.value = '반려묘'

                                                let label = ''
                                                if (item.name == '반려') {
                                                    label = item.value
                                                } else if (item.name === '미혼돌싱') {
                                                    label = item.value
                                                } else {
                                                    label = item.name
                                                }

                                                return (
                                                    <div className="d-inline-block text-center mx-2" key={index}>
                                                        <div>
                                                            <span className={`trophy ${item.icon} fs2 ${color}`}></span>
                                                        </div>
                                                        <div>
                                                            <small>{label}</small>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                    }
                                </div>
                            </div>
                            <div className="mt-3">
                                {
                                    !enableShow ?
                                        <button className="btn btn-secondary btn-block" onClick={() => { alert(`이미 소개해줘 요청을 했습니다.`) }}> 소개해줘 신청완료</button> :
                                        <button className="btn btn-danger btn-block" onClick={requestClick}>소개해줘</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default UserMainCard
