const validation = {
    password: (params) => {
        // var reg1 = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,30}$/;    // a-z 0-9 특수문자 중에 8자리 부터 30자리만 허용 한다는 뜻이구요
        var reg2 = /[a-z]/g;    
        var reg3 = /[0-9]/g;
        // return(reg1.test(params) &&  reg2.test(params) && reg3.test(params));
        return(reg2.test(params) && reg3.test(params));

    }
}

export default validation