import Api from "./api"
const url = "ban"
const Ban = {
    get: async () => {
        return await Api.get(`${url}`)
    },
    add: async(name, phone) => {
        return await Api.post(`${url}`, { name, phone })
    },
    delete: async (name, phone) => {
        return await Api.delete(`${url}`, { name, phone })
    },
    report: async (name, phone, email, message) => {
        return await Api.post(`${url}/report`, { name, phone, email, message })
    }
}

export default Ban