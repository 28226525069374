import React, { Component } from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import Config from '../config/config'

const authenticateUrl = `${Config.moduleUrl}/auth/smartcert_start.php`

export class SignupStep1 extends Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        onClickMoveTab: PropTypes.func.isRequired,
        data: PropTypes.shape({
            terms: PropTypes.bool.isRequired,
            police: PropTypes.bool.isRequired,
            textTerms: PropTypes.bool.isRequired,
            name: PropTypes.string.isRequired,
            phone: PropTypes.string.isRequired,
            birth: PropTypes.string.isRequired,
            warningMessage: PropTypes.string.isRequired,
        })
    }

    constructor(props) {
        super(props)


        this.state = {
            serviceTerm: '',
            privatePolice: '',
            alltermState: props.data.allterms,
            termState: props.data.terms,
            policeState: props.data.police,
            textTermsState: props.data.textTerms,
            visibleForm: props.data.visibleForm
        }
    }

    componentDidMount = () => {
        // const elmnt = document.getElementsById("main")
        // elmnt = elmnt.scrollTop

        const serviceTermUrl = '/terms/serviceTerm.txt' // provide file location
        const privatePoliceUrl = '/terms/privatePolice.txt'
        fetch(serviceTermUrl)
            .then(r => r.text())
            .then(t => {
                this.setState({
                    serviceTerm: t
                })
            })

        fetch(privatePoliceUrl)
            .then(r => r.text())
            .then(t => {
                this.setState({
                    privatePolice: t
                })
            })
    }

    allChangeAllCheckBox = (e) => {
        this.setState({
            termState: e.target.checked,
            policeState: e.target.checked,
            textTermsState: e.target.checked,
        })
        this.props.onChangeAllTerms(e)
    }

    allChangeAllCheckArea = (value) => {
        let e = { target: { checked: value } }



        this.setState({
            alltermState: value,
            termState: value,
            policeState: value,
            textTermsState: value,
        })
        this.props.onChangeAllTerms(e)
    }

    termChangeCheck = (value) => {
        let e = { target: { name: 'terms', type: 'checkbox', checked: value } }
        this.props.onChange(e)
        this.setState({
            termState: value,
        })
    }

    policeChangeCheck = (value) => {
        let e = { target: { name: 'police', type: 'checkbox', checked: value } }
        this.props.onChange(e)
        this.setState({
            policeState: value,
        })
    }

    textTermChangeCheck = (value) => {
        let e = { target: { name: 'textTerms', type: 'checkbox', checked: value } }
        this.props.onChange(e)
        this.setState({
            textTermsState: value,
        })
    }

    render() {
        const {
            state: {
                serviceTerm,
                privatePolice,
                termState,
                policeState,
                textTermsState,
                alltermState
            },
            props: {
                data: {
                    allterm,
                    term,
                    police,
                    name,
                    phone,
                    birth,
                    warningMessage,
                    visibleForm
                },
                onChange,
                onChangeAllTerms,
                onClickMoveTab,
                checkAuthCerti
            }
        } = this

        return (
            <div>
                <div className="card border-radius-0 mb-3">
                    <div className="card-header">
                        <div className="form-group form-check mb-0" onClick={() => this.allChangeAllCheckArea(!alltermState)}>
                            <input type="checkbox" className="form-check-input" name="allterms" onChange={this.allChangeAllCheckBox} checked={alltermState} />
                            <label className="form-check-label"> 전체동의 (가입연령은 여 만20세 이상, 남 만23세 이상입니다)</label>
                        </div>
                    </div>
                    <div className="card-header">
                        <div className="form-group form-check mb-0" onClick={() => this.termChangeCheck(!termState)}>
                            <input type="checkbox" className="form-check-input" name="terms" onChange={onChange} checked={this.state.termState} />
                            <label className="form-check-label"> 이용약관에 동의합니다.</label>
                        </div>
                    </div>
                    <textarea
                        className="form-control border-radius-0"
                        name="termService"
                        id=""
                        cols="30"
                        rows="6"
                        defaultValue={serviceTerm}
                        readOnly
                    />
                </div>
                <div className="card border-radius-0 mb-3">
                    <div className="card-header">
                        <div className="form-group form-check mb-0" onClick={() => this.policeChangeCheck(!policeState)}>
                            <input type="checkbox" className="form-check-input" name="police" onChange={onChange} checked={this.state.policeState} />
                            <label className="form-check-label"> 개인정보처리방침에 동의합니다.</label>
                        </div>
                    </div>
                    <textarea
                        className="form-control border-radius-0"
                        name="termPolice"
                        id=""
                        cols="30"
                        rows="6"
                        defaultValue={privatePolice}
                        readOnly
                    />
                </div>
                <div className="card border-radius-0 mb-3">
                    <div className="card-header">
                        <div className="form-group form-check mb-0" onClick={() => this.textTermChangeCheck(!textTermsState)}>
                            <input type="checkbox" className="form-check-input" name="textTerms" onChange={onChange} checked={this.state.textTermsState} />
                            <label className="form-check-label"> 문자, 이메일 수신에 대한 동의</label>
                        </div>
                    </div>
                </div>
                <div className="card border-radius-0 mb-5">
                    <div className="card-header">
                        {/* 본인인증 */}
                    </div>
                    <div className="card-body">

                        <div className={visibleForm ? null : 'd-none'}>
                            {/* <p className="text-red small">* 본인인증을 진행하면 휴대전화, 이름, 생년월일, 성별이 자동입력 됩니다.</p> */}
                            <p className="text-red small">반갑습니다. 본인인증이 완료되었습니다.</p>

                            <div className="d-flex floating-input-group">
                                <div className={!phone ? "form-group floating-form-group w-100" : "form-group floating-form-group w-100 active"}>
                                    <input type="text" className="form-control floating-input" name="phone" onChange={onChange} defaultValue={phone} readOnly />
                                    <label className="floating-label">휴대전화번호</label>
                                </div>
                            </div>
                            <div className={!name ? "form-group floating-form-group" : "form-group floating-form-group active"}>
                                <input type="text" className="form-control floating-input" name="name" onChange={onChange} defaultValue={name} readOnly />
                                <label className="floating-label">이름</label>
                            </div>
                            <div className={!birth ? "form-group floating-form-group" : "form-group floating-form-group active"}>
                                <input type="date" className="form-control floating-input" name="birth" onChange={onChange} defaultValue={birth} readOnly />
                                <label className="floating-label">생년월일</label>
                            </div>
                        </div>

                        <div className="mb-3">
                            <a href={authenticateUrl}
                                onClick={checkAuthCerti}
                                className="btn btn-primary btn-block mt-5"
                                type="button"
                            >본인인증</a>
                            <button
                                type="button"
                                className="btn btn-danger btn-block mt-2"
                                onClick={() => onClickMoveTab('기본정보')}
                            > 다음 </button>
                            <div className="alert-label text-center mt-3 text-warning">
                                {!warningMessage ? '' : warningMessage}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SignupStep1
