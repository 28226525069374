import React, { Component } from 'react';
import { Form, Button, Nav, Card, Col, Row, InputGroup, Table } from "react-bootstrap";
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { Link } from 'react-router-dom'
import ProfileForm from '../components/ProfileForm'
import ProfileForm2 from '../components/ProfileForm2'
import ProfileForm3 from '../components/ProfileForm3'
import ProfileForm4 from '../components/ProfileForm4'
import ProfileForm5 from '../components/ProfileForm5'
import { AuthContext } from '../components/AuthenticatedComponent'
import User from '../api/user.api'
import Photo from '../api/photo.api'
import Trophy from '../api/trophy.api'

class InfoModify extends Component{

    static contextType = AuthContext

    constructor(props, context) {
        super(props)
        
        let petLabel = ''
        if(context.trophy){
            if(context.trophy.pet.value === '개') {
                petLabel = '반려견'
            }
            if(context.trophy.pet.value === '고양이') {
                petLabel = '반려묘'
            }
            if(context.trophy.pet.value === '없음') {
                petLabel = '없음'
            }
        }
        
        this.state = {
            name: context.profile.name,
            birth: context.profile.birth,
            gender: context.profile.gender,
            email: context.profile.email,
            phone: context.profile.phone,
            _area: context.profile._area._id,
            religion: context.profile.religion,
            married: context.profile.married,
            smoking: context.profile.smoking,
            drinking: context.profile.drinking,
            tattoo: context.profile.tattoo,
            
            height: context.profile.height,
            body: context.profile.body,
            personality: context.profile.personality,
            education: context.profile.education,
            job: context.profile.job,
            insurance: context.profile.insurance,
            income: context.profile.income,
            car: context.profile.car,
            estate: context.profile.estate,
            values: context.profile.values,
            advantage: context.profile.advantage,
            idealType: context.profile.idealType,
            want: context.profile.want,
            talkTo: context.profile.talkTo,
            _main: [context.profile._photos._main], // 프로필사진 (필수)
            _privateR: context.profile._photos._privateR, // 개인사진 (필수)
            _hobbyLife: context.profile._photos._hobbyLife,
            pet : context.trophy ? {value: petLabel, post: context.trophy.pet.post } : {value: '미인증'},
            donation: context.trophy ? context.trophy.donation : {value: '미인증'},
            estate_trophy: context.trophy ? context.trophy.estate : {value: '미인증'},
            income_trophy: context.trophy ? context.trophy.income : {value: '미인증'},
            award: context.trophy ? context.trophy.award : {value: '미인증'},
            tax: context.trophy ? context.trophy.tax : {value: '미인증'},
            work: context.trophy ? context.trophy.work : {value: '미인증'},
            sales: context.trophy ? context.trophy.sales : {value: '미인증'},
            graduation: context.trophy ? context.trophy.graduation : {value: '미인증'},
            license: context.trophy ? context.trophy.license : {value: '미인증'},
            car_trophy: context.trophy ? context.trophy.car : {value: '미인증'},
            family: context.trophy ? context.trophy.family : {value: '미인증'}
        }
        // this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this)
        // this.uploadFiles = this.uploadFiles.bind(this)
    }

    onChange = async (e) => {

        const targetName = e.target.name 
        const targetValue = e.target.value

        const userRes = await User.update({
            [targetName] : targetValue
        })

        if(userRes.success){
            this.setState({
                [targetName] : targetValue
            })
        } else {
            alert('데이터 등록 실패')
        }

        
    }

    onPrompt = e =>{
        window.prompt('아녕')
    }

    onObjChange = async (e, target, stateProp) => {
        let obj = {
            value: 5,
            visible: false
        }

        if (!target) {
            const targetName = e.target.name 
            const targetValue = e.target.value
            const userRes = await User.update({
                [targetName] : targetValue
            })
    
            if(userRes.success){
                if(targetName === "height" || targetName === "income" || targetName === "estate") {
                    obj.value = targetValue * 1
                } else {
                    obj.value = targetValue
                }
                
                obj.visible = this.state[targetName].visible
                this.setState({
                    [targetName] : obj
                })
            } else {
                alert('데이터 등록 실패')
            }
        } else {
            // visible 설정일 경우
            obj.value = this.state[stateProp].value
            obj.visible = e
            const userRes = await User.update({
                [target] : e
            })
            if(!userRes.success) {
                alert('데이터 등록에 실패했습니다.');
            } else {
                this.setState({
                    [stateProp] : obj
                })
            }
        }
    }

    onBlur = async (e) =>{
        // const userRes = await User.update({
        //     [e.target.name] : e.target.value
        // })
        // if(!userRes.success) {
        //     alert('데이터 등록에 실패했습니다.');
        // }
    }

    deletePhoto = async (index, target) => {
        const photos = this.state[target]
        photos.splice(index, 1)

        const userRes = await User.update({
            [target]: photos
        })
        if (userRes.success) {
            this.setState({ [target]: photos })
        } else {
            alert(`데이터 수정 실패\n${userRes.message}(${userRes.status})`)
        }
    }

    onPhotoChange = async (index, e) => {
        e.persist();

        const files = Array.from(e.target.files)
        const file = files && 0 < files.length ? files.find(file => file) : null
        if (!file)
            return false
        
        if (file.type === 'image/gif') {
            alert('gif 파일은 업로드 할 수 없습니다.')
            return false
        }
        if (-1 < file.type.indexOf('video')) {
            alert('비디오 파일은 업로드 할 수 없습니다.')
            return false
        }

        try {
            const promise = new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.addEventListener('load', ev => {
                    resolve(ev.target.result);
                })
                reader.addEventListener('error', reject)
                reader.readAsDataURL(file)
            })
            const image = await promise
            if (!image) {
                alert("이미지 로딩 실패")
                return false
            }

            const name = e.target.name
            const photoRes = await Photo.upload([file])
            if (photoRes.success) {
                const photo = photoRes.uploads[0]
                const userRes = await User.patchphoto({
                    type: name,
                    index,
                    photo: photo.filename
                })
                if (userRes.success) {
                    this.setState(state => {
                        const photos = Array.from(state[name])
                        if (-1 < Number(index) && Number(index) < photos.length) {
                            photos[index] = photo.filename
                        } else {
                            photos.push(photo.filename)
                        }
                        return ({ [name]: photos, })
                    })
                } else
                    alert(`사진 업데이트 실패\n${userRes.message} (${userRes.status})`)

            } else
                alert(`이미지 업로드 실패\n${photoRes.message} (${photoRes.status})`)

        } catch (e) {
            alert(e)
            return false
        }
    }

    onTrophyChange = async (e, target, value) => {
        // if( value === '없음' || value === '미등록') {
        //     alert('마스킹 처리된  해당 서류를 인증하셔야 합니다.')
        // } else {
            let trophyName 
            if(target === 'estate' || target === 'income' || target === 'car') {
                trophyName = target+'_trophy'
            } else {
                trophyName = target
            }

            this.setState((state) => ({
                [trophyName]: {
                    value: state[trophyName].value,
                    post: e
                }
            }))
    
            const trophyRes = await Trophy.update({
                [target + "Post"] : e
            })
    
            if(!trophyRes.success){
                alert('트로피정보 수정 실패')
            }
        // }
    }

    render(){
        const {
            onChange,
            onBlur,
            onObjChange,
            onPhotoChange,
            onTrophyChange,
            deletePhoto,
            context: {
                profile,
                trophy
            },
            state : {
                name,
                birth,
                gender,
                email,
                phone,
                _area,
                religion,
                married,
                smoking,
                drinking,
                tattoo,

                height,
                body,
                personality, 
                education, 
                job, 
                insurance,
                income,
                car,
                estate,
                _main,
                _privateR,
                _hobbyLife,

                pet,
                donation,
                estate_trophy,
                income_trophy,
                award,
                tax,
                work,
                sales,
                graduation,
                license,
                car_trophy,
                family
            }
        } = this

        return(
            <div className="row"><div className="col-12">
                <form action="">
                    <ProfileForm profile={{
                        name,
                        birth,
                        gender,
                        email,
                        phone,
                        _area,
                        religion,
                        married,
                        smoking,
                        drinking,
                        tattoo,
                    }} onChange={onChange} onBlur={onBlur} />
                    <ProfileForm2 profile={{
                        height,
                        body,
                        personality, 
                        education, 
                        job, 
                        insurance,
                        income,
                        car,
                        estate,
                    }} onChange={onObjChange} onBlur={onBlur}/>
                    <ProfileForm3 profile={profile} onChange={onChange} onBlur={onBlur} />
                    <ProfileForm4 profile={profile} onChange={onPhotoChange}
                        deletePhoto={deletePhoto} 
                        photos={{
                            _main,
                            _privateR,
                            _hobbyLife
                        }}
                    />
                    <ProfileForm5 trophy={{
                        pet,
                        donation,
                        estate_trophy,
                        income_trophy,
                        award,
                        tax,
                        work,
                        sales,
                        graduation,
                        license,
                        car_trophy,
                        family
                    }} onChange={onTrophyChange} />
                </form>
            </div>
            </div>
        );
    }
}
export default InfoModify;