const optionChecker = {
    //부동산
    estate: (param) => {
        switch (param) {
            case 1 :
                return '0~1억'
            case 2 :
                return '1~3억'
            case 3 :
                return '3~6억'
            case 4 : 
                return '6~10억'
            case 5 :
                return '10~20억'
            case 6 :
                return '20억 이상'
            default :
                return false
        }
    },
    height: (param) => {
        switch (param) {
            case 1 :
                return '155미만'
            case 2 :
                return '155~159'
            case 3 :
                return '160~164'
            case 4 :
                return '165~169'
            case 5 :
                return '170~174'
            case 6 : 
                return '175~179'
            case 7 :
                return '180~184'
            case 8 :
                return '185~189'
            case 9 :
                return '190~194'
            case 10 :
                return '195이상'
            default :
                return false
        }
    },
    income: (param) => {
        switch (param) {
            case 1 :
                return '0~2000만'
            case 2 :
                return '2000~4000만'
            case 3 :
                return '4000~6000만'
            case 4 :
                return '6000~8000만'
            case 5 :
                return '8000만~1억'
            case 6 :
                return '1억 이상'
            default :
                return false
        }
    },
    grade: (param) => {
        switch (param) {
            case "standard" :
                return "스탠다드"
            case "gold" :
                return "골드"
            case "platinum" :
                return "플래티넘"
            case "diamond" :
                return "다이아"
            default :
                return false

        }
    },
    car: (param) => {
        if(param) {
            return '보유'
        } else {
            return '없음'
        }
    }
}

export default optionChecker