import React, { Component } from 'react'
import PropTypes from 'prop-types'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'

export class ProfileForm2 extends Component {
    static propTypes = {

    }

    render() {

        const {
            
            props: {
                onChange,
                onBlur,
                profile: {
                    height,
                    body, 
                    personality, 
                    education, 
                    job, 
                    insurance,
                    income,
                    car,
                    estate,
                }
            }
        } = this
        return (
            <div className="card border-radius-0 mb-3">
                <div className="card-header">
                    <h6 className="mb-0">어필정보 설정</h6>
                </div>
                <div className="card-body">
                    <div className="form-group mb-3">
                        <label>키</label>
                        <div className="d-flex">
                                <select className="form-control mr-1" name="height" defaultValue={height.value} onChange={onChange} onBlur ={onBlur}>
                                    <option value={1}>155 미만</option>
                                    <option value={2}>155 ~ 159</option>
                                    <option value={3}>160 ~ 164</option>
                                    <option value={4}>165 ~ 169</option>
                                    <option value={5}>170 ~ 174</option>
                                    <option value={6}>175 ~ 179</option>
                                    <option value={7}>180 ~ 184</option>
                                    <option value={8}>185 ~ 189</option>
                                    <option value={9}>190 ~ 194</option>
                                    <option value={10}>195 이상</option>
                                </select>
                            <div className="toggle-group">
                                <BootstrapSwitchButton checked={height.visible} onChange={(e) => onChange(e, "heightVisible", "height")} size="md" width={100} onlabel="노출" offlabel="미노출" onstyle="primary" offstyle="danger"/>
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-3">
                        <label>체형</label>
                        <div className="d-flex">
                            <select className="form-control mr-1" name="body" defaultValue={body.value} onChange={onChange} onBlur ={onBlur}>
                                <option value="마른">마른</option>
                                <option value="보통">보통</option>
                                <option value="통통">통통</option>
                            </select>
                            <div className="toggle-group">
                                <BootstrapSwitchButton checked={body.visible} onChange={(e) => onChange(e, "bodyVisible", "body")} size="md" width={100} onlabel="노출" offlabel="미노출" onstyle="primary" offstyle="danger"/>
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-3">
                        <label>성격</label>
                        <div className="d-flex">
                            <textarea disabled className="form-control mr-1" name="personality" defaultValue={personality.value} onChange={onChange} onBlur={onBlur} cols="5" rows="1" />
                            <div className="toggle-group">
                                <BootstrapSwitchButton checked={personality.visible} onChange={(e) => onChange(e, "personalityVisible", "personality")} size="md" width={100} onlabel="노출" offlabel="미노출" onstyle="primary" offstyle="danger"/>
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-3">
                        <label>최종학력</label>
                        <div className="d-flex">
                            <select className="form-control mr-1" name="education" defaultValue={education.value} onChange={onChange} onBlur ={onBlur}>
                                <option value="고등학교졸">고등학교졸</option>
                                <option value="2년제졸">전문대학교졸</option>
                                <option value="4년제졸">대학교졸</option>
                                <option value="대학원졸">대학원졸</option>
                                <option value="대학(원)재학">대학(원)재학</option>
                                <option value="해외대학졸">해외대학(원)졸</option>
                            </select>
                            <div className="toggle-group">
                                <BootstrapSwitchButton checked={education.visible} onChange={(e) => onChange(e, "educationVisible", "education")} size="md" width={100} onlabel="노출" offlabel="미노출" onstyle="primary" offstyle="danger"/>
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-3">
                        <label>직업</label>
                        <div className="d-flex">
                            <input type="text" className="form-control mr-1" name="job" defaultValue={job.value} onChange={onChange} onBlur ={onBlur} style={{height:'42px'}} readOnly/>
                            <div className="toggle-group">
                                <BootstrapSwitchButton checked={job.visible} onChange={(e) => onChange(e, "jobVisible", "job")}  size="md" width={100} onlabel="노출" offlabel="미노출" onstyle="primary" offstyle="danger"/>
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-3">
                        <label>4대보험형태</label>
                        <div className="d-flex">
                            <select className="form-control mr-1" name="insurance" defaultValue={insurance.value} onChange={onChange} onBlur ={onBlur}>
                                <option value="직장가입자">직장가입자</option>
                                <option value="지역가입자">지역가입자</option>
                                <option value="미가입자">미가입자</option>
                            </select>
                            <div className="toggle-group">
                                <BootstrapSwitchButton checked={insurance.visible} onChange={(e) => onChange(e, "insuranceVisible", "insurance")} size="md" width={100} onlabel="노출" offlabel="미노출" onstyle="primary" offstyle="danger"/>
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-3">
                        <label>직년소득</label>
                        <div className="d-flex">
                            <select className="form-control mr-1" name="income" defaultValue={income.value} onChange={onChange} onBlur ={onBlur}>
                                <option value="1">0 ~ 2000만원</option>
                                <option value="2">2000 ~ 4000만원</option>
                                <option value="3">4000 ~ 6000만원</option>
                                <option value="4">6000 ~ 8000만원</option>
                                <option value="5">8000만원 ~ 1억원</option>
                                <option value="6">1억원 이상</option>
                            </select>
                            <div className="toggle-group">
                                <BootstrapSwitchButton checked={income.visible} onChange={(e) => onChange(e, "incomeVisible", "income")} size="md" width={100} onlabel="노출" offlabel="미노출" onstyle="primary" offstyle="danger"/>
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-3">
                        <label>차량소유여부</label>
                        <div className="d-flex">
                            <select className="form-control mr-1" name="car" defaultValue={car.value} onChange={onChange} onBlur ={onBlur}>
                                <option value={true}>있음</option>
                                <option value={false}>없음</option>
                            </select>
                            <div className="toggle-group">
                                <BootstrapSwitchButton checked={car.visible} onChange={(e) => onChange(e, "carVisible", "car")} size="md" width={100} onlabel="노출" offlabel="미노출" onstyle="primary" offstyle="danger"/>
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-3">
                        <label>종합부동산(전/월세 포함)</label>
                        <div className="d-flex">
                            <select className="form-control mr-1" name="estate" defaultValue={estate.value} onChange={onChange} onBlur ={onBlur}>
                                <option value="1">0 ~ 1 억원</option>
                                <option value="2">1 ~ 3 억원</option>
                                <option value="3">3 ~ 6 억원</option>
                                <option value="4">6 ~ 10 억원</option>
                                <option value="5">10 ~ 20 억원</option>
                                <option value="6">20 억원 이상</option>
                            </select>
                            <div className="toggle-group">
                                <BootstrapSwitchButton checked={estate.visible} onChange={(e) => onChange(e, "estateVisible", "estate")} size="md" width={100} onlabel="노출" offlabel="미노출" onstyle="primary" offstyle="danger"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProfileForm2
