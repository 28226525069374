import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
// import { decodeToken } from 'react-jwt'
import jwt_decode from "jwt-decode";

import User from '../api/user.api'

const DecodedToken = (token) => {
    try {
        const myDecodedToken = jwt_decode(token);
        return myDecodedToken;
    } catch (error) {
        return false
    }

}

export const AuthContext = React.createContext(
    {
        _id: '',
        name: '',
        permission: ''
    }
)

export class AuthenticatedComponent extends Component {

    constructor(props) {
        super(props)

        this.state = {
            token: '',
            accessor: undefined
        }
    }

    componentDidMount = async () => {
        let decodeT
        const autoCheck = localStorage.getItem('autoLogin')

        if (autoCheck === 'false') {
            decodeT = DecodedToken(sessionStorage.getItem('token'))
        } else {
            decodeT = DecodedToken(localStorage.getItem('token'))
        }
        if (!decodeT) {
            this.props.history.push('/login')
        } else {
            let userObj = {}
            let userRes = await User.show(decodeT._id)
            // jason_wang: user 가 없는데 어떻게 user 를 api 서버에 전달하는가?
            // let userRes = await User.show('my')
            if (!userRes.success) {
                alert('사용자 정보를 호출할 수 없습니다. 관리자에게 문의해 주세요.')
                localStorage.removeItem('autoLogin')
                localStorage.removeItem('token')
                sessionStorage.removeItem('autoLogin')
                sessionStorage.removeItem('token')
                this.props.history.push('/login')
                // return false
            } else {
                userObj = {
                    history: userRes.history ? userRes.history : {},
                    profile: userRes.user,
                    interest: userRes.interest,
                    trophy: userRes.trophy,
                    permission: decodeT.permission
                }
            }

            this.setState({
                accessor: userObj
            })
        }
    }

    render() {
        if (!this.state.accessor) {
            return (
                <div>
                    {/*<h1>Loading...</h1>*/}
                </div>
            )
        }
        return (
            <AuthContext.Provider value={
                this.state.accessor
            }>
                {this.props.children}
            </AuthContext.Provider>
        )
    }
}

export default withRouter(AuthenticatedComponent)
