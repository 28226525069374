import React, { Component } from 'react';
// import "bootstrap/dist/css/bootstrap.min.css";
import Product from '../api/product.api'
import PaymentTerm from '../components/PaymentTerms'
import PaymentNotice from '../components/PaymentNotice'
import { AuthContext } from '../components/AuthenticatedComponent'
import PaymentApi from '../api/payment.api'
import Config from '../config/config'
import moment from 'moment'
import Goods from '../helper/goods.helper'

const clientUrl = Config.clientUrl
//const paymentUrl = "https://kspay.ksnet.to/store/KSPayMobileV1.4/KSPayPWeb.jsp"
//const paymentUrl = `${Config.moduleUrl}/pay/kspay_wh_result.php`
const paymentUrl = `${Config.moduleUrl}/pay/result.php`

class Pass extends Component {

    static contextType = AuthContext

    constructor(props) {
        super(props)

        this.state = {
            showItem: null,
            connectItem: null,
            standardTicket: null,
            goldTicket: null,
            platinumTicket: null,
            diamondTicket: null,

            allProduct: [],
            chargeProduct: [],
            ticketProduct: [],
            premiumProduct: [],
            defaultValue: '',
            selectedProduct: [],

            sndPaymethod: '1000000000',
            sndStoreid: '2999199999',
            sndCurrencytype: 'WON',
            sndOrdernumber: '', // submit 설정필요
            sndInstallmenttype: '0:2:3:4:5:6:7:8:9:10:11:12',
            sndInteresttype: 'NONE',
            sndShowcard: 'C',
            sndGoodname: '선택상품', // submit 설정필요
            sndAmount: '', // submit 설정필요
            sndOrdername: '', // submit 설정필요
            sndEmail: '', // submit 설정필요
            sndMobile: '', // submit 설정필요
            // 결제모듈 기본
            sndCharSet: 'utf-8',
            sndReply: '',
            sndEscrow: '',
            sndVirExpDt: '',
            sndVirExpTm: '',
            sndStoreName: '연애해',
            sndStoreNameEng: 'kspay',
            sndStoreDomain: clientUrl,
            sndGoodType: '1',
            sndUseBonusPoint: '',
            sndRtApp: '',
            sndStoreCeoName: '',
            sndStorePhoneNo: '',
            sndStoreAddress: ''
        }
    }

    onChange = (e) => {
        if (e.target.name === 'sndPaymethod') {
            this.setState({
                sndPaymethod: e.target.value
            })
        } else {
            let selectedItem = this.state.allProduct.find(item => item._id === e.target.value)
            const goodCount = this.state.selectedProduct.length
            let countLabel = ''
            if (goodCount > 0) {
                countLabel = ' 외 ' + goodCount + '개'

            }

            this.setState(state => {
                return ({
                    selectedProduct: [...state.selectedProduct, selectedItem],
                    sndAmount: String(Number(state.sndAmount) + this.getTotalPrice(selectedItem)),
                    sndGoodName: selectedItem.name + countLabel
                })
            })
        }



    }

    _pay = async (e, _frm) => {
        if (this.state.selectedProduct.length > 0) {
            if (window.confirm(this.state.sndAmount + '원을 결제하시겠습니까?')) {
                let _productArr = []
                let orderNum = String(moment().valueOf())
                const alphabet = "abcdefghijklmnopqrstuvwxyz";
                for (var i = 0; i < 5; i++) {
                    let rnum = Math.floor(Math.random() * alphabet.length);
                    // alert(alphabet.substring(rnum,rnum+1))
                    orderNum += alphabet.substring(rnum, rnum + 1);
                }

                this.setState({
                    sndOrdernumber: orderNum
                })

                _productArr = this.state.selectedProduct.map(item => {
                    return item._id
                })

                const paymentRes = await PaymentApi.create({
                    _product: _productArr,
                    orderNumber: orderNum,
                    method: this.state.sndPaymethod === '1000000000' ? '신용카드' : '가상계좌'
                })

                if (paymentRes.success) {
                    _frm.action = 'https://kspay.ksnet.to/store/KSPayMobileV1.4/KSPayPWeb.jsp';    //리얼
                    // _frm.action ='https://210.181.28.134/store/KSPayMobileV1.4/KSPayPWeb.jsp';
                    _frm.submit()
                } else {
                    alert(paymentRes.message)
                }
            }
        } else {
            alert('선택된 상품이 없습니다.')
        }
    }

    deleteItem = (e) => {
        const targetItem = Number(e.target.dataset.product)
        const targetPrice = e.target.dataset.price

        const removedArr = this.state.selectedProduct.filter((item, index) => index !== targetItem)
        const amount = String(Number(this.state.sndAmount) - targetPrice)
        const goodCount = this.state.selectedProduct.length
        let countLabel = ''
        const goodName = removedArr.length === 0 ? '' : removedArr[0].name
        if (goodCount > 2) {
            countLabel = ' 외 ' + (goodCount - 2) + '개'
        }

        this.setState({
            selectedProduct: removedArr,
            sndAmount: amount,
            sndGoodName: goodName + countLabel
        })

    }

    componentDidMount = async () => {
        const {
            context: {
                history
            }
        } = this

        this.setState({
            showItem: Goods.create(history.showItem_free, history.showItem_paid, history.showItem_price),
            connectItem: Goods.create(history.connectItem_free, history.connectItem_paid, history.connectItem_price),
            standardTicket: Goods.create(history.standardTicket_free, history.standardTicket_paid, history.standardTicket_price),
            goldTicket: Goods.create(history.goldTicket_free, history.goldTicket_paid, history.goldTicket_price),
            platinumTicket: Goods.create(history.platinumTicket_free, history.platinumTicket_paid, history.platinumTicket_price),
            diamondTicket: Goods.create(history.diamondTicket_free, history.diamondTicket_paid, history.diamondTicket_price),
        })

        const productRes = await Product.index({ gender: this.context.profile.gender })

        if (!productRes.success) {
            alert('상품 데이터를 가져올 수 없습니다.')
        } else {
            // const chargeProduct = productRes.products.every( item => item.type === 'charge' )
            let chargeProduct = []
            let ticketProduct = []
            let premiumProduct = []

            productRes.products.forEach(item => {
                if (item.type === 'charge') {
                    chargeProduct.push(item)
                } else if (item.type === 'ticket') {
                    ticketProduct.push(item)
                } else if (item.type === 'premium') {
                    premiumProduct.push(item)
                }
            })

            this.setState({
                allProduct: productRes.products,
                chargeProduct: chargeProduct,
                ticketProduct: ticketProduct,
                premiumProduct: premiumProduct,
                sndOrdername: this.context.profile.name,
                sndEmail: this.context.profile.email,
                sndMobile: this.context.profile.phone
            })
        }
    }

    getTotalPrice = product => {
        return Number(product.showItem_price) +
            Number(product.connectItem_price) +
            Number(product.standardTicket_price) +
            Number(product.goldTicket_price) +
            Number(product.platinumTicket_price) +
            Number(product.diamondTicket_price)
    }

    render() {

        const {
            onChange,
            onSubmit,
            deleteItem,
            state: {
                showItem,
                connectItem,
                standardTicket,
                goldTicket,
                platinumTicket,
                diamondTicket,

                sndOrdernumber,
                sndPaymethod,
                chargeProduct,
                ticketProduct,
                premiumProduct,
                defaultValue,
                selectedProduct,
                sndAmount,
                sndOrdername,
                sndEmail,
                sndMobile,
                sndGoodName
            }
        } = this

        if (!chargeProduct && !ticketProduct && !premiumProduct) {
            return (
                <div>상품 불러오는 중...</div>
            )
        }

        let paymentList

        if (selectedProduct.length === 0) {
            paymentList = ''
        } else {
            paymentList = <div>
                <label className="mx-2">선택한 결제 상품</label>
                <table className="table">

                    <tbody>
                        {
                            selectedProduct.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="py-3">
                                            {item.name}
                                        </td>
                                        <td className="py-3">
                                            {this.getTotalPrice(item)}
                                        </td>
                                        <td>
                                            <button className="btn btn-sm btn-secondary" onClick={deleteItem} data-product={index} data-price={this.getTotalPrice(item)} >삭제</button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                <div className="d-flex justify-content-between px-2 mb-5">
                    <div>
                        <h4 className="text-secondary">결제금액</h4>
                    </div>
                    <div>
                        <h4 className="text-danger">{sndAmount}</h4>
                    </div>
                </div>
            </div>
        }

        return (
            <div>
                <div className="row">
                    <div className="col-3">
                        <div className="px-2 separator-right">
                            <p className="small mb-0 font-weight-bold text-secondary">이어줘충전</p>
                            <div className="d-flex w-100">
                                <div className="w-100">
                                    <h6 className="text-secondary mb-0"><span className="text-danger" style={{ fontSize: '27px' }}>{Goods.count(connectItem)}</span> 회</h6>
                                    <div className="small">이어줘</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-9">
                        <div className="px-2">
                            <p className="small mb-0 font-weight-bold text-secondary">내 성사 이용권</p>
                            <div className="d-flex w-100">
                                <div className="w-100">
                                    <h6 className="text-secondary mb-0"><span className="text-danger" style={{ fontSize: '27px' }}>{Goods.count(standardTicket)}</span> 회</h6>
                                    <div className="small ">스탠다드</div>
                                </div>
                                <div className="w-100">
                                    <h6 className="text-secondary mb-0"><span className="text-danger" style={{ fontSize: '27px' }}>{Goods.count(goldTicket)}</span> 회</h6>
                                    <div className="small ">골드</div>
                                </div>
                                <div className="w-100">
                                    <h6 className="text-secondary mb-0"><span className="text-danger" style={{ fontSize: '27px' }}>{Goods.count(platinumTicket)}</span> 회</h6>
                                    <div className="small ">플래티넘</div>
                                </div>
                                <div className="w-100">
                                    <h6 className="text-secondary mb-0"><span className="text-danger" style={{ fontSize: '27px' }}>{Goods.count(diamondTicket)}</span> 회</h6>
                                    <div className="small ">다이아</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div style={{ height: "20px", width: "100%", clear: "both" }}></div>
                    <div className="col-12">
                        <div className="card border-radius-0 mb-5">
                            <div className="card-body px-2">
                                <a href="/grade" className="separator-bottom">회원등급기준 보기</a>
                                <div style={{ height: "20px", width: "100%", clear: "both" }}></div>
                                <div className="form-group">
                                    <label>충전하기</label>
                                    <select className="form-control" name="charge" onChange={onChange} value={defaultValue}>
                                        <option defaultValue="">---- 선택 ----</option>
                                        {
                                            chargeProduct.map((item, index) => {
                                                return (
                                                    <option value={item._id} key={index}>{item.name} ({this.getTotalPrice(item)}원) </option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>성사이용권</label>
                                    <select className="form-control" name="ticket" onChange={onChange} value={defaultValue}>
                                        <option>---- 선택 ----</option>
                                        {
                                            ticketProduct.map((item, index) => {
                                                return (
                                                    <option value={item._id} key={index}>{item.name} ({this.getTotalPrice(item)}원) </option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>프리미엄 이용권</label>
                                    <select className="form-control" name="premium" onChange={onChange} value={defaultValue}>
                                        <option>---- 선택 ----</option>
                                        {
                                            premiumProduct.map((item, index) => {
                                                return (
                                                    <option value={item._id} key={index} >{item.name} ({this.getTotalPrice(item)}원) </option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div>
                                {paymentList}
                                <form name="authFrmFrame" action={paymentUrl} method='post' onSubmit={onSubmit}>
                                    <div className="form-group px-2 mt-4">
                                        <label>결제수단</label>
                                        <select name="sndPaymethod" className="form-control" defaultValue={sndPaymethod} onChange={onChange}>
                                            <option value='1000000000'>신용카드</option>
                                            {/* <option value='0100000000'>가상계좌</option> */}
                                            {/* <option value='0010000000'>계좌이체</option> */}
                                        </select>
                                    </div>

                                    <div className="d-none">
                                        {/* <div className="form-group">
                                    <label>결제수단</label>
                                    <select name="sndPaymethod">
                                        <option value='1000000000'>신용카드</option>
                                        <option value='0100000000'>가상계좌</option>
                                        <option value='0010000000'>계좌이체</option>
                                    </select>
                                </div> */}
                                        <div className="form-group">
                                            <label>상점아이디</label>
                                            <input type="text" name="sndStoreid" size="10" maxLength="10" defaultValue="2014900002" />
                                        </div>
                                        <div className="form-group">
                                            <label>화폐단위</label>
                                            <input type="text" name="sndCurrencytype" size="30" maxLength="3" defaultValue="WON" />
                                        </div>
                                        <div className="form-group">
                                            <label>주문번호</label>
                                            <input type="text" name="sndOrdernumber" size="30" maxLength="30" defaultValue={sndOrdernumber} />
                                        </div>
                                        <div className="form-group">
                                            <label>할부개월수</label>
                                            <input type="text" name="sndInstallmenttype" size="30" maxLength="30" defaultValue="0:2:3:4:5:6:7:8:9:10:11:12" />
                                        </div>
                                        <div className="form-group">
                                            <label>무이자구분</label>
                                            <input type="text" name="sndInteresttype" size="30" maxLength="30" defaultValue="NONE" />
                                        </div>
                                        <div className="form-group">
                                            <label>신용카드표시구분</label>
                                            <input type="text" name="sndShowcard" size="30" maxLength="30" defaultValue="C" />
                                        </div>
                                        <div className="form-group">
                                            <label>상품명</label>
                                            <input type="text" name="sndGoodname" size="30" maxLength="30" defaultValue={sndGoodName} />
                                        </div>
                                        <div className="form-group">
                                            <label>가격</label>
                                            <input type="text" name="sndAmount" size="30" maxLength="9" defaultValue={sndAmount} />
                                        </div>
                                        <div className="form-group">
                                            <label>성명</label>
                                            <input type="text" name="sndOrdername" size="30" maxLength="20" defaultValue={sndOrdername} />
                                        </div>
                                        <div className="form-group">
                                            <label>전자우편</label>
                                            <input type="text" name="sndEmail" size="30" maxLength="50" defaultValue={sndEmail} />
                                        </div>
                                        <div className="form-group">
                                            <label>이동전화</label>
                                            <input type="text" name="sndMobile" size="30" maxLength="12" defaultValue={sndMobile} />
                                        </div>
                                        <input type="hidden" name="sndCharSet" defaultValue="utf-8" />
                                        {/* <input type="hidden" name="sndReply"           		defaultValue="/module/payment/kspay_wh_result.php" /> */}
                                        <input type="hidden" name="sndReply" defaultValue={paymentUrl} />
                                        <input type="hidden" name="sndEscrow" defaultValue="0" />
                                        <input type="hidden" name="sndVirExpDt" defaultValue="" />
                                        <input type="hidden" name="sndVirExpTm" defaultValue="" />
                                        <input type="hidden" name="sndStoreName" defaultValue="케이에스페이(주)" />
                                        <input type="hidden" name="sndStoreNameEng" defaultValue="kspay" />
                                        <input type="hidden" name="sndStoreDomain" defaultValue={clientUrl} />
                                        <input type="hidden" name="sndGoodType" defaultValue="1" />
                                        <input type="hidden" name="sndUseBonusPoint" defaultValue="" />
                                        <input type="hidden" name="sndRtApp" defaultValue="" />
                                        <input type="hidden" name="sndStoreCeoName" defaultValue="" />
                                        <input type="hidden" name="sndStorePhoneNo" defaultValue="" />
                                        <input type="hidden" name="sndStoreAddress" defaultValue="" />
                                    </div>

                                    <div className="px-2 mb-5">
                                        {/* <button type="submit" className="btn btn-block btn-primary btn-secondary" onSubmit={onSubmit}>결제하기</button> */}
                                        <button type="button" onClick={(e) => this._pay(e, document.authFrmFrame)} className="btn btn-block btn-primary"><span className="text-dark text-alpha">{sndGoodName}</span>  결제하기</button>

                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="mx-2 mb-3">
                            <PaymentNotice />
                        </div>
                        <div className="mx-2">
                            <PaymentTerm />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Pass;