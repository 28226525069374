import React, { Component } from 'react'
import SignupTabs from '../components/SignupTabs'
import { withRouter } from 'react-router-dom';
import Admin from '../api/admin.api'
import Area from '../api/area.api'
import Photo from '../api/photo.api'
import User from '../api/user.api'
import SignupStep1 from '../components/SignupStep1'
import SignupStep2 from '../components/SignupStep2';
import SignupStep3 from '../components/SignupStep3';
import SignupStep4 from '../components/SignupStep4';
import moment from 'moment'
import queryString from 'query-string'
import Validation from '../helper/validation.helper'
import Auth from '../api/auth.api';
import { ageLimit } from '../helper/age.helper'
import Logo from '../img/logo.png'
import Config from "../config/config"

// import {readAsDataURL, readAsText, readAsArrayBuffer} from 'promise-file-reader';


export class Signup extends Component {

    constructor(props) {
        super(props)

        const _authObj = queryString.parse(props.location.search)

        this.state = {
            isLoading: false,
            activeTab: '본인인증',
            allterms: _authObj._auth ? true : false,
            terms: _authObj._auth ? true : false,
            police: _authObj._auth ? true : false,
            textTerms: _authObj._auth ? true : false,
            name: '',
            phone: '',
            birth: '',
            grade: 'standard',
            status: 'wait',
            email: '',
            password: '',
            passwordConfirm: '',
            gender: '',
            tattoo: '',
            _area: '',
            religion: '',
            married: '',
            smoking: '',
            drinking: '',
            height: 0,
            heightVisible: true,
            body: '',
            bodyVisible: true,
            personality: [],
            personalityVisible: true,
            education: '',
            educationVisible: true,
            job: '',
            jobVisible: true,
            insurance: '',
            insuranceVisible: true,
            income: 0,
            incomeVisible: true,
            car: "none",
            carVisible: true,
            estate: 0,
            estateVisible: true,
            values: '',
            advantage: [],
            idealType: [],
            want: [],
            talkTo: '',
            _main: [], // 프로필사진 (필수)
            _main_preview: [],
            _privateR: [], // 개인사진 (필수)
            _privateR_preview: [],
            _hobbyLife: [], // 취미사진 (필수)
            _hobbyLife_preview: [],
            warningMessage: '',
            areas: [],
            _auth: _authObj._auth,
            passwordMessege: '',
            teamNames: [],
            initTeam: '',
        }

        this.onChange = this.onChange.bind(this);
        this.onClickMoveTab = this.onClickMoveTab.bind(this);
    }

    eamilCheck = async (e) => {
        return false
    }

    checkAuthCerti = (e) => {
        // alert('asdsdf');
        if (!this.state.terms || !this.state.police || !this.state.textTerms) {
            e.preventDefault()
            alert('이용약관, 개인정보처리방침, 문자, 이메일 수신을 동의해주세요.')
            return false
        }
    }

    onChangeFile = (index, e) => {
        const files = Array.from(e.target.files)
        const file = files && 0 < files.length ? files[0] : null
        if (!file)
            return false
        
        if (file.type === 'image/gif') {
            alert('gif 파일은 업로드 할 수 없습니다.')
            return false
        }
        if (-1 < file.type.indexOf('video')) {
            alert('비디오 파일은 업로드 할 수 없습니다.')
            return false
        }

        const name = e.target.name
        new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.addEventListener('load', ev => {
                resolve(ev.target.result);
            })
            reader.addEventListener('error', reject)
            reader.readAsDataURL(file)
        }).then(image => {
            this.setState(state => {
                const photos = Array.from(state[name])
                const previews = Array.from(state[name + '_preview'])

                if (-1 < Number(index) && Number(index) < photos.length) {
                    photos[index] = file
                    previews[index] = image
                } else {
                    photos.push(file)
                    previews.push(image)
                }
                return ({
                    [name]: photos,
                    [name + '_preview']: previews
                })
            })
        }, error => {
            console.error(error)
        })
    }

    onDeletePhoto = (index, target) => {
        const photos = this.state[target]
        const previews = this.state[target + '_preview']

        photos.splice(index, 1)
        previews.splice(index, 1)

        this.setState({
            [target]: photos,
            [target + '_preview']: previews
        })
    }

    onVisibleChange = (e, target) => {
        this.setState({
            [target]: e
        })
    }

    onChangeAllTerms = (e) => {
        this.setState({
            allterms: e.target.checked,
            terms: e.target.checked,
            police: e.target.checked,
            textTerms: e.target.checked,
        })
    }

    onChangePersonality = (e) => {
        const value = e.target.dataset.value
        const personalityLength = this.state.personality.length

        if (e.target.checked && personalityLength < 4) {
            this.setState(state => ({
                personality: [...state.personality, value]
            }))
        } else if (!e.target.checked) {
            this.setState(state => ({
                personality: state.personality.filter(item => item !== value)
            }))
        } else {
            alert('성격은 4개 이하로 선택 가능합니다.')
        }
    }

    onChnageAdventage = (e) => {
        const value = e.target.dataset.value
        const advLength = this.state.advantage.length

        if (e.target.checked && advLength < 7) {
            this.setState(state => ({
                advantage: [...state.advantage, value]
            }))
        } else if (!e.target.checked) {
            this.setState(state => ({
                advantage: state.advantage.filter(item => item !== value)
            }))
        } else {
            alert('매력포인트는 7개 이하로 선택 가능합니다.')
        }
    }

    onChnageIdealType = (e) => {
        const value = e.target.dataset.value
        const idlLength = this.state.idealType.length

        if (e.target.checked && idlLength < 7) {
            this.setState(state => ({
                idealType: [...state.idealType, value]
            }))
        } else if (!e.target.checked) {
            this.setState(state => ({
                idealType: state.idealType.filter(item => item !== value)
            }))
        } else {
            alert('이상형은 7개 이하로 선택 가능합니다.')
        }
    }

    onChnageWant = (e) => {
        const value = e.target.dataset.value
        const wantLength = this.state.want.length
        if (e.target.checked && wantLength < 7) {
            this.setState(state => ({
                want: [...state.want, value]
            }))
        } else if (!e.target.checked) {
            this.setState(state => ({
                want: state.want.filter(item => item !== value)
            }))
        } else {
            alert('데이트 스타일은 7개 이하로 선택 가능합니다.')
        }
    }

    onChange = (e) => {
        let value = '';
        if (e.target.type === 'checkbox') {
            value = e.target.checked;

            this.setState({ [e.target.name]: value });

        } else if (e.target.type === 'number') {
            value = Number(e.target.value);
            this.setState({ [e.target.name]: value });
        } else if (e.target.name === 'height' || e.target.name === 'income' || e.target.name == 'estate') {
            value = parseInt(e.target.value, 10);
        } else if (e.target.name === 'car') {
            value = e.target.value
        } else {
            value = e.target.value;
        }

        let passwordMessege = this.state.passwordMessege
        if (e.target.name === 'passwordConfirm') {
            let password = this.state.password
            if (password === value) {
                passwordMessege = ''
            } else {
                passwordMessege = '비밀번호, 비밀번호 확인이 일치하지 않습니다.'
            }
        }

        if (e.target.name === 'password') {
            let password = this.state.passwordConfirm
            if (password === value) {
                passwordMessege = ''
            } else {
                passwordMessege = '비밀번호, 비밀번호 확인이 일치하지 않습니다.'
            }
        }

        this.setState({
            [e.target.name]: value,
            passwordMessege: passwordMessege,
            warningMessage: ''
        });
    }

    onSubmit = async (e) => {
        e.preventDefault();

        // this.setState({
        //     isLoading: true
        // })

        let except = false
        let exceptPhoto = false
        try
        {
            let {
                _main,
                _privateR,
                _hobbyLife,
                _area,
                name,
                grade,
                status,
                password,
                passwordConfirm,
                email,
                gender,
                tattoo,
                phone,
                birth,
                religion,
                married,
                smoking,
                drinking,
                height,
                heightVisible,
                body,
                bodyVisible,
                personality,
                personalityVisible,
                education,
                educationVisible,
                job,
                jobVisible,
                insurance,
                insuranceVisible,
                income,
                incomeVisible,
                car,
                carVisible,
                estate,
                estateVisible,
                values,
                advantage,
                want,
                idealType,
                talkTo,
                terms,
                police,
                _auth,
                initTeam
            } = this.state
            // 사진파일 체크
            if (_main.length === 0 || _privateR.length === 0 || _hobbyLife.length === 0) {
                alert('모든 사진은 1개 이상 입력해주세요.')
                this.setState({ warningMessage: '모든 사진은 1개 이상 입력해주세요.' })
                return false
            } else {
                this.setState({ isLoading: true })

                let _mainUpload = null
                let _privateRUpload = null
                let _hobbyLifeUpload = null

                try {
                    _mainUpload = await Photo.upload(_main)
                    if (!_mainUpload.success) {
                        alert(`프로필사진 업로드에 실패했습니다. 다시 시도해주세요.\n${_mainUpload.message}(${_mainUpload.status})`)
                        this.setState({
                            isLoading: false,
                            warningMessage: '프로필사진의 사이즈나 형식 등을 변경해 보시기 바랍니다'
                        })
                        return false
                    }
                } catch (e) {
                    alert(`프로필사진 업로드 과정에서 아래와 같은 에러가 발생하였습니다.\n${e.toString()}`)
                    this.setState({ warningMessage: '프로필사진의 사이즈나 형식 등을 변경해 보시기 바랍니다' })
                    except = true
                    exceptPhoto = true
                    return false
                }

                try {
                    _privateRUpload = await Photo.upload(_privateR)
                    if (!_privateRUpload.success) {
                        alert(`개인사진 업로드에 실패했습니다. 다시 시도해주세요.\n${_privateRUpload.message}(${_privateRUpload.status})`)
                        this.setState({
                            isLoading: false,
                            warningMessage: '개인사진의 사이즈나 형식 등을 변경해 보시기 바랍니다'
                        })
                        return false
                    }
                } catch (e) {
                    alert(`개인사진 업로드 과정에서 아래와 같은 에러가 발생하였습니다.\n${e.toString()}`)
                    this.setState({ warningMessage: '개인사진의 사이즈나 형식 등을 변경해 보시기 바랍니다' })
                    except = true
                    exceptPhoto = true
                    return false
                }

                try {
                    _hobbyLifeUpload = await Photo.upload(_hobbyLife)
                    if (!_hobbyLifeUpload.success) {
                        alert(`취미사진 업로드에 실패했습니다. 다시 시도해주세요.\n${_hobbyLifeUpload.message}(${_hobbyLifeUpload.status})`)
                        this.setState({
                            isLoading: false,
                            warningMessage: '취미사진의 사이즈나 형식 등을 변경해 보시기 바랍니다'
                        })
                        return false
                    }
                } catch (e) {
                    alert(`취미사진 업로드 과정에서 아래와 같은 에러가 발생하였습니다.\n${e.toString()}`)
                    this.setState({ warningMessage: '취미사진의 사이즈나 형식 등을 변경해 보시기 바랍니다' })
                    except = true
                    exceptPhoto = true
                    return false
                }

                try {
                    const validMain = _mainUpload && _mainUpload.success
                    const validPrivateR = _privateRUpload && _privateRUpload.success
                    const validHobbyLife = _hobbyLifeUpload && _hobbyLifeUpload.success

                    if (!validMain || !validPrivateR || !validHobbyLife) {
                        const logicError = validMain ? 'Invalid _mainUpload' :
                            validPrivateR ? 'Invalid _privateRUpload' :
                                validHobbyLife ? 'Invalid _privateRUpload' : 'Unknown error'
                        alert(`사진처리 과정에서 로직에러가 발생하였습니다.\n${logicError}`)
                        except = true
                        exceptPhoto = true
                        return false
                    }
                } catch (e) {
                    alert(`사진처리 과정에서 아래와 같은 에러가 발생하였습니다.\n${e.toString()}`)
                    except = true
                    exceptPhoto = true
                    return false
                }

                let userParam = {}
                try {
                    userParam._main = _mainUpload.uploads[0].filename
                    userParam._privateR = _privateRUpload.uploads.map(item => item.filename)
                    userParam._hobbyLife = _hobbyLifeUpload.uploads.map(item => item.filename)
                    userParam._area = _area
                    userParam.name = name
                    userParam.grade = grade
                    userParam.status = status
                    userParam.password = password
                    userParam.passwordConfirm = passwordConfirm
                    userParam.email = email
                    userParam.gender = gender
                    userParam.tattoo = tattoo
                    userParam.phone = phone
                    userParam.birth = birth
                    userParam.religion = religion
                    userParam.married = married
                    userParam.smoking = smoking
                    userParam.drinking = drinking
                    userParam.height = height
                    userParam.heightVisible = heightVisible
                    userParam.body = body
                    userParam.bodyVisible = bodyVisible
                    userParam.personality = personality
                    userParam.personalityVisible = personalityVisible
                    userParam.education = education
                    userParam.educationVisible = educationVisible
                    userParam.job = job
                    userParam.jobVisible = jobVisible
                    userParam.insurance = insurance
                    userParam.insuranceVisible = insuranceVisible
                    userParam.income = income
                    userParam.incomeVisible = incomeVisible
                    userParam.car = car === "true" ? true : false
                    userParam.carVisible = carVisible
                    userParam.estate = estate
                    userParam.estateVisible = estateVisible
                    userParam.values = values
                    userParam.advantage = advantage
                    userParam.want = want
                    userParam.idealType = idealType
                    userParam.talkTo = talkTo
                    userParam._auth = _auth
                    userParam.initTeam = initTeam
                } catch (e) {
                    alert(`정보처리 과정에서 아래와 같은 에러가 발생하였습니다.\n${e.toString()}`)
                    except = true
                    return false
                }

                try {
                    const userRes = await User.create(userParam)
                    if (userRes.success) {
                        let successMessage = userRes.status == 201 ?
                            '연애해 가입이 접수되셨습니다. 가입심사는 1~2일 소요됩니다' :
                            '연애해 가입이 접수되셨습니다. 가입심사는 1~2일 소요됩니다.' // NOTE: jason_wang: 맨 끝에 '.' 하나 붙여서 재가입인지 구분
                        alert(successMessage);
                        this.props.history.push('/login')
                    } else {
                        alert(userRes.message)
                        except = true
                        return false
                    }

                    this.setState({ isLoading: false })
                } catch (e) {
                    alert(`서버처리 과정에서 아래와 같은 에러가 발생하였습니다.\n${e.toString()}`)
                    except = true
                    return false
                }
            }
        } finally {
            if (except) {
                this.setState({ isLoading: false })
                if (exceptPhoto)
                    alert("이용에 불편을 드려 죄송합니다.\n지속적으로 에러 발생시 고객센터에 문의 부탁드립니다.\n(임시방편 : 사진의 사이즈나 형식 등을 변경해 보시기 바랍니다)")
                else
                    alert("이용에 불편을 드려 죄송합니다.\n지속적으로 에러 발생시 고객센터에 문의 부탁드립니다.")
            }
        }
    }

    onClickMoveTab = (label) => {

        document.getElementById('main').scrollTo(0, 0);
        const { activeTab } = this.state
        let warningMessage = ''

        if (activeTab === '본인인증') {
            const { terms, police, phone, name, birth, gender, textTerms } = this.state

            const ageRes = ageLimit(birth, gender)
            if (!ageRes.success) {
                alert(ageRes.message)
                return false
            }

            warningMessage = !phone ? '휴대전화를 입력하세요' : warningMessage + ''
            warningMessage = !name ? '이름을 입력하세요' : warningMessage + ''
            warningMessage = !birth ? '생일을 입력하세요' : warningMessage + ''
            warningMessage = !terms ? '이용약관에 동의해주세요' : warningMessage + ''
            warningMessage = !police ? '개인정보처리방침에 동의해주세요' : warningMessage + ''
            warningMessage = !textTerms ? '문자, 이메일 수신을 동의해주세요' : warningMessage + ''

            // warningMessage = !ageRes.success ? ageRes.message : warningMessage+''

            if (warningMessage) {
                alert(warningMessage)
            }

            this.setState({
                warningMessage: warningMessage
            })

            if (terms && police && phone && name && birth && textTerms) {
                this.setState({
                    activeTab: label
                });
                warningMessage = ''
            }

            // this.setState({
            //     activeTab: label
            // })
        } else if (activeTab === '기본정보') {

            if (label === '본인인증') {
                this.setState({
                    activeTab: label
                });
            } else {
                const { email, password, passwordConfirm, gender, tattoo, _area, religion, married, smoking, drinking } = this.state
                let checkValidation = false
                if (!Validation.password(password)) {
                    checkValidation = false
                    warningMessage = '비밀번호는 영문, 숫자 조합 8자 이상으로 입력하세요'
                    alert(warningMessage)
                    return false
                } else {
                    checkValidation = true
                }


                warningMessage = !email ? '이메일을 입력하세요' : warningMessage + ''
                warningMessage = !password ? '비밀전호를 입력하세요' : warningMessage + ''
                warningMessage = !passwordConfirm ? '비밀번호확인을 입력하세요' : warningMessage + ''
                warningMessage = !gender ? '남/여를 선택하세요' : warningMessage + ''
                warningMessage = !tattoo ? '타투를 선택하세요' : warningMessage + ''
                warningMessage = !_area ? '지역을 선택하세요' : warningMessage + ''
                warningMessage = !religion ? '종교를 선택하세요' : warningMessage + ''
                warningMessage = !married ? '결혼유무를 선택하세요' : warningMessage + ''
                warningMessage = !smoking ? '흡연여부를 선택하세요' : warningMessage + ''
                warningMessage = !drinking ? '음주여부를 선택하세요' : warningMessage + ''
                warningMessage = password !== passwordConfirm ? '비밀번호확인이 일치하지 않습니다.' : warningMessage + ''

                if (warningMessage) {
                    alert(warningMessage)
                }

                this.setState({
                    warningMessage: warningMessage
                })

                if (email && password && passwordConfirm && checkValidation && gender && tattoo && _area && religion && married && smoking && drinking && passwordConfirm === password) {
                    this.setState({
                        activeTab: label
                    });
                    warningMessage = ''
                }
                // this.setState({
                //     activeTab: label
                // });
            }


        } else if (activeTab === '어필정보') {

            if (label === '본인인증' || label === '기본정보') {
                this.setState({
                    activeTab: label
                });
            } else {
                const {
                    height,
                    body,
                    personality,
                    education,
                    job,
                    insurance,
                    income,
                    car,
                    estate,
                    values,
                    advantage,
                    idealType,
                    want,
                    talkTo,
                } = this.state

                warningMessage = height === 0 ? '키를 입력하세요' : warningMessage + ''
                warningMessage = !body ? '체형을 입력하세요' : warningMessage + ''
                warningMessage = !personality ? '성격을 입력하세요' : warningMessage + ''
                warningMessage = personality.length < 2 || personality.length > 4 ? '성격은 2개 이상, 4개 이하로 선택 하세요.' : warningMessage + ''
                warningMessage = !education ? '학력을 입력하세요' : warningMessage + ''
                warningMessage = !job ? '직업을 입력하세요' : warningMessage + ''
                warningMessage = !insurance ? '4대보험을 입력하세요' : warningMessage + ''
                warningMessage = income === 0 ? '작년소득을 입력하세요' : warningMessage + ''
                warningMessage = car === "none" ? '차량을 입력하세요' : warningMessage + ''
                warningMessage = estate === 0 ? '종합부동산을 입력하세요' : warningMessage + ''
                // warningMessage = !values ? '"저의 성겨과 잘하는 것은요"를 입력하세요' : warningMessage+''
                // warningMessage = !idealType ? '"저의 이상형은요"를 입력하세요' : warningMessage+''
                // warningMessage = !advantage ? '"제가 잘하는 것은요"를 입력하세요' : warningMessage+''
                warningMessage = !want ? '"연애시 가장 하고 싶은 일은요"를 입력하세요' : warningMessage + ''
                warningMessage = !talkTo ? '"매니저님 이것만은 안돼요"를 입력하세요' : warningMessage + ''
                // warningMessage = values.length > 200 ? '"저의 성겨과 잘하는 것은요"를 200자 이내로 입력하세요' : warningMessage+''
                warningMessage = advantage.length > 8 || advantage.length < 2 ? '매력포인트를 2개 이상, 7개 이하로 입력하세요' : warningMessage + ''
                warningMessage = idealType.length > 8 || idealType.length < 2 ? '이상형을 2개 이상, 7개 이하로 입력하세요' : warningMessage + ''
                warningMessage = want.length > 8 || want.length < 2 ? '데이트 스타일을 2개 이상, 7개 이하로  입력하세요' : warningMessage + ''
                warningMessage = talkTo.length > 200 ? '"매니저님 이것만은 안돼요"를 200자 이내로 입력하세요' : warningMessage + ''

                if (warningMessage) {
                    alert(warningMessage)
                    this.setState({
                        warningMessage: warningMessage
                    })
                    warningMessage = ''
                } else {
                    this.setState({
                        activeTab: label
                    });
                }

                // if( height && body && personality && education && job && insurance && income && car !== "none" && estate && idealType && want ) {
                //     this.setState({
                //         activeTab: label
                //     });
                // }
            }

        } else {
            this.setState({
                activeTab: label
            });
        }

        // this.setState({
        //     activeTab: label
        // });
    }

    componentDidMount = async () => {
        const areaRes = await Area.get()
        if (!areaRes || !areaRes.success) {
            alert('지역정보를 불러올 수 없습니다. 다시 시도해주세요.')
            return true
        }

        const teamNamesRes = await Admin.getTeamNames()
        if (!teamNamesRes || !teamNamesRes.success) {
            alert('팀 정보를 불러올 수 없습니다. 다시 시도해주세요.')
            return true
        }

        const authRes = await Auth.get(this.state._auth)
        window.scrollTo(0, 0)
        // window.addEventListener("beforeunload", this.onUnload);
        if (authRes.success) {
            this.setState({
                name: authRes.auth.user_name,
                gender: authRes.auth.sex_code === '01' ? 'male' : 'female',
                birth: moment(authRes.auth.birth_day, 'YYYYMMDD').format('YYYY-MM-DD'),
                phone: authRes.auth.phone_no,
                areas: areaRes.areas,
                teamNames: teamNamesRes.names
            })
        } else {
            if (Config.SANDBOX) {
                this.setState({
                    name: '왕종선',
                    gender: 'male',
                    birth: '1987-01-24',
                    phone: '01064822455',
                    areas: areaRes.areas,
                    teamNames: teamNamesRes.names
                })
            } else {
                this.setState({
                    areas: areaRes.areas,
                    teamNames: teamNamesRes.names
                })
            }
        }
    }

    componentWillUnmount() {
        // window.removeEventListener("beforeunload", this.onUnload);
    }

    onUnload = e => { // the method that will be used for both add and remove event
        e.preventDefault();
        e.returnValue = '';
    }

    movePage = e => {
        e.preventDefault()
        if (window.confirm('데이터가 저장되지 않을 수 있습니다. 그래도 이동하시겠습니까?')) {
            this.props.history.push('/login ')
        }
    }

    render() {
        const {
            onChange,
            onChangePersonality,
            onChnageAdventage,
            onChangeAllTerms,
            onChangeFile,
            onSubmit,
            onClickMoveTab,
            onDeletePhoto,
            onVisibleChange,
            eamilCheck,
            checkAuthCerti,
            onChnageIdealType,
            onChnageWant,
            state: {
                isLoading,
                allterms,
                terms,
                police,
                textTerms,
                name,
                phone,
                birth,
                email,
                password,
                passwordConfirm,
                gender,
                tattoo,
                _area,
                religion,
                married,
                smoking,
                drinking,
                height,
                heightVisible,
                body,
                bodyVisible,
                personality,
                personalityVisible,
                education,
                educationVisible,
                job,
                jobVisible,
                insurance,
                insuranceVisible,
                income,
                incomeVisible,
                car,
                carVisible,
                estate,
                estateVisible,
                values,
                advantage,
                idealType,
                want,
                talkTo,
                _main, // 프로필사진 (필수)
                _main_preview,
                _privateR, // 개인사진 (필수)
                _privateR_preview,
                _hobbyLife,
                _hobbyLife_preview,
                activeTab,
                warningMessage,
                areas,
                passwordMessege,
                _auth,
                teamNames,
                initTeam
            }
        } = this

        let loadingComp
        if (isLoading) {
            loadingComp = <div className="loading-comp">
                <div className="text-center">
                    <div>
                        <img src={Logo} alt="logo" className="연애해" />
                    </div>
                    요청 처리중...
                </div>
            </div>
        }
        const visibleForm = _auth ? true : false
        return (
            <div>
                {loadingComp}
                <form onSubmit={onSubmit}>
                    <SignupTabs activeTab={activeTab} onClickMoveTab={onClickMoveTab}>
                        <div label="본인인증">
                            <SignupStep1
                                onChange={onChange}
                                onChangeAllTerms={onChangeAllTerms}
                                onClickMoveTab={onClickMoveTab}
                                checkAuthCerti={checkAuthCerti}
                                data={{ allterms, terms, police, textTerms, name, phone, birth, warningMessage, visibleForm }}
                            />
                        </div>
                        <div label="기본정보">
                            <SignupStep2
                                onChange={onChange}
                                onBlur={eamilCheck}
                                onClickMoveTab={onClickMoveTab}
                                data={{ email, password, passwordConfirm, gender, initTeam, tattoo, _area, religion, married, smoking, drinking, warningMessage }}
                                teamNames={teamNames}
                                areas={areas}
                                passwordMessege={passwordMessege}
                            />
                        </div>
                        <div label="어필정보">
                            <SignupStep3
                                onChange={onChange}
                                onChangePersonality={onChangePersonality}
                                onChnageAdventage={onChnageAdventage}
                                onChnageIdealType={onChnageIdealType}
                                onVisibleChange={onVisibleChange}
                                onClickMoveTab={onClickMoveTab}
                                onChnageWant={onChnageWant}
                                data={{
                                    height,
                                    heightVisible,
                                    body,
                                    bodyVisible,
                                    personality,
                                    personalityVisible,
                                    education,
                                    educationVisible,
                                    job,
                                    jobVisible,
                                    insurance,
                                    insuranceVisible,
                                    income,
                                    incomeVisible,
                                    car,
                                    carVisible,
                                    estate,
                                    estateVisible,
                                    values,
                                    advantage,
                                    idealType,
                                    want,
                                    talkTo,
                                    warningMessage
                                }}
                            />
                        </div>
                        <div label="프로필">
                            <SignupStep4
                                onChange={onChangeFile}
                                onClickMoveTab={onClickMoveTab}
                                deletePhoto={onDeletePhoto}
                                data={{
                                    _main, // 프로필사진 (필수)
                                    _main_preview,
                                    _privateR, // 개인사진 (필수)
                                    _privateR_preview,
                                    _hobbyLife,
                                    _hobbyLife_preview,
                                    warningMessage
                                }}
                            />
                        </div>
                    </SignupTabs>
                </form>
                <div className="text-center mb-5">
                    <a href="/login" className="text-secondary" onClick={this.movePage}>로그인하러 가기</a>
                </div>
            </div>
        )
    }
}

export default withRouter(Signup)
