import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Head from '../components/Head'
import Footer from '../components/Footer'
import Main from '../pages/Main'
import MatchState from '../pages/MatchState'
import Pass from '../pages/Pass'
import InfoModify from '../pages/InfoModify'
import Interest from '../pages/Interest'
import Block from '../pages/Block'
import Passwordchange from '../pages/PasswordChange'
import Gradeinfo from '../pages/Grade'
export class defaultLayout extends Component {

    render() {
        return (
            <div className="">
                <Head />
                <div className="container">
                    <div className="main-content">
                        <Route exact path='/main' component={Main} />
                        <Route exact path='/matchstate' component={MatchState} />
                        <Route exact path='/pass' component={Pass} />
                        <Route exact path='/infomodify' component={InfoModify}/>
                        <Route exact path='/Interest' component={Interest}/>
                        <Route exact path='/block' component={Block}/>
                        <Route exact path='/passwordchange' component={Passwordchange}/>
                        <Route exact path='/grade' component={Gradeinfo}/>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default defaultLayout
