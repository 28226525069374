import React, { Component } from 'react';

class Footer extends Component{
    render(){
        return(
            <div>
                <div className="bg-footer py-3">
                    <div className="container">
                        <div className="d-flex">
                            <div className="flex-basis-auto text-center">
                                <a href="/" className="text-dark">Home</a>
                            </div>
                            <div className="flex-basis-auto text-center">
                                <a href="/service" className="text-dark">1:1케어란?</a>
                            </div>
                            <div className="flex-basis-auto text-center">
                                <a href="/menual" className="text-dark">비회원맛보기</a>
                            </div>
                            <div className="flex-basis-auto text-center">
                                <a href="/about_us" className="text-dark">회사소개</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="divider-light"></div>
                <div className="bg-footer2 py-4">
                    <div className="container-wide">
                        <div>
                            <address className="font-size-clamp-min050rem-cen150vw-max82rem text-center">
                                상호명 : 연애해
                                <span> &middot; </span>
                                대표이사 : 왕종욱
                                <span> &middot; </span>
                                주소 : 21, Jangseungbaegi-ro 3-gil, Dongjak-gu, Seoul, Korea
                                <span> &middot; </span>
                                사업자등록번호 : 493-20-00554
                                <span> &middot; </span>
                                통신판매업신고 : 2021서울동작1744호
                                <span> &middot; </span>
                                대표번호 : 1644-1885
                                <span> &middot; </span>
                                고객센터 : support@yeonhae.com
                            </address>
                        </div>
                        <div className="text-center small">
                            <div className="text-muted">Copyright &copy; 연애해 2021</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Footer;