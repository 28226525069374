import React, { Component } from 'react'
import PropTypes from 'prop-types'
export class SignupStep2 extends Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        onClickMoveTab: PropTypes.func.isRequired,
        data: PropTypes.shape({
            email: PropTypes.string.isRequired,
            password: PropTypes.string.isRequired,
            passwordConfirm: PropTypes.string.isRequired,
            gender: PropTypes.string.isRequired,
            tattoo: PropTypes.string.isRequired,
            initTeam: PropTypes.string.isRequired,
            _area: PropTypes.string.isRequired,
            religion: PropTypes.string.isRequired,
            married: PropTypes.string.isRequired,
            smoking: PropTypes.string.isRequired,
            drinking: PropTypes.string.isRequired,
            warningMessage: PropTypes.string.isRequired
        }),
        teamNames: PropTypes.array.isRequired,
        areas: PropTypes.array.isRequired,
    }

    render() {
        const {
            props: {
                onChange,
                onClickMoveTab,
                onBlur,
                data: {
                    email,
                    password,
                    passwordConfirm,
                    gender,
                    initTeam,
                    tattoo,
                    _area,
                    religion,
                    married,
                    smoking,
                    drinking,
                    warningMessage
                },
                teamNames,
                areas,
                passwordMessege
            }
        } = this

        return (
            <div className="card border-radius-0 mb-3">
                <div className="card-body">
                    <p className="text-red small">* 기본정보는 다른이용자에게 공개되지 않습니다.</p>
                    <div className={!email ? "form-group floating-form-group" : "form-group floating-form-group active"}>
                        <input type="email" className="form-control floating-input" name="email" onChange={onChange} onBlur={onBlur} defaultValue={email} />
                        <label className="floating-label">이메일</label>
                        <p className="small">비밀번호 분실시 등록된 이메일 ID로 전송됩니다</p>
                    </div>
                    <div className={!password ? "form-group floating-form-group" : "form-group floating-form-group active"}>
                        <input type="password" className="form-control floating-input" name="password" onChange={onChange} defaultValue={password} />
                        <label className="floating-label">비밀번호</label>
                        <p className="small">영문, 숫자 조합 8자 이상</p>
                    </div>
                    <div className={!passwordConfirm ? "form-group floating-form-group" : "form-group floating-form-group active"}>
                        <input type="password" className="form-control floating-input" name="passwordConfirm" onChange={onChange} defaultValue={passwordConfirm} />
                        <label className="floating-label">비밀번호 확인</label>
                        {
                            passwordMessege && <p className="small">{passwordMessege}</p>
                        }
                    </div>
                    <div className={gender === '' ? "form-group floating-form-group" : "form-group floating-form-group active"}>
                        <select className="form-control floating-input" name="gender" onChange={onChange} defaultValue={gender}>
                            <option value="" disabled={true}>선택</option>
                            <option value="male" disabled={true}>남</option>
                            <option value="female" disabled={true}>여</option>
                        </select>
                        <label className="floating-label">성별</label>
                    </div>
                    <div className={initTeam === '' ? "form-group floating-form-group" : "form-group floating-form-group active"}>
                        <select className="form-control floating-input" name="initTeam" onChange={onChange} defaultValue={initTeam}>
                            <option value="" disabled={true}>선택</option>
                            {
                                teamNames.map(value => <option key={value} value={value}>{value}</option>)
                            }
                        </select>
                        <label className="floating-label">담당케어</label>
                    </div>
                    <div className={tattoo === '' ? "form-group floating-form-group" : "form-group floating-form-group active"}>
                        <select className="form-control floating-input" name="tattoo" onChange={onChange} defaultValue={tattoo}>
                            <option value="" disabled={true}>선택</option>
                            <option value="크고화려함">크고화려함</option>
                            <option value="손바닥정도">손바닥정도</option>
                            <option value="포인트살짝">포인트살짝</option>
                            <option value="안함">안함</option>
                        </select>
                        <label className="floating-label">타투</label>
                    </div>
                    <div className={_area === '' ? "form-group floating-form-group" : "form-group floating-form-group active"}>
                        <select className="form-control floating-input" name="_area" onChange={onChange} defaultValue={_area} >
                            <option value="" disabled={true}>선택</option>
                            {
                                areas.map(item => <option key={item._id} value={item._id}>{item.city} {item.name}</option>)
                            }
                        </select>
                        <label className="floating-label">지역</label>
                    </div>
                    <div className={religion === '' ? "form-group floating-form-group" : "form-group floating-form-group active"}>
                        <select className="form-control floating-input" name="religion" onChange={onChange} defaultValue={religion} >
                            <option value="" disabled={true}>선택</option>
                            <option value="무교">무교</option>
                            <option value="기독교">기독교</option>
                            <option value="천주교">천주교</option>
                            <option value="불교">불교</option>
                            <option value="기타">기타</option>
                        </select>
                        <label className="floating-label">종교</label>
                    </div>
                    <div className={married === '' ? "form-group floating-form-group" : "form-group floating-form-group active"}>
                        <select className="form-control floating-input" name="married" onChange={onChange} defaultValue={married} >
                            <option value="" disabled={true}>선택</option>
                            <option value="미혼">미혼</option>
                            <option value="돌싱">돌싱</option>
                        </select>
                        <label className="floating-label">미혼여부</label>
                    </div>
                    <div className={smoking === '' ? "form-group floating-form-group" : "form-group floating-form-group active"}>
                        <select className="form-control floating-input" name="smoking" onChange={onChange} defaultValue={smoking} >
                            <option value="" disabled={true}>선택</option>
                            <option value="흡연">흡연</option>
                            <option value="비흡연">비흡연</option>
                        </select>
                        <label className="floating-label">흡연</label>
                    </div>
                    <div className={drinking === '' ? "form-group floating-form-group" : "form-group floating-form-group active"}>
                        <select className="form-control floating-input" name="drinking" onChange={onChange} defaultValue={drinking} >
                            <option value="" disabled={true}>선택</option>
                            <option value="즐겨함">즐겨함</option>
                            <option value="가끔">가끔</option>
                            <option value="안함">안함</option>
                        </select>
                        <label className="floating-label">음주여부</label>
                    </div>
                    <div className="mb-3 d-flex justify-content-between">
                        <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => onClickMoveTab('본인인증')}
                        > <i className="fas fa-chevron-left"></i> 이전 </button>
                        <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => onClickMoveTab('어필정보')}
                        > 다음 <i className="fas fa-chevron-right"></i> </button>
                    </div>
                    <div className="alert-label text-center mt-3 text-warning">
                        {warningMessage && warningMessage}
                    </div>
                </div>
            </div>
        )
    }
}

export default SignupStep2
