import React, { Component } from 'react'
import PropTypes from 'prop-types'

export class SignupStep4 extends Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        onClickMoveTab: PropTypes.func.isRequired,
        data: PropTypes.shape({
            _main_preview: PropTypes.array.isRequired, // 프로필사진 (필수)
            _privateR_preview: PropTypes.array.isRequired, // 개인사진 (필수)
            _hobbyLife_preview: PropTypes.array.isRequired,
            warningMessage: PropTypes.string.isRequired
        })
    }

    _renderPreview(name, previews, index, onChange, deletePhoto) {
        const length = previews ? previews.length : 0
        if (length < index)
            return (<></>)

        const jsxInput = (<input name={name}
            type="file"
            accept="image/jpeg, image/png" 
            className="form-control"
            onChange={e => onChange(index, e)}
            onClick={e => e.target.value = null}
            hidden
        />)

        if (length == index) {
            return (<div key={index}>
                <label className="preview d-flex justify-content-center">
                    <div className="align-self-center text-danger">
                        <i className="fas fa-camera"></i>
                    </div>
                    {jsxInput}
                </label>             
            </div>)
        }

        return (<div key={index}>
            <label className="preview d-flex justify-content-center mr-2"
                style={{
                    backgroundImage: `url(${previews[index]})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}>
                {jsxInput}
            </label>
            {
                deletePhoto &&
                <div className="text-center">
                    <span className="badge badge-secondary" onClick={() => deletePhoto(index, name)}>삭제</span>
                </div>
            }
        </div>)
    }

    render() {
        const {
            props: {
                onChange,
                onClickMoveTab,
                deletePhoto,
                data: {
                    _main_preview, // 프로필사진 (필수)
                    _privateR_preview, // 개인사진 (필수)
                    _hobbyLife_preview,
                    warningMessage
                }
            }
        } = this

        return (
            <div className="card border-radius-0 mb-3">
                <div className="card-body">
                <p className="text-red small">* 각 항목당 사진 1개는 필수입니다.</p>
                    <div className="form-group">
                        <label className="small">프로필 사진</label>
                        {this._renderPreview("_main", _main_preview, 0, onChange, null)}
                    </div>
                    <div className="form-group">
                        <label className="small">개인 사진</label>
                        <div className="d-flex">
                            {this._renderPreview("_privateR", _privateR_preview, 0, onChange, 1 < _privateR_preview.length ? deletePhoto : null)}
                            {this._renderPreview("_privateR", _privateR_preview, 1, onChange, deletePhoto)}
                            {this._renderPreview("_privateR", _privateR_preview, 2, onChange, deletePhoto)}
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="small">취미/일상 사진</label>
                        <div className="d-flex">
                            {this._renderPreview("_hobbyLife", _hobbyLife_preview, 0, onChange, 1 < _hobbyLife_preview.length ? deletePhoto : null)}
                            {this._renderPreview("_hobbyLife", _hobbyLife_preview, 1, onChange, deletePhoto)}
                            {this._renderPreview("_hobbyLife", _hobbyLife_preview, 2, onChange, deletePhoto)}
                        </div>
                    </div>
                    <div className="mb-3 d-flex justify-content-between">
                        <button 
                            type="button" 
                            className="btn btn-danger" 
                            onClick={() => onClickMoveTab('어필정보')}
                        > <i className="fas fa-chevron-left"></i> 이전 </button>
                        <button type="submit" className="btn btn-primary" > 회원가입 신청하기 <i className="fas fa-chevron-right"></i> </button>
                    </div>
                    <div className="alert-label text-center mt-3 text-warning">
                        { warningMessage && warningMessage}
                    </div>
                </div>
            </div>
        )
    }
}

export default SignupStep4
