import React, { Component } from 'react'
import moment from 'moment'
import { AuthContext } from '../components/AuthenticatedComponent'
import Match from '../api/match.api'
import UserMainCard from '../components/UserMainCard'
import RecommendMainCard from '../components/RecommendMainCard'
import Goods from '../helper/goods.helper'
export class Main extends Component {

    static contextType = AuthContext;

    constructor(props) {
        super(props)
    
        this.state = {
            showItem: null,
            connectItem: null,
            standardTicket: null,
            goldTicket: null,
            platinumTicket: null,
            diamondTicket: null,

            loaded: false,
            showExpireAt: null,// TODO: jason_wang: showExpireAt 은 대상별로 별도로 취급하는것이 확장성을 확보가능하다. 다만 지금할수 있는게 아님 (더보여줘 도입시 사업자와 논의가 필요)
            todayMatches: null,
            todayRecommends: null,
            selectedRecommend: null
        }
    }
    
    componentDidMount = async () => {
        const {
            context: {
                history
            }
        } = this

        this.setState({
            showItem: Goods.create(history.showItem_free, history.showItem_paid, history.showItem_price),
            connectItem: Goods.create(history.connectItem_free, history.connectItem_paid, history.connectItem_price),
            standardTicket: Goods.create(history.standardTicket_free, history.standardTicket_paid, history.standardTicket_price),
            goldTicket: Goods.create(history.goldTicket_free, history.goldTicket_paid, history.goldTicket_price),
            platinumTicket: Goods.create(history.platinumTicket_free, history.platinumTicket_paid, history.platinumTicket_price),
            diamondTicket: Goods.create(history.diamondTicket_free, history.diamondTicket_paid, history.diamondTicket_price),
        })

        //날짜 시간 해보기
        this.timerID = setInterval(
            () => this.tick(), 1000
        )
        const now = moment()

        const matchRes = await Match.todayShow()

        if (matchRes.success) {
            const matches = matchRes.matches
            if (matches && 0 < matches.length) {
                let recommends = matchRes.recommends
                if (recommends) {
                    let recommendsNew = []
                    recommends.forEach(recommend => {
                        const match = matches.find(match => recommend._match == match._id) 
                        if (match) {
                            recommend._match = match
                            recommendsNew.push(recommend)
                        } else {
                            console.log(`INVALID_RECOMMEND_ID:${recommend._id}, MATCH_ID:${recommend._match}`)
                        }
                    })
                    recommendsNew.sort((a, b) => {
                        return moment(a.createdAt) - moment(b.createdAt)
                    })
                    recommends = recommendsNew
                }
                this.setState({
                    loaded: true,
                    showExpireAt: this.getNextExpireAt(now, matches),
                    todayMatches: matches,
                    todayRecommends: recommends,
                    showable: matchRes.showable
                })
            } else {
                this.setState({
                    loaded: true,
                    showExpireAt: null,
                    todayMatches: null,
                    todayRecommends: null,
                    showable: matchRes.showable
                })
            }
        } else {
            alert('사용자 매치정보를 호출하지 못했습니다. 관리자에게 문의하세요.')
            return false
        }
    }

    componentWillUnmount() {
        clearInterval(this.timerID)
    }

    getNextExpireAt(now, matches) {
        if (!matches || !now)
            return null

        var selected = null
        matches.forEach(elem => {
            if (!elem.showExpireAt)
                return  // NOTE: jason_wang: candidate 를 구할 수 없음

            const candidate = moment(elem.showExpireAt)
            if (candidate < now)
                return  // NOTE: jason_wang: candidate 는 과거임
            
            // NOTE: jason_wang: candidate 는 과거가 아님
            
            if (selected && selected <= candidate)
                return  // NOTE: jason_wang: candidate 는 현재 선택된 값 보다 미래임 

            // NOTE: jason_wang: candidate 는 과거는 아니지만 현재 선택된 값 보다는 가까운 미래임 (또는 현재 선택된 값이 없거나)
            selected = candidate
        })

        return selected
    }

    tick() {
        const now = moment()
        let showExpireAt = this.state.showExpireAt
        if (showExpireAt && showExpireAt < now) {
            showExpireAt = this.getNextExpireAt(now, this.state.todayRecommends)
            if (showExpireAt) {
                this.setState({ showExpireAt: showExpireAt })
            } else {
                this.setState({ showExpireAt: null })
            }
        }
        
        this.setState((state) => ({
            date: new Date()
        }))
    }
    
    doSpentGoods = (goods, count = 1) => {
        const shortage = Goods.spent(goods, count)
        if (0 != shortage) { 
            goods = Goods.create(0, 0, 0)
            console.log(`내부 로직 에러, 아이템 소모에 ${shortage} 만큼 부족함`)
        }
        return goods
    }

    spentGoods = (goodsName, count = 1) => {
        switch (goodsName) {
            case 'showItem':
                this.setState({ showItem: this.doSpentGoods(this.state.showItem, count) })
                break
            
            case 'connectItem':
                this.setState({ connectItem: this.doSpentGoods(this.state.connectItem, count) })
                break
            
            case 'standardTicket':
                this.setState({ standardTicket: this.doSpentGoods(this.state.standardTicket, count) })
                break
            
            case 'goldTicket':
                this.setState({ goldTicket: this.doSpentGoods(this.state.goldTicket, count) })
                break
            
            case 'platinumTicket':
                this.setState({ platinumTicket: this.doSpentGoods(this.state.platinumTicket, count) })
                break
            
            case 'diamondTicket':
                this.setState({ diamondTicket: this.doSpentGoods(this.state.diamondTicket, count) })
                break
        }

        this.updateHistory()
    }

    updateHistory = () => {
        let history = this.context.history

        history.showItem_free = Goods.free(this.state.showItem)
        history.showItem_paid = Goods.paid(this.state.showItem)
        history.showItem_price = Goods.price(this.state.showItem)

        history.connectItem_free = Goods.free(this.state.connectItem)
        history.connectItem_paid = Goods.paid(this.state.connectItem)
        history.connectItem_price = Goods.price(this.state.connectItem)

        history.standardTicket_free = Goods.free(this.state.standardTicket)
        history.standardTicket_paid = Goods.paid(this.state.standardTicket)
        history.standardTicket_price = Goods.price(this.state.standardTicket)

        history.goldTicket_free = Goods.free(this.state.goldTicket)
        history.goldTicket_paid = Goods.paid(this.state.goldTicket)
        history.goldTicket_price = Goods.price(this.state.goldTicket)

        history.platinumTicket_free = Goods.free(this.state.platinumTicket)
        history.platinumTicket_paid = Goods.paid(this.state.platinumTicket)
        history.platinumTicket_price = Goods.price(this.state.platinumTicket)

        history.diamondTicket_free = Goods.free(this.state.diamondTicket)
        history.diamondTicket_paid = Goods.paid(this.state.diamondTicket)
        history.diamondTicket_price = Goods.price(this.state.diamondTicket)
    }

    render() {
        const {
            spentGoods,
            state: {
                showItem,
                connectItem,
                standardTicket,
                goldTicket,
                platinumTicket,
                diamondTicket,

                loaded,
                todayRecommends,
                showable
            },
            
        } = this

        if (!loaded) {
            return <div>매치정보 불러오는 중...</div>
        }

        let matchTimer = <p></p>
        let contentMatch = <></>
        const hasTodayRecommends = todayRecommends && 0 < todayRecommends.length
        if (hasTodayRecommends) {
            const showExpireAt = this.state.showExpireAt
            if (showExpireAt) {
                const now = moment()
                if (showExpireAt >= now) {
                    const remainTime = moment.duration(showExpireAt.diff(now))
                    matchTimer = <p className="small mt-3 font-weight-bold text-secondary">오늘의 남은 매칭시간 <strong className="text-info">{remainTime.hours() + '시간 ' + remainTime.minutes() + '분 ' + remainTime.seconds() + '초 남음'}</strong></p>
                    // TODO: jason_wang: 추후 매칭 남은시간을 건별로 보여주면 위의 UX 처리는 수정이 필요하다 (재대로된 더 보여줘 상품 판매시 매칭 남은시간을 통합이 아닌 건별로 보여줄 수 있음)
                }
            }

            contentMatch = <RecommendMainCard todayRecommends={todayRecommends} spentGoods={spentGoods}/>
        }

        let contentShow = <></>
        if (showable || !hasTodayRecommends) {
            contentShow = <UserMainCard showable={showable} />
        }

        return (
            <div>
                <div className="row">
                    <div className="col-3">
                        <div className="px-2 separator-right">
                            <p className="small mb-0 font-weight-bold text-secondary">이어줘충전</p>
                            <div className="d-flex w-100">
                                <div className="w-100">
                                    <h6 className="text-secondary mb-0"><span className="text-danger" style={{ fontSize: '27px' }}>{Goods.count(connectItem)}</span> 회</h6>
                                    <div className="small">이어줘</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-9">
                        <div className="px-2">
                            <p className="small mb-0 font-weight-bold text-secondary">내 성사 이용권</p>
                            <div className="d-flex w-100">
                                <div className="w-100">
                                    <h6 className="text-secondary mb-0"><span className="text-danger" style={{ fontSize: '27px' }}>{Goods.count(standardTicket)}</span> 회</h6>
                                    <div className="small ">스탠다드</div>
                                </div>
                                <div className="w-100">
                                    <h6 className="text-secondary mb-0"><span className="text-danger" style={{ fontSize: '27px' }}>{Goods.count(goldTicket)}</span> 회</h6>
                                    <div className="small ">골드</div>
                                </div>
                                <div className="w-100">
                                    <h6 className="text-secondary mb-0"><span className="text-danger" style={{ fontSize: '27px' }}>{Goods.count(platinumTicket)}</span> 회</h6>
                                    <div className="small ">플래티넘</div>
                                </div>
                                <div className="w-100">
                                    <h6 className="text-secondary mb-0"><span className="text-danger" style={{ fontSize: '27px' }}>{Goods.count(diamondTicket)}</span> 회</h6>
                                    <div className="small ">다이아</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <h3 className="text-secondary px-2">{title}</h3> */}
                <div className="px-2">
                    {matchTimer}
                </div>
                <div>
                    {contentMatch}
                </div>
                <div>
                    {contentShow}
                </div>
            </div>
        )
    }
}

export default Main
