import React, { Component } from 'react'
import Logo from '../img/logo.png'
import User from '../api/user.api'
import Footer from '../components/Footer'
import moment from 'moment'
import { regicountApi } from '../api/regicount.api'
import Config from '../config/config'

class login extends Component {

    constructor(props) {
        super(props)

        this.state = {
            email: '',
            password: '',
            autoLogin: false,
            regitotal: null,
            regitoday: null,
            matchtoday: null
        }
    }

    onChange = (e) => {
        let value = e.target.value
        this.setState({ [e.target.name]: value })
    }

    onCheck = (e) => {
        let value = e.target.checked
        this.setState({ [e.target.name]: value })
    }

    onSubmit = async (e) => {
        e.preventDefault();

        const {
            email,
            password,
            autoLogin
        } = this.state

        let loginParams = {}
        loginParams.email = email
        loginParams.password = password

        const loginRes = await User.login(loginParams)
        if (!loginRes.success) {

            alert(loginRes.message)
            return false
        } else {
            // const userRes = await User.approval();

            localStorage.setItem('autoLogin', true)
            localStorage.setItem('token', loginRes.token)

            // if(autoLogin) {
            //     localStorage.setItem('autoLogin', autoLogin)
            //     localStorage.setItem( 'token', loginRes.token)
            //     // this.setState({
            //     //     ...this.state,
            //     //     token : localStorage.getItem('token')
            //     // })
            // } else {
            //     // localStorage.setItem('autoLogin', true)
            //     localStorage.setItem('autoLogin', autoLogin)
            //     // localStorage.setItem( 'token', loginRes.token)
            //     sessionStorage.setItem('token', loginRes.token)
            //     // this.setState({
            //     //     token : sessionStorage.getItem('token')
            //     // })
            // }

            if (loginRes.userStatus === 'rest') {
                if (window.confirm('휴면상태 계정입니다. 휴면 상태를 푸시겠습니까?')) {
                    const userUpdateRes = await User.approval()
                    if (!userUpdateRes.success) {
                        alert('계정상태 변경 실패!')
                        localStorage.removeItem('autoLogin')
                        localStorage.removeItem('token')
                        sessionStorage.removeItem('autoLogin')
                        sessionStorage.removeItem('token')

                        return false;
                    }
                } else {
                    alert('휴면상태를 해제를 하셔야 로그인 할 수 있습니다.')
                    localStorage.removeItem('autoLogin')
                    localStorage.removeItem('token')
                    sessionStorage.removeItem('autoLogin')
                    sessionStorage.removeItem('token')
                    return false;
                }
            }
            this.props.history.push('/')
        }
    }

    componentDidMount = async () => {
        const regicount = await regicountApi.get()
        if (regicount.success && regicount.regicount.length > 0) {
            const data = regicount.regicount[0]
            this.setState({
                regitotal: data.total,
                regitoday: data.today,
                matchtoday: data.match
            })
        }
    }

    // componentDidMount(){
    //     if(localStorage.getItem('token') || sessionStorage.getItem('token')) {
    //         this.props.history.push('/')
    //     }
    // }
    // ...

    render() {
        const {
            onSubmit,
            onChange,
            onCheck,
            state: {
                email,
                password
            }
        } = this

        const contentTotalRegisted = Config.showTotalRegisted ? (<h6>가입회원 : <span className="text-danger">{this.state.regitotal}</span></h6>) : (<></>)
        const contentTodayRegisted = Config.showTodayRegisted ? (<h6>오늘가입자 : <span className="text-danger">{this.state.regitoday}</span></h6>) : (<></>)
        const contentTodayMatch = Config.showTodayMatch ? (<h6>오늘성사 : <span className="text-danger">{this.state.matchtoday}</span></h6>) : (<></>)

        return (
            <div>
                <div className="login-bg" style={{ paddingTop: '100px' }}>
                    <div className="login-box py-3">
                        <div className="text-center mb-3">
                            <img src={Logo} alt="연애해" className="login-logoimg" />
                            <h3><span className="text-danger">No</span> <span className="small text-white">가입비</span> <span className="text-danger">No</span> <span className="small text-white">사람차별</span> </h3>
                            <h3><span className="text-danger">No</span> <span className="small text-white">신상노출</span> <span className="text-danger">No</span> <span className="small text-white">거짓정보</span> </h3>
                        </div>
                        <div style={{ marginLeft: 15, marginRight: 15 }}>
                            <div className="text-center mb-5 text-white d-flex justify-content-between">
                                {contentTotalRegisted}
                                {contentTodayRegisted}
                                {contentTodayMatch}
                            </div>
                        </div>
                        <form onSubmit={onSubmit}>
                            <div className={!email ? "form-group floating-form-group" : "form-group floating-form-group active"}>
                                <input type="email" className="form-control floating-input text-dark" name="email" onChange={onChange} />
                                <label className="floating-label">이메일</label>
                            </div>
                            <div className={!password ? "form-group floating-form-group" : "form-group floating-form-group active"}>
                                <input type="password" className="form-control floating-input text-dark" name="password" onChange={onChange} />
                                <label className="floating-label">비밀번호</label>
                            </div>
                            {/* <div className="form-check">
                                <input type="checkbox" onChange={onCheck} className="form-check-input" name="autoLogin" />
                                <label className="form-check-label text-white">자동 로그인</label>
                            </div> */}
                            <div className="mt-2 mb-3">
                                <button type="submit" className="btn btn-danger btn-block">로그인</button>
                                <a href="/signup" className="btn btn-light btn-block">회원가입</a>
                            </div>
                            <div className="text-center small">
                                <div className="py-2">
                                    <a href="/findid" className="text-white">아이디를 잊으셨나요?</a>
                                </div>
                                <div className="py-2">
                                    <a href="/findpw" className="text-white">비밀번호를 잊으셨나요?</a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}
export default login;