import React, { Component } from 'react'
import moment from 'moment'
import Config from '../config/config'
import { AuthContext } from '../components/AuthenticatedComponent'
import optionChecker from '../helper/optionChecker.helper'
import Age from '../helper/age.helper'
import Match from '../api/match.api'
import { withRouter } from "react-router-dom";
// import { matchAble } from '../helper/grade.helper'// DEPRECATED: jason_wang: 로직이 바뀌었다. 이제 성사이용권 보유 여부는 확인하지 않고 이어줘만 확인한다
import PhotoModal from "./PhotoModal"

export class RecommendMainCard extends Component {

    static contextType = AuthContext;

    constructor(props) {
        super(props)

        this.state = {
            todayRecommends: [],
            photoModal: (<></>),
        }
    }

    getRemainConnectItem = () => {
        const history = this.context.history
        return history ? Number(history.connectItem_free) + Number(history.connectItem_paid) : 0
    }

    matchRequestClick = async (e) => {
        let grade = ''
        const matchItem = e.target.dataset.match
        const userItem = e.target.dataset.user
        const photoFullPath = e.target.dataset.photo
        const recoItem = e.target.dataset.reco
        const remainConnectItem = this.getRemainConnectItem()
        if (0 < remainConnectItem) {
            this.setState({
                photoModal: <PhotoModal
                    photo={`${Config.photoUrl}${photoFullPath}`}
                    body={
                        <>
                            <h4>매니저에게 이어줘 요청 하시겠습니까?</h4>
                            <p>(남은 이어줘 : {`${remainConnectItem}`}개)</p>
                        </>
                    }
                    buttonPrimary={{
                        label: "이어줘",
                        hide: false,
                        onClickAsync: async () => {
                            const patchMatchRes = await Match.setResponse(matchItem, {
                                _user: userItem,
                                status: "response"
                            });
                            if (patchMatchRes.success) {
                                this.props.spentGoods('connectItem', 1)
                                this.setState({
                                    todayRecommends: this.state.todayRecommends.map(item => {
                                        if (item._id === recoItem) {
                                            item.status = "success"
                                        } else if (item._match._id == matchItem) {
                                            item.status = "fail"
                                        }
                                        return item
                                    })
                                })
                            } else {
                                if (!Config.__LIVE__)
                                    alert(patchMatchRes.message)
                                alert('이어줘 요청에 실패했습니다. 관리자에게 문의해주세요.')
                            }

                            this.setState({ photoModal: (<></>) })
                            return true
                        },
                    }}
                    buttonSecondary={{
                        label: "취소",
                        onClick: () => {
                            this.setState({ photoModal: (<></>) })
                            return true
                        }
                    }}
                />,
            })
        } else {
            if (window.confirm("이어줘가 필요합니다. 충전하시겠습니까? 이어줘는 매니저가 이어주기 위한 소정의 착수금입니다.")) {
                this.props.history.push('/pass')
            }
            return false
        }
    }

    componentDidMount() {
        const {
            props: {
                todayRecommends,
            }
        } = this

        const reversedRecommends = todayRecommends.reverse()

        const setTodayRecommends = reversedRecommends.map(item => {
            item.photos = []
            item.photos.push(item._response._photos._main)
            item.photos.push(...item._response._photos._hobbyLife)
            item.photos.push(...item._response._photos._privateR)

            item.userBasic = []
            item.userBasic.push({ name: '등급', value: optionChecker.grade(item._response.grade) })
            item.userBasic.push({ name: '지역', value: item._response._area.city })
            item.userBasic.push({ name: '나이', value: Age(item._response.birth) })

            item.userProfile = []
            if (item._response.body.visible) {
                item.userProfile.push({ name: '체형', value: item._response.body.value })
            }
            if (item._response.education.visible) {
                item.userProfile.push({ name: '학력', value: item._response.education.value })
            }
            if (item._response.car.visible) {
                item.userProfile.push({ name: '차', value: optionChecker.car(item._response.car.value) })
            }
            if (item._response.estate.visible) {
                item.userProfile.push({ name: '자산', value: optionChecker.estate(item._response.estate.value) })
            }
            if (item._response.height.visible) {
                item.userProfile.push({ name: '키', value: optionChecker.height(item._response.height.value) })
            }
            if (item._response.income.visible) {
                item.userProfile.push({ name: '수입', value: optionChecker.income(item._response.income.value) })
            }
            if (item._response.insurance.visible) {
                item.userProfile.push({ name: '보험', value: item._response.insurance.value })
            }
            if (item._response.job.visible) {
                item.userProfile.push({ name: '직업', value: item._response.job.value })
            }
            if (item._response.personality.visible) {
                const personality = item._response.personality.value
                if (personality && 0 < personality.length) {
                    const ssv = []
                    personality.forEach(item => ssv.push(item.replace(' ', '')))
                    item.userProfile.push({ name: '성격', value: ssv.join(' ') })
                }
            }

            item.userTrophy = []
            if (item._response._trophy) {
                const trophy = item._response._trophy
                if (trophy.pet.post) {
                    item.userTrophy.push({ name: '반려', icon: 'icon-1', value: trophy.pet.value })
                }
                if (trophy.donation.post) {
                    item.userTrophy.push({ name: '봉사기부', icon: 'icon-2', value: trophy.donation.value })
                }
                if (trophy.estate.post) {
                    item.userTrophy.push({ name: '부동산', icon: 'icon-3', value: trophy.estate.value })
                }
                if (trophy.income.post) {
                    item.userTrophy.push({ name: '작년소득', icon: 'icon-4', value: trophy.income.value })
                }
                if (trophy.award.post) {
                    item.userTrophy.push({ name: '수상장학', icon: 'icon-5', value: trophy.award.value })
                }
                if (trophy.tax.post) {
                    item.userTrophy.push({ name: '세금납부', icon: 'icon-7', value: trophy.tax.value })
                }
                if (trophy.work.post) {
                    item.userTrophy.push({ name: '재직재학', icon: 'icon-8', value: trophy.work.value })
                }
                if (trophy.sales.post) {
                    item.userTrophy.push({ name: '작년도매출', icon: 'icon-9', value: trophy.sales.value })
                }
                if (trophy.graduation.post) {
                    item.userTrophy.push({ name: '졸업학위', icon: 'icon-10', value: trophy.graduation.value })
                }
                if (trophy.license.post) {
                    item.userTrophy.push({ name: '사업자/면허', icon: 'icon-6', value: trophy.license.value })
                }
                if (trophy.car.post) {
                    item.userTrophy.push({ name: '차량', icon: 'icon-11', value: trophy.car.value })
                }
                if (trophy.family.post) {
                    item.userTrophy.push({ name: '미혼돌싱', icon: 'icon-uniE900', value: trophy.family.value })
                }

                // item.userTrophy.push({ name: '반려', icon: 'icon-1', value: item._response._trophy.pet.post && item._response._trophy.pet.value })
                // item.userTrophy.push({ name: '봉사기부', icon: 'icon-2', value: item._response._trophy.donation.post && item._response._trophy.donation.value })
                // item.userTrophy.push({ name: '부동산', icon: 'icon-3', value: item._response._trophy.estate.post && item._response._trophy.estate.value   })
                // item.userTrophy.push({ name: '작년소득', icon: 'icon-4', value: item._response._trophy.income.post && item._response._trophy.income.value   })
                // item.userTrophy.push({ name: '수상장학', icon: 'icon-5', value: item._response._trophy.award.post && item._response._trophy.award.value   })
                // item.userTrophy.push({ name: '세금납부',icon: 'icon-6', value: item._response._trophy.tax.post && item._response._trophy.tax.value   })
                // item.userTrophy.push({ name: '재직재학', icon: 'icon-7', value: item._response._trophy.work.post && item._response._trophy.work.value  })
                // item.userTrophy.push({ name: '작년도매출', icon: 'icon-8', value: item._response._trophy.sales.post && item._response._trophy.sales.value   })
                // item.userTrophy.push({ name: '졸업학위', icon: 'icon-9', value: item._response._trophy.graduation.post && item._response._trophy.graduation.value  })
                // item.userTrophy.push({ name: '4대보험', icon: 'icon-10', value: item._response._trophy.license.post && item._response._trophy.license.value  })
                // item.userTrophy.push({ name: '차량', icon: 'icon-11', value: item._response._trophy.car.post && item._response._trophy.car.value  })
                // item.userTrophy.push({ name: '미혼돌싱', icon: 'icon-uniE900', value: item._response._trophy.family.post && item._response._trophy.family.value  })
            }
            return item
        })

        this.setState({ todayRecommends: setTodayRecommends })
    }

    render() {
        const {
            matchRequestClick,
            state: {
                todayRecommends,
                photoModal,
            }
        } = this

        if (!todayRecommends) {
            return (
                <div> 추천상대 불러오는 중... </div>
            )
        }

        const now = moment()

        return (
            <div className="row mt-2">
                {
                    todayRecommends.map((item, index) => {
                        return (
                            <div className="col-md-4 mb-3" key={index}>
                                <div className="card border-radius-0">
                                    <div id={`carouselExampleIndicators${index}`} className="carousel slide" data-ride="carousel">
                                        <ol className="carousel-indicators">
                                            {item.photos.map((item, index) => {
                                                let classObj = ""
                                                if (index === 0) {
                                                    classObj += 'active'
                                                }
                                                return (
                                                    <li data-target={`#carouselExampleIndicators${index}`} data-slide-to={index} className={classObj} key={index} ></li>
                                                )
                                            })}
                                        </ol>
                                        <div className="carousel-inner">
                                            {
                                                item.photos.map((item, index) => {
                                                    let classStyle = "carousel-item profile-carousel-item"
                                                    if (index === 0) {
                                                        classStyle += " active"
                                                    }
                                                    let style = {
                                                        backgroundImage: `url('${Config.photoUrl}${item}')`,
                                                        backgroundSize: 'cover',
                                                        backgroundPosition: 'center'
                                                    }

                                                    return (
                                                        <div className={classStyle} style={style} key={index}></div>
                                                    )
                                                })
                                            }


                                        </div>
                                        <a className="carousel-control-prev" href={`#carouselExampleIndicators${index}`} role="button" data-slide="prev">
                                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span className="sr-only">Previous</span>
                                        </a>
                                        <a className="carousel-control-next" href={`#carouselExampleIndicators${index}`} role="button" data-slide="next">
                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span className="sr-only">Next</span>
                                        </a>
                                    </div>
                                    <div className="card-body">
                                        <div className="">
                                            <span className="badge badge-danger mr-1">매칭확률 : {item.rate}%</span>
                                            {
                                                item.userBasic.map((item, index) => {
                                                    return (
                                                        <span className="mr-2 text-danger" key={index}>#{item.value}</span>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div>
                                            <div>
                                                <small className="text-secondary">공개정보</small>
                                            </div>
                                            {
                                                item.userProfile.length === 0 ? <div className="text-sm text-secondary">공개정보 없음</div> :
                                                    item.userProfile.map((item, index) => {
                                                        return (
                                                            <span className="badge badge-info mr-2" key={index}>{item.name}: {!item.value ? '비공개' : item.value}</span>
                                                        )
                                                    })
                                            }
                                        </div>
                                        <div>
                                            <div>
                                                <small className="text-secondary">트로피정보</small>
                                            </div>
                                            <div style={{ margin: '0 -7px' }}>
                                                {
                                                    item.userTrophy.length === 0 ?
                                                        <div className="mx-2 text-sm text-secondary">트로피정보 없음</div> :
                                                        <div className="mx-2">
                                                            {
                                                                item.userTrophy.map((item, index) => {
                                                                    let color = ''
                                                                    if (item.value === '금') {
                                                                        color = 'text-warning'
                                                                    } else if (item.value === '은') {
                                                                        color = 'text-secondary'
                                                                    } else if (item.value === '동') {
                                                                        color = 'text-bronze'
                                                                    } else {
                                                                        color = 'text-danger'
                                                                    }

                                                                    if (!item.value || item.value === '없음' || item.value === '미등록') {
                                                                        return false
                                                                    }

                                                                    if (item.value === '개') item.value = '반려견'
                                                                    if (item.value === '고양이') item.value = '반려묘'

                                                                    let label = ''
                                                                    if (item.name == '반려') {
                                                                        label = item.value
                                                                    } else if (item.name === '미혼돌싱') {
                                                                        label = item.value
                                                                    } else {
                                                                        label = item.name
                                                                    }

                                                                    return (
                                                                        <div className="d-inline-block text-center mx-2" key={index}>
                                                                            <div>
                                                                                <span className={`trophy ${item.icon} fs2 ${color}`}></span>
                                                                            </div>
                                                                            <div>
                                                                                <small>{label}</small>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="text-center my-3">
                                            {matchButton(now, item, matchRequestClick)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                {/* <div className="mb-5 mx-2">
                <button className="btn btn-block btn-primary" onClick={requestClick}>더보여줘 요청</button>
            </div> */}
                {photoModal}
            </div>

        )
    }
}

export function matchButton(now, recommend, matchRequestClick) {
    if (recommend._match.status !== 'success' && recommend._match.status !== 'fail') {
        if (recommend.status === 'success') {
            if (recommend._match.status === 'wait') {
                return <button className="btn btn-info" style={{ width: '200px' }} data-reco={recommend._id} data-match={recommend._match._id} data-user={recommend._response._id}>약속대기</button>
            } else {
                return <button className="btn btn-success" style={{ width: '200px' }} data-reco={recommend._id} data-match={recommend._match._id} data-user={recommend._response._id}>이어주는중</button>
            }
        } else if (recommend.status === 'fail') {
            return <button className="btn btn-secondary" style={{ width: '200px' }} data-reco={recommend._id} data-match={recommend._match._id} data-user={recommend._response._id}>매칭실패</button>
        } else if (recommend._match.showExpireAt && now <= moment(recommend._match.showExpireAt)) {
            return <button className="btn btn-danger" style={{ width: '200px' }} data-reco={recommend._id} data-match={recommend._match._id} data-user={recommend._response._id} data-photo={recommend._response._photos._main} data-grade={recommend._response.grade} onClick={(e) => matchRequestClick(e)}>이어줘</button>
        } else {
            return <button className="btn btn-secondary" style={{ width: '200px' }} data-reco={recommend._id} data-match={recommend._match._id} data-user={recommend._response._id}>매칭시간만료됨</button>
        }
    } else if (recommend._match.status === 'success') {
        if (recommend.status === 'success') {
            return <button className="btn btn-primary" style={{ width: '200px' }} data-reco={recommend._id} data-match={recommend._match._id} data-user={recommend._response._id}>매칭성공</button>
        } else if (recommend.status === 'fail') {
            return <button className="btn btn-secondary" style={{ width: '200px' }} data-reco={recommend._id} data-match={recommend._match._id} data-user={recommend._response._id}>매칭실패</button>
        }
    } else {
        return <button className="btn btn-secondary" style={{ width: '200px' }} data-reco={recommend._id} data-match={recommend._match._id} data-user={recommend._response._id}>매칭실패</button>
    }
}

export default withRouter(RecommendMainCard)
