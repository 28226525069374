const Goods = {

    create: (free = 0, paid = 0, price = 0) => {
        return {
            free: free ? Number(free) : 0,
            paid: paid ? Number(paid) : 0,
            price: price ? Number(price) : 0
        }
    },

    createFromObj: obj => {
        return {
            free: Goods.free(obj),
            paid: Goods.paid(obj),
            price: Goods.price(obj)
        }
    },

    setFree: (goods, value) => {
        if (goods)
            goods.free = Number(value)
    },

    free: goods => {
        return goods && goods.free ? Number(goods.free) : 0
    },

    setPaid: (goods, value) => {
        if (goods)
            goods.paid = Number(value)
    },

    paid: goods => {
        return goods && goods.paid ? Number(goods.paid) : 0
    },

    setPrice: (goods, value) => {
        if (goods)
            goods.price = Number(value)
    },

    price: goods => {
        return goods && goods.price ? Number(goods.price) : 0
    },

    add: (a, b) => {
        return {
            free: Goods.free(a) + Goods.free(b),
            paid: Goods.paid(a) + Goods.paid(b),
            price: Goods.price(a) + Goods.price(b)
        }
    },

    subtract: (a, b) => {
        return {
            free: Goods.free(a) - Goods.free(b),
            paid: Goods.paid(a) - Goods.paid(b),
            price: Goods.price(a) - Goods.price(b)
        }
    },

    multiply: (a, b) => {
        return {
            free: Goods.free(a) * Goods.free(b),
            paid: Goods.paid(a) * Goods.paid(b),
            price: Goods.price(a) * Goods.price(b)
        }
    },

    divide: (a, b) => {
        const b_free = Goods.free(b)
        const b_paid = Goods.paid(b)
        const b_price = Goods.price(b)
        return {
            free: b_free ? Goods.free(a) / b_free : 0,
            paid: b_paid ? Goods.paid(a) / b_paid : 0,
            price: b_price ? Goods.price(a) / b_price : 0
        }
    },

    equals: (a, b) => {
        return Goods.free(a) == Goods.free(b) &&
            Goods.paid(a) == Goods.paid(b) &&
            Goods.price(a) == Goods.price(b)
    },

    count: goods => {
        return Goods.free(goods) + Goods.paid(goods)
    },

    averagePrice: goods => {
        const price = Goods.price(goods)
        const paid = Goods.paid(goods)
        return price && paid ? price / paid : 0
    },

    gain: (goods, free, paid, price) => {
        if (goods) {
            goods.free += free
            goods.paid += paid
            goods.price += price
        }
    },

    spent: (goods, count = 1) => {
        if (goods) {
            if (0 < goods.paid) {
                if (count < goods.paid) {
                    goods.price -= Goods.averagePrice(goods) * count
                    goods.paid -= count
                    return 0
                }
                count -= goods.paid
                goods.price = 0
                goods.paid = 0
            }

            if (0 < goods.free) {
                if (count < goods.free) {
                    goods.free -= count
                    return 0
                }
                count -= goods.free
                goods.free = 0
            }
        }

        return count
    },

    toString: goods => {
        return goods ? `{'free': ${Goods.free(goods)}, 'paid': ${Goods.paid(goods)}, 'price': ${Goods.price(goods)}}` : null
    },
}

export default Goods