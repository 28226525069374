import React, { Component } from 'react'
import PropTypes from 'prop-types'
import BootstrapSwitchButton from 'bootstrap-switch-button-react';

export class SignupStep3 extends Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        onClickMoveTab: PropTypes.func.isRequired,
        data: PropTypes.shape({
            height: PropTypes.number.isRequired,
            heightVisible: PropTypes.bool.isRequired,
            body: PropTypes.string.isRequired,
            bodyVisible: PropTypes.bool.isRequired,
            personality: PropTypes.array.isRequired,
            personalityVisible: PropTypes.bool.isRequired,
            education: PropTypes.string.isRequired,
            educationVisible: PropTypes.bool.isRequired,
            job: PropTypes.string.isRequired,
            jobVisible: PropTypes.bool.isRequired,
            insurance: PropTypes.string.isRequired,
            insuranceVisible: PropTypes.bool.isRequired,
            income: PropTypes.number.isRequired,
            incomeVisible: PropTypes.bool.isRequired,
            car: PropTypes.string.isRequired,
            carVisible: PropTypes.bool.isRequired,
            estate: PropTypes.number.isRequired,
            estateVisible: PropTypes.bool.isRequired,
            values: PropTypes.string.isRequired,
            advantage: PropTypes.string.isRequired,
            idealType: PropTypes.string.isRequired,
            want: PropTypes.string.isRequired,
            talkTo: PropTypes.string.isRequired,
            warningMessage: PropTypes.string.isRequired
        })
    }

    render() {
        const {
            props: {
                onChange,
                onClickMoveTab,
                onVisibleChange,
                onChangePersonality,
                onChnageAdventage,
                onChnageIdealType,
                onChnageWant,
                data: {
                    height,
                    heightVisible,
                    body,
                    bodyVisible,
                    personality,
                    personalityVisible,
                    education,
                    educationVisible,
                    job,
                    jobVisible,
                    insurance,
                    insuranceVisible,
                    income,
                    incomeVisible,
                    car,
                    carVisible,
                    estate,
                    estateVisible,
                    values,
                    advantage,
                    idealType,
                    want,
                    talkTo,
                    warningMessage
                }
            }
        } = this

        return (
            <div className="card border-radius-0 mb-3">
                <div className="card-body">
                    <p className="text-red small">* 어필 정보를 노출 시 매칭 확률은 높아집니다.</p>
                    <div className="d-flex float-inputs">
                        <div className={!height ? "form-group floating-form-group w-100" : "form-group floating-form-group w-100 active"}>
                            <select className="form-control floating-input" name="height" defaultValue={height} onChange={onChange}>
                                <option value={0}>선택</option>
                                <option value={1}>155 미만</option>
                                <option value={2}>155 ~ 159</option>
                                <option value={3}>160 ~ 164</option>
                                <option value={4}>165 ~ 169</option>
                                <option value={5}>170 ~ 174</option>
                                <option value={6}>175 ~ 179</option>
                                <option value={7}>180 ~ 184</option>
                                <option value={8}>185 ~ 189</option>
                                <option value={9}>190 ~ 194</option>
                                <option value={10}>195 이상</option>
                            </select>
                            <label className="floating-label">키</label>
                        </div>
                        <BootstrapSwitchButton checked={heightVisible} onChange={(e) => onVisibleChange(e, 'heightVisible')} size="md" width={100} onlabel="노출" offlabel="미노출" onstyle="primary" offstyle="danger" />
                    </div>
                    <div className="d-flex float-inputs">
                        <div className={!body ? "form-group floating-form-group w-100" : "form-group floating-form-group w-100 active"}>
                            <select className="form-control floating-input" name="body" defaultValue={body} onChange={onChange}>
                                <option value="" disabled={true}>선택</option>
                                <option value="마른">마른</option>
                                <option value="보통">보통</option>
                                <option value="통통 ">통통</option>
                            </select>
                            <label className="floating-label">체형</label>
                        </div>
                        <BootstrapSwitchButton checked={bodyVisible} onChange={(e) => onVisibleChange(e, 'bodyVisible')} size="md" width={100} onlabel="노출" offlabel="미노출" onstyle="primary" offstyle="danger" />
                    </div>
                    <div>
                        <div className="d-flex float-inputs">
                            <div className={!personality ? "form-group floating-form-group w-100" : "form-group floating-form-group w-100 active"}>
                                {/* <select className="form-control floating-input" name="personality" defaultValue={personality} onChange={onChange}>
                                <option value="" disabled={true}>선택</option>
                                <option value="내향적인">내향적인</option>
                                <option value="외향적인">외향적인</option>
                            </select> */}
                                <div>
                                    <label style={{ color: '#999999' }}>성격</label>
                                </div>
                            </div>
                            <BootstrapSwitchButton checked={personalityVisible} onChange={(e) => onVisibleChange(e, 'personalityVisible')} size="md" width={100} onlabel="노출" offlabel="미노출" onstyle="primary" offstyle="danger" />
                        </div>
                        <div className="row mt-3">
                            <div className="col-4 mb-2">
                                <input id="personality1" type="checkbox" name="personality" data-value="낙천적인" onChange={onChangePersonality} checked={personality.some(item => item === '낙천적인')} />
                                <label for="personality1">낙천적인</label>
                            </div>
                            <div className="col-4 mb-2">
                                <input id="personality2" type="checkbox" name="personality" data-value="도발적인" onChange={onChangePersonality} checked={personality.some(item => item === '도발적인')} />
                                <label for="personality2">도발적인</label>
                            </div>
                            <div className="col-4 mb-2">
                                <input id="personality3" type="checkbox" name="personality" data-value="털털한" onChange={onChangePersonality} checked={personality.some(item => item === '털털한')} />
                                <label for="personality3">털털한</label>
                            </div>
                            <div className="col-4 mb-2">
                                <input id="personality4" type="checkbox" name="personality" data-value="단순한" onChange={onChangePersonality} checked={personality.some(item => item === '단순한')} />
                                <label for="personality4">단순한</label>
                            </div>
                            <div className="col-4 mb-2">
                                <input id="personality5" type="checkbox" name="personality" data-value="정직한" onChange={onChangePersonality} checked={personality.some(item => item === '정직한')} />
                                <label for="personality5">정직한</label>
                            </div>
                            <div className="col-4 mb-2">
                                <input id="personality6" type="checkbox" name="personality" data-value="친절한" onChange={onChangePersonality} checked={personality.some(item => item === '친절한')} />
                                <label for="personality6">친절한</label>
                            </div>
                            <div className="col-4 mb-2">
                                <input id="personality7" type="checkbox" name="personality" data-value="주도적인" onChange={onChangePersonality} checked={personality.some(item => item === '주도적인')} />
                                <label for="personality7">주도적인</label>
                            </div>
                            <div className="col-4 mb-2">
                                <input id="personality8" type="checkbox" name="personality" data-value="도도한" onChange={onChangePersonality} checked={personality.some(item => item === '도도한')} />
                                <label for="personality8">도도한</label>
                            </div>
                            <div className="col-4 mb-2">
                                <input id="personality9" type="checkbox" name="personality" data-value="애교있는" onChange={onChangePersonality} checked={personality.some(item => item === '애교있는')} />
                                <label for="personality9">애교있는</label>
                            </div>
                            <div className="col-4 mb-2">
                                <input id="personality10" type="checkbox" name="personality" data-value="경청하는" onChange={onChangePersonality} checked={personality.some(item => item === '경청하는')} />
                                <label for="personality10">경청하는</label>
                            </div>
                            <div className="col-4 mb-2">
                                <input id="personality11" type="checkbox" name="personality" data-value="중간입장" onChange={onChangePersonality} checked={personality.some(item => item === '중간입장')} />
                                <label for="personality11">중간입장</label>
                            </div>
                            <div className="col-4 mb-2">
                                <input id="personality12" type="checkbox" name="personality" data-value="조용한" onChange={onChangePersonality} checked={personality.some(item => item === '조용한')} />
                                <label for="personality12">조용한</label>
                            </div>
                            <div className="col-4 mb-2">
                                <input id="personality13" type="checkbox" name="personality" data-value="호기심 많은" onChange={onChangePersonality} checked={personality.some(item => item === '호기심 많은')} />
                                <label for="personality13">호기심 많은</label>
                            </div>
                            <div className="col-4 mb-2">
                                <input id="personality14" type="checkbox" name="personality" data-value="리액션한" onChange={onChangePersonality} checked={personality.some(item => item === '리액션한')} />
                                <label for="personality14">리액션한</label>
                            </div>
                            <div className="col-4 mb-2">
                                <input id="personality15" type="checkbox" name="personality" data-value="꼼꼼한" onChange={onChangePersonality} checked={personality.some(item => item === '꼼꼼한')} />
                                <label for="personality15">꼼꼼한</label>
                            </div>
                            <div className="col-4 mb-2">
                                <input id="personality16" type="checkbox" name="personality" data-value="서포터즈" onChange={onChangePersonality} checked={personality.some(item => item === '서포터즈')} />
                                <label for="personality16">서포터즈</label>
                            </div>
                            <div className="col-4 mb-2">
                                <input id="personality17" type="checkbox" name="personality" data-value="플렉스한" onChange={onChangePersonality} checked={personality.some(item => item === '플렉스한')} />
                                <label for="personality17">플렉스한</label>
                            </div>
                            <div className="col-4 mb-2">
                                <input id="personality18" type="checkbox" name="personality" data-value="끈기있는" onChange={onChangePersonality} checked={personality.some(item => item === '끈기있는')} />
                                <label for="personality18">끈기있는</label>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex float-inputs">
                        <div className={!education ? "form-group floating-form-group w-100" : "form-group floating-form-group w-100 active"}>
                            {/* <input type="text" className="form-control floating-input" name="education" defaultValue={education} onChange={onChange} /> */}
                            <select className="form-control floating-input" name="education" defaultValue={education} onChange={onChange}>
                                <option value="" disabled={true}>선택</option>
                                <option value="고등학교졸">고등학교졸</option>
                                <option value="2년제졸">전문대학교졸</option>
                                <option value="4년제졸">대학교졸</option>
                                <option value="대학원졸">대학원졸</option>
                                <option value="대학(원)재학">대학(원)재학</option>
                                <option value="해외대학졸">해외대학(원)졸</option>
                            </select>
                            <label className="floating-label">최종학력</label>
                        </div>
                        <BootstrapSwitchButton checked={educationVisible} onChange={(e) => onVisibleChange(e, 'educationVisible')} size="md" width={100} onlabel="노출" offlabel="미노출" onstyle="primary" offstyle="danger" />
                    </div>
                    <div className="d-flex float-inputs">
                        <div className={!job ? "form-group floating-form-group w-100" : "form-group floating-form-group w-100 active"}>
                            <input type="text" className="form-control floating-input" name="job" defaultValue={job} onChange={onChange} />
                            <label className="floating-label">직업(상세하게 작성해주세요.)</label>
                            <p className="small">작성된 직업은 검토 후 카테고리로 이동됩니다</p>
                        </div>
                        <BootstrapSwitchButton checked={jobVisible} onChange={(e) => onVisibleChange(e, 'jobVisible')} size="md" width={100} onlabel="노출" offlabel="미노출" onstyle="primary" offstyle="danger" />
                    </div>
                    <div className="d-flex float-inputs">
                        <div className={!insurance ? "form-group floating-form-group w-100" : "form-group floating-form-group w-100 active"}>
                            <select className="form-control floating-input" name="insurance" defaultValue={insurance} onChange={onChange}>
                                <option value="" disabled={true}>선택</option>
                                <option value="직장가입자">직장가입자</option>
                                <option value="지역가입자">지역가입자</option>
                                <option value="미가입자">미가입자</option>
                            </select>
                            <label className="floating-label">4대보험</label>
                        </div>
                        <BootstrapSwitchButton checked={insuranceVisible} onChange={(e) => onVisibleChange(e, 'insuranceVisible')} size="md" width={100} onlabel="노출" offlabel="미노출" onstyle="primary" offstyle="danger" />
                    </div>
                    <div className="d-flex float-inputs">
                        <div className={!income ? "form-group floating-form-group w-100" : "form-group floating-form-group w-100 active"}>
                            <select className="form-control floating-input" name="income" defaultValue={income} onChange={onChange}>
                                <option value="0">선택</option>
                                <option value="1">0 ~ 2000만원</option>
                                <option value="2">2000 ~ 4000만원</option>
                                <option value="3">4000 ~ 6000만원</option>
                                <option value="4">6000 ~ 8000만원</option>
                                <option value="5">8000만원 ~ 1억원</option>
                                <option value="6">1억원 이상</option>
                            </select>
                            <label className="floating-label">작년소득</label>
                        </div>
                        <BootstrapSwitchButton checked={incomeVisible} onChange={(e) => onVisibleChange(e, 'incomeVisible')} size="md" width={100} onlabel="노출" offlabel="미노출" onstyle="primary" offstyle="danger" />
                    </div>
                    <div className="d-flex float-inputs">
                        <div className={car === "none" ? "form-group floating-form-group w-100" : "form-group floating-form-group w-100 active"}>
                            <select className="form-control floating-input" name="car" defaultValue={car} onChange={onChange}>
                                <option value="none">선택</option>
                                <option value="true">있음</option>
                                <option value="false">없음</option>
                            </select>
                            <label className="floating-label">차량소유여부</label>
                        </div>
                        <BootstrapSwitchButton checked={carVisible} onChange={(e) => onVisibleChange(e, 'carVisible')} size="md" width={100} onlabel="노출" offlabel="미노출" onstyle="primary" offstyle="danger" />
                    </div>
                    <div className="d-flex float-inputs">
                        <div className={!estate ? "form-group floating-form-group w-100" : "form-group floating-form-group w-100 active"}>
                            <select className="form-control floating-input" name="estate" defaultValue={estate} onChange={onChange}>
                                <option value="0">선택</option>
                                <option value="1">0 ~ 1 억원</option>
                                <option value="2">1 ~ 3 억원</option>
                                <option value="3">3 ~ 6 억원</option>
                                <option value="4">6 ~ 10 억원</option>
                                <option value="5">10 ~ 20 억원</option>
                                <option value="6">20 억원 이상</option>
                            </select>
                            <label className="floating-label">종합부동산(전/월세 포함)</label>
                        </div>
                        <BootstrapSwitchButton checked={estateVisible} onChange={(e) => onVisibleChange(e, 'estateVisible')} size="md" width={100} onlabel="노출" offlabel="미노출" onstyle="primary" offstyle="danger" />
                    </div>
                    <div className={!advantage ? "form-group floating-form-group w-100" : "form-group floating-form-group w-100 active"}>
                        {/* <textarea className="form-control floating-input" name="values" defaultValue={values} onChange={onChange} cols="5" rows="2" placeholder="200자 이내" />
                        <label className="floating-label">저의 성격과 잘하는 것은요 (매니저 참고용)</label> */}
                        <div>
                            <label style={{ color: '#999999' }}>매력포인트 (매니저 참고용)</label>
                        </div>
                        <div className="row mt-3">
                            <div className="col-6 mb-2">
                                <input id="advantage1" type="checkbox" name="advantage" data-value="분위기 메이커" onChange={onChnageAdventage} checked={advantage.some(item => item === '분위기 메이커')} />
                                <label for="advantage1">분위기 메이커</label>
                            </div>
                            <div className="col-6 mb-2">
                                <input id="advantage2" type="checkbox" name="advantage" data-value="뛰어난 커리어" onChange={onChnageAdventage} checked={advantage.some(item => item === '뛰어난 커리어')} />
                                <label for="advantage2">뛰어난 커리어</label>
                            </div>
                            <div className="col-6 mb-2">
                                <input id="advantage3" type="checkbox" name="advantage" data-value="허세 없어요" onChange={onChnageAdventage} checked={advantage.some(item => item === '허세 없어요')} />
                                <label for="advantage3">허세 없어요</label>

                            </div>
                            <div className="col-6 mb-2">
                                <input id="advantage4" type="checkbox" name="advantage" data-value="손이 예뻐요" onChange={onChnageAdventage} checked={advantage.some(item => item === '손이 예뻐요')} />
                                <label for="advantage4">손이 예뻐요</label>

                            </div>
                            <div className="col-6 mb-2">
                                <input id="advantage5" type="checkbox" name="advantage" data-value="뭐든 잘 먹어요" onChange={onChnageAdventage} checked={advantage.some(item => item === '뭐든 잘 먹어요')} />
                                <label for="advantage5">뭐든 잘 먹어요</label>
                            </div>
                            <div className="col-6 mb-2">
                                <input id="advantage6" type="checkbox" name="advantage" data-value="요리를 잘해요" onChange={onChnageAdventage} checked={advantage.some(item => item === '요리를 잘해요')} />
                                <label for="advantage6">요리를 잘해요</label>
                            </div>
                            <div className="col-6 mb-2">
                                <input id="advantage7" type="checkbox" name="advantage" data-value="잘 듣는 편이에요" onChange={onChnageAdventage} checked={advantage.some(item => item === '잘 듣는 편이에요')} />
                                <label for="advantage7">잘 듣는 편이에요</label>
                            </div>
                            <div className="col-6 mb-2">
                                <input id="advantage8" type="checkbox" name="advantage" data-value="운동 감각" onChange={onChnageAdventage} checked={advantage.some(item => item === '운동 감각')} />
                                <label for="advantage8">운동 감각</label>
                            </div>
                            <div className="col-6 mb-2">
                                <input id="advantage9" type="checkbox" name="advantage" data-value="넓은 어깨" onChange={onChnageAdventage} checked={advantage.some(item => item === '넓은 어깨')} />
                                <label for="advantage9">넓은 어깨</label>
                            </div>
                            <div className="col-6 mb-2">
                                <input id="advantage10" type="checkbox" name="advantage" data-value="주도적인" onChange={onChnageAdventage} checked={advantage.some(item => item === '주도적인')} />
                                <label for="advantage10">주도적인</label>
                            </div>
                            <div className="col-6 mb-2">
                                <input id="advantage11" type="checkbox" name="advantage" data-value="도도한" onChange={onChnageAdventage} checked={advantage.some(item => item === '도도한')} />
                                <label for="advantage11">도도한</label>
                            </div>
                            <div className="col-6 mb-2">
                                <input id="advantage12" type="checkbox" name="advantage" data-value="애교있는" onChange={onChnageAdventage} checked={advantage.some(item => item === '애교있는')} />
                                <label for="advantage12">애교있는</label>
                            </div>
                            <div className="col-6 mb-2">
                                <input id="advantage13" type="checkbox" name="advantage" data-value="장난기가 많아요" onChange={onChnageAdventage} checked={advantage.some(item => item === '장난기가 많아요')} />
                                <label for="advantage13">장난기가 많아요</label>
                            </div>
                            <div className="col-6 mb-2">
                                <input id="advantage14" type="checkbox" name="advantage" data-value="섬세해요" onChange={onChnageAdventage} checked={advantage.some(item => item === '섬세해요')} />
                                <label for="advantage14">섬세해요</label>
                            </div>
                            <div className="col-6 mb-2">
                                <input id="advantage15" type="checkbox" name="advantage" data-value="보조개" onChange={onChnageAdventage} checked={advantage.some(item => item === '보조개')} />
                                <label for="advantage15">보조개</label>
                            </div>
                            <div className="col-6 mb-2">
                                <input id="advantage16" type="checkbox" name="advantage" data-value="엉덩이가 예뻐요" onChange={onChnageAdventage} checked={advantage.some(item => item === '엉덩이가 예뻐요')} />
                                <label for="advantage16">엉덩이가 예뻐요</label>
                            </div>
                            <div className="col-6 mb-2">
                                <input id="advantage17" type="checkbox" name="advantage" data-value="비율이 좋아요" onChange={onChnageAdventage} checked={advantage.some(item => item === '비율이 좋아요')} />
                                <label for="advantage17">비율이 좋아요</label>
                            </div>
                            <div className="col-6 mb-2">
                                <input id="advantage18" type="checkbox" name="advantage" data-value="큰 눈" onChange={onChnageAdventage} checked={advantage.some(item => item === '큰 눈')} />
                                <label for="advantage18">큰 눈</label>
                            </div>
                            <div className="col-6 mb-2">
                                <input id="advantage19" type="checkbox" name="advantage" data-value="워커홀릭" onChange={onChnageAdventage} checked={advantage.some(item => item === '워커홀릭')} />
                                <label for="advantage19">워커홀릭</label>
                            </div>
                            <div className="col-6 mb-2">
                                <input id="advantage20" type="checkbox" name="advantage" data-value="짙은 눈썹" onChange={onChnageAdventage} checked={advantage.some(item => item === '짙은 눈썹')} />
                                <label for="advantage20">짙은 눈썹</label>
                            </div>
                            <div className="col-6 mb-2">
                                <input id="advantage21" type="checkbox" name="advantage" data-value="솔직해요" onChange={onChnageAdventage} checked={advantage.some(item => item === '솔직해요')} />
                                <label for="advantage21">솔직해요</label>
                            </div>
                            <div className="col-6 mb-2">
                                <input id="advantage22" type="checkbox" name="advantage" data-value="유머감각" onChange={onChnageAdventage} checked={advantage.some(item => item === '유머감각')} />
                                <label for="advantage22">유머감각</label>
                            </div>
                            <div className="col-6 mb-2">
                                <input id="advantage23" type="checkbox" name="advantage" data-value="경제력" onChange={onChnageAdventage} checked={advantage.some(item => item === '경제력')} />
                                <label for="advantage23" >경제력</label>
                            </div>
                            <div className="col-6 mb-2">
                                <input id="advantage24" type="checkbox" name="advantage" data-value="오똑한 콧날" onChange={onChnageAdventage} checked={advantage.some(item => item === '오똑한 콧날')} />
                                <label for="advantage24">오똑한 콧날</label>
                            </div>
                            <div className="col-6 mb-2">
                                <input id="advantage25" type="checkbox" name="advantage" data-value="쌍커풀 없는 눈" onChange={onChnageAdventage} checked={advantage.some(item => item === '쌍커풀 없는 눈')} />
                                <label for="advantage25">쌍커풀 없는 눈</label>
                            </div>
                            <div className="col-6 mb-2">
                                <input id="advantage26" type="checkbox" name="advantage" data-value="애교가 많아요" onChange={onChnageAdventage} checked={advantage.some(item => item === '애교가 많아요')} />
                                <label for="advantage26">애교가 많아요</label>
                            </div>
                            <div className="col-6 mb-2">
                                <input id="advantage27" type="checkbox" name="advantage" data-value="대화를 잘해요" onChange={onChnageAdventage} checked={advantage.some(item => item === '대화를 잘해요')} />
                                <label for="advantage27">대화를 잘해요</label>
                            </div>
                            <div className="col-6 mb-2">
                                <input id="advantage28" type="checkbox" name="advantage" data-value="배려심이 깊어요" onChange={onChnageAdventage} checked={advantage.some(item => item === '배려심이 깊어요')} />
                                <label for="advantage28">배려심이 깊어요</label>
                            </div>
                            <div className="col-6 mb-2">
                                <input id="advantage29" type="checkbox" name="advantage" data-value="표현을 잘해요" onChange={onChnageAdventage} checked={advantage.some(item => item === '표현을 잘해요')} />
                                <label for="advantage29">표현을 잘해요</label>
                            </div>
                            <div className="col-6 mb-2">
                                <input id="advantage30" type="checkbox" name="advantage" data-value="탁월한 패션감각" onChange={onChnageAdventage} checked={advantage.some(item => item === '탁월한 패션감각')} />
                                <label for="advantage30">탁월한 패션감각</label>
                            </div>
                            <div className="col-6 mb-2">
                                <input id="advantage31" type="checkbox" name="advantage" data-value="목소리가 좋아요" onChange={onChnageAdventage} checked={advantage.some(item => item === '목소리가 좋아요')} />
                                <label for="advantage31">목소리가 좋아요</label>
                            </div>
                            <div className="col-6 mb-2">
                                <input id="advantage32" type="checkbox" name="advantage" data-value="웃음이 많아요" onChange={onChnageAdventage} checked={advantage.some(item => item === '웃음이 많아요')} />
                                <label for="advantage32">웃음이 많아요</label>
                            </div>
                            <div className="col-6 mb-2">
                                <input id="advantage33" type="checkbox" name="advantage" data-value="게임을 잘해요" onChange={onChnageAdventage} checked={advantage.some(item => item === '게임을 잘해요')} />
                                <label for="advantage33">게임을 잘해요</label>
                            </div>
                            <div className="col-6 mb-2">
                                <input id="advantage34" type="checkbox" name="advantage" data-value="웃는게 예뻐요" onChange={onChnageAdventage} checked={advantage.some(item => item === '웃는게 예뻐요')} />
                                <label for="advantage34">웃는게 예뻐요</label>
                            </div>
                            <div className="col-6 mb-2">
                                <input id="advantage35" type="checkbox" name="advantage" data-value="예의가 발라요" onChange={onChnageAdventage} checked={advantage.some(item => item === '예의가 발라요')} />
                                <label for="advantage35">예의가 발라요</label>
                            </div>
                            <div className="col-6 mb-2">
                                <input id="advantage36" type="checkbox" name="advantage" data-value="다정해요" onChange={onChnageAdventage} checked={advantage.some(item => item === '다정해요')} />
                                <label for="advantage36">다정해요</label>
                            </div>
                            <div className="col-6 mb-2">
                                <input id="advantage37" type="checkbox" name="advantage" data-value="털털해요" onChange={onChnageAdventage} checked={advantage.some(item => item === '털털해요')} />
                                <label for="advantage37">털털해요</label>
                            </div>
                            <div className="col-6 mb-2">
                                <input id="advantage38" type="checkbox" name="advantage" data-value="다리가 예뻐요" onChange={onChnageAdventage} checked={advantage.some(item => item === '다리가 예뻐요')} />
                                <label for="advantage38">다리가 예뻐요</label>
                            </div>
                            <div className="col-6 mb-2">
                                <input id="advantage39" type="checkbox" name="advantage" data-value="섹시한 타투" onChange={onChnageAdventage} checked={advantage.some(item => item === '섹시한 타투')} />
                                <label for="advantage39">섹시한 타투</label>
                            </div>
                            <div className="col-6 mb-2">
                                <input id="advantage40" type="checkbox" name="advantage" data-value="시사에 밝아요" onChange={onChnageAdventage} checked={advantage.some(item => item === '시사에 밝아요')} />
                                <label for="advantage40">시사에 밝아요</label>
                            </div>
                        </div>
                    </div>
                    {/* <div className={!advantage ? "form-group floating-form-group w-100" : "form-group floating-form-group w-100 active"}>
                        <textarea className="form-control floating-input" name="advantage" defaultValue={advantage} onChange={onChange} cols="5" rows="2" placeholder="200자 이내" />
                        <label className="floating-label">제가 잘하는 것은요 (매니저 참고용)</label>
                    </div> */}
                    <div className={!idealType ? "form-group floating-form-group w-100" : "form-group floating-form-group w-100 active"}>
                        {/* <textarea className="form-control floating-input" name="idealType" defaultValue={idealType} onChange={onChange} cols="5" rows="2" placeholder="200자 이내" />
                        <label className="floating-label">저의 이상형은요 (매니저 참고용)</label> */}

                        <div>
                            <label style={{ color: '#999999' }}>이상형 (매니저 참고용)</label>
                        </div>
                        <div className="row mt-3">
                            <div className="mb-2 col-6">
                                <input id="idealType1" type="checkbox" name="idealType" data-value="유머러스한 사람" onChange={onChnageIdealType} checked={idealType.some(item => item === '유머러스한 사람')} />
                                <label for="idealType1">유머러스한 사람</label>
                            </div>
                            <div className="mb-2 col-6">
                                <input id="idealType2" type="checkbox" name="idealType" data-value="대화가 많은 사람" onChange={onChnageIdealType} checked={idealType.some(item => item === '대화가 많은 사람')} />
                                <label for="idealType2">대화가 많은 사람</label>
                            </div>
                            <div className="mb-2 col-6">
                                <input id="idealType3" type="checkbox" name="idealType" data-value="배울게 많은 사람" onChange={onChnageIdealType} checked={idealType.some(item => item === '배울게 많은 사람')} />
                                <label for="idealType3">배울게 많은 사람</label>
                            </div>
                            <div className="mb-2 col-6">
                                <input id="idealType4" type="checkbox" name="idealType" data-value="귀여운 사람" onChange={onChnageIdealType} checked={idealType.some(item => item === '귀여운 사람')} />
                                <label for="idealType4">귀여운 사람</label>
                            </div>
                            <div className="mb-2 col-6">
                                <input id="idealType5" type="checkbox" name="idealType" data-value="시간여유 많은 사람" onChange={onChnageIdealType} checked={idealType.some(item => item === '시간여유 많은 사람')} />
                                <label for="idealType5">시간여유 많은 사람</label>
                            </div>
                            <div className="mb-2 col-6">
                                <input id="idealType6" type="checkbox" name="idealType" data-value="일 잘하는 사람" onChange={onChnageIdealType} checked={idealType.some(item => item === '일 잘하는 사람')} />
                                <label for="idealType6">일 잘하는 사람</label>
                            </div>
                            <div className="mb-2 col-6">
                                <input id="idealType7" type="checkbox" name="idealType" data-value="애교가 많은 사람" onChange={onChnageIdealType} checked={idealType.some(item => item === '애교가 많은 사람')} />
                                <label for="idealType7">애교가 많은 사람</label>
                            </div>
                            <div className="mb-2 col-6">
                                <input id="idealType8" type="checkbox" name="idealType" data-value="허세가 없는 사람" onChange={onChnageIdealType} checked={idealType.some(item => item === '허세가 없는 사람')} />
                                <label for="idealType8">허세가 없는 사람</label>
                            </div>
                            <div className="mb-2 col-6">
                                <input id="idealType9" type="checkbox" name="idealType" data-value="얼굴보다 마음" onChange={onChnageIdealType} checked={idealType.some(item => item === '얼굴보다 마음')} />
                                <label for="idealType9">얼굴보다 마음</label>
                            </div>
                            <div className="mb-2 col-6">
                                <input id="idealType10" type="checkbox" name="idealType" data-value="털털한 사람" onChange={onChnageIdealType} checked={idealType.some(item => item === '털털한 사람')} />
                                <label for="idealType10">털털한 사람</label>
                            </div>
                            <div className="mb-2 col-6">
                                <input id="idealType11" type="checkbox" name="idealType" data-value="예의바른 사람" onChange={onChnageIdealType} checked={idealType.some(item => item === '예의바른 사람')} />
                                <label for="idealType11">예의바른 사람</label>
                            </div>
                            <div className="mb-2 col-6">
                                <input id="idealType12" type="checkbox" name="idealType" data-value="인기가 많은 사람" onChange={onChnageIdealType} checked={idealType.some(item => item === '인기가 많은 사람')} />
                                <label for="idealType12">인기가 많은 사람</label>
                            </div>
                            <div className="mb-2 col-6">
                                <input id="idealType13" type="checkbox" name="idealType" data-value="경제력 탁월한 사람" onChange={onChnageIdealType} checked={idealType.some(item => item === '경제력 탁월한 사람')} />
                                <label for="idealType13">경제력 탁월한 사람</label>
                            </div>
                            <div className="mb-2 col-6">
                                <input id="idealType14" type="checkbox" name="idealType" data-value="진보성향인 사람" onChange={onChnageIdealType} checked={idealType.some(item => item === '진보성향인 사람')} />
                                <label for="idealType14">진보성향인 사람</label>
                            </div>
                            <div className="mb-2 col-6">
                                <input id="idealType15" type="checkbox" name="idealType" data-value="중도성향인 사람" onChange={onChnageIdealType} checked={idealType.some(item => item === '중도성향인 사람')} />
                                <label for="idealType15">중도성향인 사람</label>
                            </div>
                            <div className="mb-2 col-6">
                                <input id="idealType16" type="checkbox" name="idealType" data-value="보수성향의 사람" onChange={onChnageIdealType} checked={idealType.some(item => item === '보수성향의 사람')} />
                                <label for="idealType16">보수성향의 사람</label>
                            </div>
                        </div>
                    </div>
                    <div className={!want ? "form-group floating-form-group w-100" : "form-group floating-form-group w-100 active"}>
                        {/* <textarea className="form-control floating-input" name="want" defaultValue={want} onChange={onChange} cols="5" rows="2" placeholder="200자 이내" />
                        <label className="floating-label">연애시 가장 하고 싶은 일은요 (매니저 참고용)</label> */}
                        <div>
                            <label style={{ color: '#999999' }}>데이트 스타일 (매니저 참고용)</label>
                        </div>
                        <div className="mt-3">
                            <div className="mb-2 d-flex">
                                <div className="mr-3 col-6">
                                    <input id="want1" type="checkbox" name="want" data-value="함께 드라이브 하기" onChange={onChnageWant} checked={want.some(item => item === '함께 드라이브 하기')} />
                                    <label for="want1">함께 드라이브 하기</label>
                                </div>
                                <div className="mr-3 col-6">
                                    <input id="want2" type="checkbox" name="want" data-value="주말 데이트" onChange={onChnageWant} checked={want.some(item => item === '주말 데이트')} />
                                    <label for="want2">주말 데이트</label>
                                </div>
                            </div>
                            <div className="mb-2 d-flex">
                                <div className="mr-3 col-6">
                                    <input id="want3" type="checkbox" name="want" data-value="집 데이트" onChange={onChnageWant} checked={want.some(item => item === '집 데이트')} />
                                    <label for="want3">집 데이트</label>
                                </div>
                                <div className="mr-3 col-6">
                                    <input id="want4" type="checkbox" name="want" data-value="도시락 나들이" onChange={onChnageWant} checked={want.some(item => item === '도시락 나들이')} />
                                    <label for="want4">도시락 나들이</label>
                                </div>

                            </div>
                            <div className="mb-2 d-flex">
                                <div className="mr-3 col-6">
                                    <input id="want5" type="checkbox" name="want" data-value="함께 산책하기" onChange={onChnageWant} checked={want.some(item => item === '함께 산책하기')} />
                                    <label for="want5">함께 산책하기</label>
                                </div>
                                <div className="mr-3 col-6">
                                    <input id="want6" type="checkbox" name="want" data-value="함께 여행하기" onChange={onChnageWant} checked={want.some(item => item === '함께 여행하기')} />
                                    <label for="want6">함께 여행하기</label>
                                </div>
                            </div>
                            <div className="mb-2 d-flex">
                                <div className="mr-3 col-6">
                                    <input id="want7" type="checkbox" name="want" data-value="함께 게임하기" onChange={onChnageWant} checked={want.some(item => item === '함께 게임하기')} />
                                    <label for="want7">함께 게임하기</label>
                                </div>
                                <div className="mr-3 col-6">
                                    <input id="want8" type="checkbox" name="want" data-value="카페 데이트" onChange={onChnageWant} checked={want.some(item => item === '카페 데이트')} />
                                    <label for="want8">카페 데이트</label>
                                </div>
                            </div>
                            <div className="mb-2 d-flex">
                                <div className="mr-3 col-6">
                                    <input id="want9" type="checkbox" name="want" data-value="친구같은 편한 만남" onChange={onChnageWant} checked={want.some(item => item === '친구같은 편한 만남')} />
                                    <label for="want9">친구같은 편한 만남</label>
                                </div>
                                <div className="mr-3 col-6">
                                    <input id="want10" type="checkbox" name="want" data-value="함께 운동하기" onChange={onChnageWant} checked={want.some(item => item === '함께 운동하기')} />
                                    <label for="want10">함께 운동하기</label>
                                </div>
                            </div>
                            <div className="mb-2 d-flex">
                                <div className="mr-3 col-6">
                                    <input id="want11" type="checkbox" name="want" data-value="함께 술 마시기" onChange={onChnageWant} checked={want.some(item => item === '함께 술 마시기')} />
                                    <label for="want11">함께 술 마시기</label>
                                </div>
                                <div className="mr-3 col-6">
                                    <input id="want12" type="checkbox" name="want" data-value="함께 맛집 투어" onChange={onChnageWant} checked={want.some(item => item === '함께 맛집 투어')} />
                                    <label for="want12">함께 맛집 투어</label>
                                </div>
                            </div>
                            <div className="mb-2 d-flex">
                                <div className="mr-3 col-6">
                                    <input id="want13" type="checkbox" name="want" data-value="즉흥적인 깜짝 만남" onChange={onChnageWant} checked={want.some(item => item === '즉흥적인 깜짝 만남')} />
                                    <label for="want13">즉흥적인 깜짝 만남</label>
                                </div>
                                <div className="mr-3 col-6">
                                    <input id="want14" type="checkbox" name="want" data-value="꾸준히 연락하기" onChange={onChnageWant} checked={want.some(item => item === '꾸준히 연락하기')} />
                                    <label for="want14">꾸준히 연락하기</label>
                                </div>
                            </div>
                            <div className="mb-2 d-flex">
                                <div className="mr-3 col-6">
                                    <input id="want15" type="checkbox" name="want" data-value="통화 자주하기" onChange={onChnageWant} checked={want.some(item => item === '통화 자주하기')} />
                                    <label for="want15">통화 자주하기</label>
                                </div>
                                <div className="mr-3 col-6">
                                    <input id="want16" type="checkbox" name="want" data-value="짧더라도 자주 만나기" onChange={onChnageWant} checked={want.some(item => item === '짧더라도 자주 만나기')} />
                                    <label for="want16">짧더라도 자주 만나기</label>
                                </div>
                            </div>
                            <div className="mb-2 d-flex">
                                <div className="mr-3 col-6">
                                    <input id="want17" type="checkbox" name="want" data-value="함께 전시회 관람" onChange={onChnageWant} checked={want.some(item => item === '함께 전시회 관람')} />
                                    <label for="want17">함께 전시회 관람</label>
                                </div>
                                <div className="mr-3 col-6">
                                    <input id="want18" type="checkbox" name="want" data-value="영화 관람" onChange={onChnageWant} checked={want.some(item => item === '영화 관람')} />
                                    <label for="want18">영화 관람</label>
                                </div>
                            </div>
                            <div className="mb-2 d-flex">
                                <div className="mr-3 col-6">
                                    <input id="want19" type="checkbox" name="want" data-value="함께 스포츠 관람" onChange={onChnageWant} checked={want.some(item => item === '함께 스포츠 관람')} />
                                    <label for="want19">함께 스포츠 관람</label>
                                </div>
                                <div className="mr-3 col-6">
                                    <input id="want20" type="checkbox" name="want" data-value="함께 공연 관람" onChange={onChnageWant} checked={want.some(item => item === '함께 공연 관람')} />
                                    <label for="want20">함께 공연 관람</label>
                                </div>
                            </div>
                            <div className="mb-2 d-flex">
                                <div className="mr-3 col-6">
                                    <input id="want21" type="checkbox" name="want" data-value="서로 사진 찍어주기" onChange={onChnageWant} checked={want.some(item => item === '서로 사진 찍어주기')} />
                                    <label for="want21">서로 사진 찍어주기</label>
                                </div>
                                <div className="mr-3 col-6">
                                    <input id="want22" type="checkbox" name="want" data-value="노래방 데이트" onChange={onChnageWant} checked={want.some(item => item === '노래방 데이트')} />
                                    <label for="want22">노래방 데이트</label>
                                </div>
                            </div>
                            <div className="mb-5 d-flex">
                                <div className="mr-3 col-6">
                                    <input id="want23" type="checkbox" name="want" data-value="취미활동 공유하기" onChange={onChnageWant} checked={want.some(item => item === '취미활동 공유하기')} />
                                    <label for="want23">취미활동 공유하기</label>
                                </div>
                                <div className="mr-3 col-6">
                                    <input id="want24" type="checkbox" name="want" data-value="발전적인 대화 나누기" onChange={onChnageWant} checked={want.some(item => item === '발전적인 대화 나누기')} />
                                    <label for="want24">발전적인 대화 나누기</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={!talkTo ? "form-group floating-form-group w-100 " : "form-group floating-form-group w-100 active"}>
                        <textarea className="form-control floating-input" name="talkTo" defaultValue={talkTo} onChange={onChange} cols="5" rows="2" placeholder="200자 이내" />
                        <label className="floating-label">매니저님 이것만은 안돼요. (매니저 참고용)</label>
                    </div>
                    <div className="mt-5 mb-3 d-flex justify-content-between">
                        <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => onClickMoveTab('기본정보')}
                        > <i className="fas fa-chevron-left"></i> 이전 </button>
                        <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => onClickMoveTab('프로필')}
                        > 다음 <i className="fas fa-chevron-right"></i> </button>
                    </div>
                    <div className="alert-label text-center mt-3 text-warning">
                        {warningMessage && warningMessage}
                    </div>
                </div>
            </div>
        )
    }
}

export default SignupStep3
