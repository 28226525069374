import React, { Component } from 'react';
import '../App.css';
import User from '../api/user.api';

class FindId extends React.Component{

    constructor(props) {
        super(props)
    
        this.state = {
            name: '',
            phone: '',
            number: '',
            checkCode: false,
            findAble: false,
            email: ''
        }

        this.getCode = this.getCode.bind(this)
    }

    getCode = async () => {
        const resGetCode = await User.getcode({
            name: this.state.name,
            phone: this.state.phone
        })

        if(!resGetCode.success){
            alert(resGetCode.message)
        } else {
            if(!this.state.checkCode) {
                this.setState({
                    checkCode: true
                })
            }
        }
    }

    OnfindId = async () => {
        const resFindId = await User.findid({
            name: this.state.name,
            phone: this.state.phone,
            number: this.state.number
        })
        if(!resFindId.success){
            
            alert(resFindId.message)
        } else {
            this.setState({
                findAble: true,
                email: resFindId.email
            })
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    onSubmit = async (e) => {
        e.preventDefault()
    }
    
    render(){
        const {
            onSubmit,
            onChange,
            getCode,
            OnfindId,
            state: {
                checkCode,
                number,
                name,
                phone,
                findAble,
                email
            }
        } = this

        let sendcodeBtn = <button className="btn btn-secondary btn-block mb-5" onClick={getCode}>인증번호 받기</button>
        let insertCodeInput = ''
        if(checkCode){
            sendcodeBtn = <button className="btn btn-secondary btn-block mb-5" onClick={getCode}>인증번호 다시 받기</button>
            insertCodeInput = <div className="form-group">
                    <label className="">인증번호</label>
                    <input type="phone" className="form-control" name="number" onChange={onChange} />
                </div>
        }
        let myEmail = ''
        if(findAble){
            myEmail = <div className="mb-5">고객님의 계정은 <strong>{email}</strong> 입니다.</div>
        }

        

        return(
            <div className="login-box py-3 mb-5" style={{marginTop:'50px'}}>
                <h5>이메일ID 찾기</h5>
                {
                    findAble ?
                    myEmail :
                    <form onSubmit={onSubmit}>
                        <div className="form-group floating-form-group">
                            <input type="name" className="form-control floating-input" name="name" onChange={onChange} />
                            <label className="floating-label">이름</label>
                        </div>
                        <div className="form-group floating-form-group">
                            <input type="phone" className="form-control floating-input" name="phone" onChange={onChange} />
                            <label className="floating-label">연락처</label>
                        </div>
                        <div>
                            {sendcodeBtn}
                            {insertCodeInput}
                        </div>
                        <div className="text-center">
                            <button className="btn btn-primary btn-block mb-5" onClick={OnfindId}>확인</button>
                        </div>
                    </form>
                }
                <div className="text-center">
                    <a href="/login" className="text-secondary">로그인하러 가기</a>
                </div>
            </div>
        );
    }
}
export default FindId;