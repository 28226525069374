import React, { Component } from 'react'
import PropTypes from 'prop-types'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'

export class ProfileForm5 extends Component {
    static propTypes = {

    }

    onTrophyCheck = (value) => {
        
    }

    render() {
        const {
            props: {
                onChange,
                trophy: {
                    pet,
                    donation,
                    estate_trophy,
                    income_trophy,
                    award,
                    tax,
                    work,
                    sales,
                    graduation,
                    license,
                    car_trophy,
                    family
                }
            }
        } = this

        return (
            <div className="card border-radius-0 mb-3">
                <div className="card-header">
                    <h6 className="mb-0">인증 트로피</h6>
                </div>
                <div className="card-body">
                    <p className="small text-secondary">미인증된 트로피항목은 마스킹 처리된  해당 서류를 인증하셔야 합니다.</p>
                    <div className="d-flex mb-2">
                        <div className="mr-3 align-self-center">
                            <span className="trophy fs2 icon-1" style={{fontSize: '24px'}}></span>
                        </div>
                        <div className="w-100 align-self-center">반려동물 : {pet.value === '없음' ? '미인증' : pet.value}</div>
                        <div>
                            <BootstrapSwitchButton 
                                disabled = {pet.value === '미인증' || pet.value === '없음' ? true : false }
                                checked={pet.post} 
                                onChange={(e) => onChange(e, 'pet', pet.value)} 
                                size="md" 
                                width={90} 
                                onlabel={pet.value === '미인증' || pet.value === '없음' ? "미인증" : "노출" } 
                                offlabel={pet.value === '미인증' || pet.value === '없음' ? "미인증" : "미노출" }  
                                onstyle={pet.value === '미인증' || pet.value === '없음' ? "secondary" : "primary" } 
                                offstyle={pet.value === '미인증' || pet.value === '없음' ? "secondary" : "danger" } />
                        </div>
                    </div> 
                    <div className="d-flex mb-2">
                        <div className="mr-3 align-self-center">
                            <span className="trophy fs2 icon-2" style={{fontSize: '24px'}}></span>
                        </div>
                        <div className="w-100 align-self-center">봉사기부 : {donation.value === '없음' ? '미인증' : donation.value}</div>
                        <div>
                            <BootstrapSwitchButton 
                                disabled = {donation.value === '미인증' || donation.value === '없음' ? true : false }
                                checked={donation.post} 
                                onChange={(e) => onChange(e, 'donation', donation.value)} 
                                size="md" width={90} 
                                onlabel={donation.value === '미인증' || donation.value === '없음' ? "미인증" : "노출" } 
                                offlabel={donation.value === '미인증' || donation.value === '없음' ? "미인증" : "미노출" }  
                                onstyle={donation.value === '미인증' || donation.value === '없음' ? "secondary" : "primary" }  
                                offstyle={donation.value === '미인증' || donation.value === '없음' ? "secondary" : "danger" }  />
                        </div>
                    </div>
                    <div className="d-flex mb-2">
                        <div className="mr-3 align-self-center">
                            <span className="trophy fs2 icon-3" style={{fontSize: '24px'}}></span>
                        </div>
                        <div className="w-100 align-self-center">부동산 : {estate_trophy.value === '없음' ? '미인증' : estate_trophy.value}</div>
                        <div>
                            <BootstrapSwitchButton 
                                disabled = {estate_trophy.value === '미인증' || estate_trophy.value === '없음' ? true : false }
                                checked={estate_trophy.post} 
                                onChange={(e) => onChange(e, 'estate', estate_trophy.value)} 
                                size="md" 
                                width={90} 
                                onlabel={estate_trophy.value === '미인증' || estate_trophy.value === '없음' ? "미인증" : "노출" } 
                                offlabel={estate_trophy.value === '미인증' || estate_trophy.value === '없음' ? "미인증" : "미노출" }  
                                onstyle={estate_trophy.value === '미인증' || estate_trophy.value === '없음' ? "secondary" : "primary" }  
                                offstyle={estate_trophy.value === '미인증' || estate_trophy.value === '없음' ? "secondary" : "danger" }  />
                        </div>
                    </div>
                    <div className="d-flex mb-2">
                        <div className="mr-3 align-self-center">
                            <span className="trophy fs2 icon-4" style={{fontSize: '24px'}}></span>
                        </div>
                        <div className="w-100 align-self-center">작년소득 : {income_trophy.value === '없음' ? '미인증' : income_trophy.value}</div>
                        <div>
                            <BootstrapSwitchButton 
                                disabled = {income_trophy.value === '미인증' || income_trophy.value === '없음' ? true : false }
                                checked={income_trophy.post} 
                                onChange={(e) => onChange(e, 'income', income_trophy.value)} 
                                size="md" 
                                width={90} 
                                onlabel={income_trophy.value === '미인증' || income_trophy.value === '없음' ? "미인증" : "노출" } 
                                offlabel={income_trophy.value === '미인증' || income_trophy.value === '없음' ? "미인증" : "미노출" }  
                                onstyle={income_trophy.value === '미인증' || income_trophy.value === '없음' ? "secondary" : "primary" }  
                                offstyle={income_trophy.value === '미인증' || income_trophy.value === '없음' ? "secondary" : "danger" }  />
                        </div>
                    </div>
                    <div className="d-flex mb-2">
                        <div className="mr-3 align-self-center">
                            <span className="trophy fs2 icon-5" style={{fontSize: '24px'}}></span>
                        </div>
                        <div className="w-100 align-self-center">수상장학 : {award.value === '없음' ? '미인증' : award.value}</div>
                        <div>
                            <BootstrapSwitchButton 
                                disabled = {award.value === '미인증' || award.value === '없음' ? true : false }
                                checked={award.post} 
                                onChange={(e) => onChange(e, 'award', award.value)} 
                                size="md" 
                                width={90} 
                                onlabel={award.value === '미인증' || award.value === '없음' ? "미인증" : "노출" } 
                                offlabel={award.value === '미인증' || award.value === '없음' ? "미인증" : "미노출" }  
                                onstyle={award.value === '미인증' || award.value === '없음' ? "secondary" : "primary" }  
                                offstyle={award.value === '미인증' || award.value === '없음' ? "secondary" : "danger" }  />
                        </div>
                    </div>
                    <div className="d-flex mb-2">
                        <div className="mr-3 align-self-center">
                            <span className="trophy fs2 icon-7" style={{fontSize: '24px'}}></span>
                        </div>
                        <div className="w-100 align-self-center">세금납부 : {tax.value === '없음' ? '미인증' : tax.value}</div>
                        <div>
                            <BootstrapSwitchButton 
                                disabled = {tax.value === '미인증' || tax.value === '없음' ? true : false }
                                checked={tax.post} 
                                onChange={(e) => onChange(e, 'tax', tax.value)} 
                                size="md" 
                                width={90} 
                                onlabel={tax.value === '미인증' || tax.value === '없음' ? "미인증" : "노출" } 
                                offlabel={tax.value === '미인증' || tax.value === '없음' ? "미인증" : "미노출" }  
                                onstyle={tax.value === '미인증' || tax.value === '없음' ? "secondary" : "primary" }  
                                offstyle={tax.value === '미인증' || tax.value === '없음' ? "secondary" : "danger" }  />
                        </div>
                    </div>
                    <div className="d-flex mb-2">
                        <div className="mr-3 align-self-center">
                            <span className="trophy fs2 icon-8" style={{fontSize: '24px'}}></span>
                        </div>
                        <div className="w-100 align-self-center">재직재학 : {work.value === '없음' ? '미인증' : work.value}</div>
                        <div>
                            <BootstrapSwitchButton 
                                disabled = {work.value === '미인증' || work.value === '없음' ? true : false }
                                checked={work.post} 
                                onChange={(e) => onChange(e, 'work', work.value)} 
                                size="md" 
                                width={90}
                                onlabel={work.value === '미인증' || work.value === '없음' ? "미인증" : "노출" } 
                                offlabel={work.value === '미인증' || work.value === '없음' ? "미인증" : "미노출" }  
                                onstyle={work.value === '미인증' || work.value === '없음' ? "secondary" : "primary" }  
                                offstyle={work.value === '미인증' || work.value === '없음' ? "secondary" : "danger" }  />
                        </div>
                    </div>
                    <div className="d-flex mb-2">
                        <div className="mr-3 align-self-center">
                            <span className="trophy fs2 icon-9" style={{fontSize: '24px'}}></span>
                        </div>
                        <div className="w-100 align-self-center">작년도매출 : {sales.value === '없음' ? '미인증' : sales.value}</div>
                        <div>
                            <BootstrapSwitchButton 
                                disabled = {sales.value === '미인증' || sales.value === '없음' ? true : false }
                                checked={sales.post} 
                                onChange={(e) => onChange(e, 'sales', sales.value)} 
                                size="md" 
                                width={90} 
                                onlabel={sales.value === '미인증' || sales.value === '없음' ? "미인증" : "노출" } 
                                offlabel={sales.value === '미인증' || sales.value === '없음' ? "미인증" : "미노출" }  
                                onstyle={sales.value === '미인증' || sales.value === '없음' ? "secondary" : "primary" }  
                                offstyle={sales.value === '미인증' || sales.value === '없음' ? "secondary" : "danger" }  />
                        </div>
                    </div>
                    <div className="d-flex mb-2">
                        <div className="mr-3 align-self-center">
                            <span className="trophy fs2 icon-10" style={{fontSize: '24px'}}></span>
                        </div>
                        <div className="w-100 align-self-center">졸업학위 : {graduation.value === '없음' ? '미인증' : graduation.value}</div>
                        <div>
                            <BootstrapSwitchButton 
                                disabled = {graduation.value === '미인증' || graduation.value === '없음' ? true : false }
                                checked={graduation.post} 
                                onChange={(e) => onChange(e, 'graduation', graduation.value)} 
                                size="md" 
                                width={90} 
                                onlabel={graduation.value === '미인증' || graduation.value === '없음' ? "미인증" : "노출" } 
                                offlabel={graduation.value === '미인증' || graduation.value === '없음' ? "미인증" : "미노출" }  
                                onstyle={graduation.value === '미인증' || graduation.value === '없음' ? "secondary" : "primary" }  
                                offstyle={graduation.value === '미인증' || graduation.value === '없음' ? "secondary" : "danger" }  />
                        </div>
                    </div>
                    <div className="d-flex mb-2">
                        <div className="mr-3 align-self-center">
                            <span className="trophy fs2 icon-6" style={{fontSize: '24px'}}></span>
                        </div>
                        <div className="w-100 align-self-center">사업자/면허 : {license.value === '없음' ? '미인증' : license.value}</div>
                        <div>
                            <BootstrapSwitchButton 
                                disabled = {license.value === '미인증' || license.value === '없음' ? true : false }
                                checked={license.post} 
                                onChange={(e) => onChange(e, 'license', license.value)} 
                                size="md" 
                                width={90} 
                                onlabel={license.value === '미인증' || license.value === '없음' ? "미인증" : "노출" } 
                                offlabel={license.value === '미인증' || license.value === '없음' ? "미인증" : "미노출" }  
                                onstyle={license.value === '미인증' || license.value === '없음' ? "secondary" : "primary" }  
                                offstyle={license.value === '미인증' || license.value === '없음' ? "secondary" : "danger" }  />
                        </div>
                    </div>
                    <div className="d-flex mb-2">
                        <div className="mr-3 align-self-center">
                            <span className="trophy fs2 icon-11" style={{fontSize: '24px'}}></span>
                        </div>
                        <div className="w-100 align-self-center">차량 : {car_trophy.value === '없음' ? '미인증' : car_trophy.value}</div>
                        <div>
                            <BootstrapSwitchButton 
                                disabled = {car_trophy.value === '미인증' || car_trophy.value === '없음' ? true : false }
                                checked={car_trophy.post} 
                                onChange={(e) => onChange(e, 'car', car_trophy.value)} 
                                size="md" 
                                width={90} 
                                onlabel={car_trophy.value === '미인증' || car_trophy.value === '없음' ? "미인증" : "노출" } 
                                offlabel={car_trophy.value === '미인증' || car_trophy.value === '없음' ? "미인증" : "미노출" }  
                                onstyle={car_trophy.value === '미인증' || car_trophy.value === '없음' ? "secondary" : "primary" }  
                                offstyle={car_trophy.value === '미인증' || car_trophy.value === '없음' ? "secondary" : "danger" }  />
                        </div>
                    </div>
                    <div className="d-flex mb-2">
                        <div className="mr-3 align-self-center">
                            <span className="trophy fs2 icon-uniE900" style={{fontSize: '24px'}}></span>
                        </div>
                        <div className="w-100 align-self-center">미혼돌싱 : {family.value === '없음' ? '미인증' : family.value}</div>
                        <div>
                            <BootstrapSwitchButton 
                                disabled = {family.value === '미인증' || family.value === '없음' ? true : false }
                                checked={family.post} 
                                onChange={(e) => onChange(e, 'family', family.value)} 
                                size="md" 
                                width={90} 
                                onlabel={family.value === '미인증' || family.value === '없음' ? "미인증" : "노출" } 
                                offlabel={family.value === '미인증' || family.value === '없음' ? "미인증" : "미노출" }  
                                onstyle={family.value === '미인증' || family.value === '없음' ? "secondary" : "primary" }  
                                offstyle={family.value === '미인증' || family.value === '없음' ? "secondary" : "danger" }  />
                        </div>
                    </div>
                </div>
            </div> 
        )
    }
}

export default ProfileForm5
